import { useState } from "react"
import { PairSelection } from "./pairSelection"
import { SelectToken } from "../selectToken/SelectToken"
import { HiOutlineArrowLeft } from "react-icons/hi"

export const LiquidityCard = ({ pair, setPair, tokenSelection, setTokenSelection, setManualSelection }) => {
  const [isSelectingToken, setIsSelectingToken] = useState({ a: false, b: false })

  const exitSelectionToken = () => {
    setIsSelectingToken({ a: false, b: false })
  }

  const selectTokenA = (address, name) => {
    const temp = { tokenA: address, symbolA: name }
    setTokenSelection({ ...tokenSelection, ...temp })
  }

  const selectTokenB = (address, name) => {
    const temp = { tokenB: address, symbolB: name }
    setTokenSelection({ ...tokenSelection, ...temp })
  }

  return (
    <div className="card card-custom-w p-6 w-full text-primary-text flex flex-col gap-6">
      <div className="w-full flex flex-row justify-between">
        <div className="self-start h-full">
          <button onClick={() => setManualSelection(false)} className="flex items-center justify-center">
            <HiOutlineArrowLeft color={"white"} width={32} />
          </button>
        </div>
        <div className=" self-center text-center flex items-center justify-center">
          <p className="font-bold text-[24px]">Liquidity</p>
        </div>
        <div className="w-8" />
      </div>
      {isSelectingToken.a || isSelectingToken.b ? (
        isSelectingToken.b ? (
          <SelectToken exit={exitSelectionToken} name="Select Token B" selectToken={selectTokenB} />
        ) : (
          <SelectToken exit={exitSelectionToken} name="Select Token A" selectToken={selectTokenA} />
        )
      ) : (
        <PairSelection
          pair={pair}
          setPair={setPair}
          setIsSelectingToken={setIsSelectingToken}
          setTokenSelection={setTokenSelection}
          tokenSelection={tokenSelection}
        />
      )}
    </div>
  )
}
