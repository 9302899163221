import { useWeb3React } from "@web3-react/core"
import leaf from "../../images/leaf.png"
import { DisabledButton } from "../../utils/disabledButton"
import { deposit } from "../../../API/farming"
import { stakeUpdate } from "../../../state/state"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../toast/receiptToast"
import { displayDecimals } from "../../../utils"
import { useEffect, useState } from "react"
import { LoadingButton } from "../../utils/loadingButton"

export const ClaimComponent = ({ index, userData }) => {
  const { active, account, library } = useWeb3React()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [userData])

  const execute = async () => {
    setLoading(true)
    deposit(index, "0", account, library)
      .then((tx) => {
        if (!tx) {
          setLoading(false)
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              stakeUpdate.set({ index, date: Date.now() })
            } else {
              toast("Transaction Failed!")
              setLoading(false)
            }
          })
          .catch((e) => {
            console.log(e)
            setLoading(false)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => {
        setLoading(false)
        console.error(e)
      })
  }

  return (
    <div className="flex flex-col items-center text-primary-text font-semibold pt-4">
      <p className="text-xl">Claim Rewards</p>
      <div className="w-11/12 px-5 mt-3 h-[50px] rounded-custom bg-transparent flex flex-row text-primary-text justify-between items-center ">
        <div className="flex flex-row items-center gap-2">
          <img src={leaf} alt="leaf" className="w-[20px]" />
          <p>esFORT</p>
        </div>
        <p>{displayDecimals(userData.reward * 0.8)}</p>
      </div>
      <div className="w-11/12 px-5 mt-3 h-[50px] rounded-custom bg-transparent flex flex-row text-primary-text justify-between items-center ">
        <div className="flex flex-row items-center gap-2">
          <img src={leaf} alt="leaf" className="w-[20px]" />
          <p>FORT</p>
        </div>
        <p>{displayDecimals(userData.reward * 0.2)}</p>
      </div>

      <div className="w-11/12">
        {loading ? (
          <LoadingButton />
        ) : !active ? (
          <DisabledButton text={"Connect wallet First!"} />
        ) : userData.reward == 0 ? (
          <DisabledButton text={"Nothing to Claim!"} />
        ) : (
          <button onClick={execute} className="interaction-button">
            Claim
          </button>
        )}
      </div>
    </div>
  )
}
