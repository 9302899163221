import { baseToken } from "../selectToken/baseToken"
import noSymbol from "../images/tokens/noSymbol.png"
import { displayDecimals } from "../../utils"

export const LpTokensInfo = ({ pair, userData }) => {
  const calculatePercentage = (pair) => {
    const percentage = Math.floor((pair.pairBalance / pair.totalSupply) * 100 * 100) / 100
    return percentage < 0.1 ? (percentage === 0 ? "0" : "<0.1") : percentage
  }

  return (
    <>
      <div className="w-full flex flex-col gap-4">
        <p className="text-[16px] font-bold">LP Tokens in your wallet</p>
        <div className="flex flex-row w-full justify-between items-center">
          <div className="flex flex-row gap-2">
            <div className="relative">
              <div className="relative flex flex-row h-6 w-12">
                <div className="absolute left-0">
                  <img
                    className="w-6"
                    src={baseToken.find((item) => item.address === pair.token0)?.img || noSymbol}
                  />
                </div>
                <div className="absolute left-5">
                  <img
                    className="w-6"
                    src={baseToken.find((item) => item.address === pair.token1)?.img || noSymbol}
                  />
                </div>
              </div>
            </div>
            <p className="font-bold text-[16px]">
              {pair.symbol0}/{pair.symbol1}
            </p>
          </div>
          <p className="font-bold text-[16px]">{displayDecimals(pair.pairBalance)}</p>
        </div>
        <div className="flex flex-row w-full justify-between items-center">
          <div className="flex flex-row gap-2">
            <p className="font-bold text-[16px]">Pool share</p>
          </div>
          <p className="font-bold text-[16px]">{displayDecimals(calculatePercentage(pair))}</p>
        </div>
        <div className="flex flex-row w-full justify-between items-center">
          <div className="flex flex-row gap-2">
            <p className="font-bold text-[16px]">{pair.symbol0}</p>
          </div>
          <p className="font-bold text-[16px]">{displayDecimals(userData.parsedBalanceTokenA)}</p>
        </div>
        <div className="flex flex-row w-full justify-between items-center">
          <div className="flex flex-row gap-2">
            <p className="font-bold text-[16px]">{pair.symbol1}</p>
          </div>
          <p className="font-bold text-[16px]">{displayDecimals(userData.parsedBalanceTokenB)}</p>
        </div>
      </div>
    </>
  )
}
