export const DividendsDescription = () => {
  const steps = [
    {
      title: "What are the Dividends?",
      content:
        "The majority of the protocol earnings are redistributed to users who allocated esFORT to the Dividends contract. 25% of total protocol earnings is distributed in one or more tokens and happens linearly in weekly epochs.",
    },
    {
      title: "What are the Epochs?",
      content:
        "The distribution is based on a weekly epoch logic: every week, a fixed part of the accumulated protocol earnings of the past 7 days will be distributed. The same process is repeated every week.",
    },
    {
      title: "How Payouts work?",
      content:
        "Those tokens are linearly distributed over the duration of the epoch, and allocated on a second-basis to every user with a esFORT allocation on this Dividends plugin, proportionally to their share of the total allocation.",
    },
  ]

  return (
    <div className="text-white w-10/12 max-md:w-11/12 max-xx:w-10/12 self-center rounded-custom py-[34px] px-12 bg-card-color shadow-custom z-10">
      <div className="w-full text-start text-[22px] font-medium h-12">
        <p>How it works</p>
      </div>
      <div className="flex flex-row justify-between w-full max-sm:flex-col max-sm:gap-6">
        {steps.map((step, index) => (
          <div key={index} className="flex flex-col justify-start w-[30%] max-sm:w-full">
            <div className="bg-primary-color text-card-color rounded-full h-[30px] w-[30px] flex justify-center items-center font-bold mb-[10px]">
              {index + 1}
            </div>
            <p className="text-white font-medium text-[18px]">{step.title}</p>
            <p className="text-gray-text font-normal text-[14px]">{step.content}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
