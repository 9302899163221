import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export const Redirector = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("/publiclaunch")
  })
  return ""
}
