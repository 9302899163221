import { useEffect, useState } from "react"
import { DisabledButton } from "../../../utils/disabledButton"
import { useWeb3React } from "@web3-react/core"
import { approve } from "../../../../API/erc20"
import { FORT_ADDRESS, FORT_STAKE } from "../../../../constants"
import { ethers } from "ethers"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../../toast/receiptToast"
import { updateInizializeReedem } from "../../../../state/state"
import { lock } from "../../../../API/swordStake"
import { displayDecimals, removeLeadingZero } from "../../../../utils"
import { Spinner } from "../../../spinner/Spinner"
//eslint-disable-next-line
export const ConvertSword = ({ swordBalance, balanceLoading }) => {
  const { active, library } = useWeb3React()
  const [amount, setAmount] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [swordBalance])

  const handleInputChange = (e) => {
    const value = removeLeadingZero(e.target.value)
    setAmount(value)
  }

  const setMax = () => {
    setAmount(swordBalance.parsed)
  }

  const executeApprove = async () => {
    setLoading(true)
    approve(FORT_STAKE, amount, FORT_ADDRESS, library)
      .then((tx) => {
        if (!tx) {
          setLoading(false)
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              updateInizializeReedem.set(updateInizializeReedem.get() + 1)
            } else {
              toast("Transaction Failed!")
              setLoading(false)
            }
          })
          .catch((e) => {
            setLoading(false)
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => {
        setLoading(false)
        console.error(e)
      })
  }

  const execute = async () => {
    setLoading(true)
    lock(ethers.parseEther(amount) + "", library)
      .then((tx) => {
        if (!tx) {
          setLoading(false)
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            setLoading(false)
            if (res.status === 1) {
              toast("Transaction Success!")
              updateInizializeReedem.set(updateInizializeReedem.get() + 1)
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            setLoading(false)
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => {
        setLoading(false)
        console.error(e)
      })
  }

  return (
    <div className="w-full h-full bg-card-color-solid rounded-custom flex flex-col justify-between items-center text-white gap-6">
      <div className="flex flex-col gap-2 text-left min-h-[140px]">
        <p className="text-[16px] font-bold">Convert FORT</p>
        <p className="py-2 text-gray-text font-normal text-[14px]">
          Lock your FORT tokens to instantly get esFORT at a 1:1 ratio.
        </p>
        <p className="text-gray-text font-normal text-[14px]">
          Holding esFORT guarantees you an entry into upcoming Launchpad projects and can be locked into the
          Dividends contract to receive a share of the protocol earnings.
        </p>
      </div>
      <div className="flex flex-col gap-2 w-full">
        <div className="flex flex-col bg-card-light-blue w-full p-4 gap-2 rounded-custom">
          <button onClick={setMax} className="self-end text-gray-text pb-2 text-xs flex items-center">
            Balance: {balanceLoading ? <Spinner size={10} /> : displayDecimals(swordBalance.parsed)}
          </button>
          <div className="w-full h-[50px]flex items-center text-white">
            <input
              onChange={handleInputChange}
              name="amount"
              type="number"
              className="w-full bg-transparent"
              placeholder="0"
              value={amount}
            />
          </div>
        </div>
        {loading ? (
          <div className="loading-button">
            <Spinner size={"30"} />
          </div>
        ) : !active ? (
          <DisabledButton type={"primary"} text={"Connect your wallet "} />
        ) : +amount === 0 ? (
          <DisabledButton type={"primary"} text={"Insert amount"} />
        ) : +amount > +swordBalance.parsed ? (
          <DisabledButton type={"primary"} text={"Amount exceed balance!"} />
        ) : +amount > +swordBalance.allowanceValue ? (
          <button onClick={executeApprove} className="interaction-button  ">
            Approve
          </button>
        ) : (
          <button onClick={execute} className="interaction-button">
            Lock FORT
          </button>
        )}
      </div>
    </div>
  )
}
