import { commitments, whiteListed } from "../../API/privateSale"
import { PUBLIC_ICO_ADDRESS } from "../../constants"

export const getUserData = async (icoAddress, account, setter) => {
  const whiteListed = await userWhitelisted(icoAddress, account)
  const commitments = await userCommitments(icoAddress, account)

  setter({ whiteListed, commitments })
}

export const userCommitments = async (icoAddress, account) => {
  if (icoAddress === PUBLIC_ICO_ADDRESS || !account) {
    return 0
  }

  const result = await commitments(account)

  return result
}

export const userWhitelisted = async (icoAddress, account) => {
  if (icoAddress === PUBLIC_ICO_ADDRESS) {
    return true
  }

  if (!account) {
    return icoAddress === PUBLIC_ICO_ADDRESS
  }

  return await whiteListed(account)
}
