import { baseToken } from "../selectToken/baseToken"
import { Spinner } from "../spinner/Spinner"
import { abbreviateNumber, displayDecimals } from "../../utils"
import "./farmingItemStyle.css"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { Tooltip } from "@material-tailwind/react"

export const FarmingItemHeader = ({
  poolInfo,
  open,
  setOpen,
  loading,
  loadingUserInfo,
  tvl,
  userData,
  poolData,
  apr,
  // eslint-disable-next-line
  rewards,
  pairApy,
}) => {
  function Icon({ open }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`${open ? "rotate-180" : ""} h-5 w-5 transition-transform lg:hidden text-primary-color`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
      </svg>
    )
  }

  const ApyContent = ({ apr, pairApy }) => {
    return (
      <div className="bg-card-color rounded-custom shadow-2xl p-4 flex flex-col gap-2 text-primary-text">
        <div className="flex flex-row gap-2">
          <p>Swap Fees APY: {displayDecimals(pairApy)}%</p>
        </div>
        <div className="flex flex-row gap-2">
          <p>Rewards APY: {displayDecimals(apr)}%</p>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full max-sm:gap-1  flex flex-row justify-around  text-primary-text">
      <div className="flex justify-start items-center pl-4 min-w-fit">
        <div className="max-md:hidden min-w-[250px] relative w-fit flex flex-row">
          <div className="absolute z-0">
            <img className="w-7" src={baseToken.find((item) => item.address === poolInfo.token0)?.img} />
          </div>
          <div className="absolute left-4 z-10">
            <img className="w-7" src={baseToken.find((item) => item.address === poolInfo.token1)?.img} />
          </div>
          <div className="relative z-10 left-12">
            <p>
              {poolInfo.symbol0}-{poolInfo.symbol1} LP
            </p>
          </div>
        </div>

        <div className="md:hidden flex flex-col min-w-[172px] text-center">
          <div className="w-full flex flex-row justify-center">
            <div className="contents z-0">
              <img className="w-7" src={baseToken.find((item) => item.address === poolInfo.token0)?.img} />
            </div>
            <div className="contents left-4 ">
              <img className="w-7" src={baseToken.find((item) => item.address === poolInfo.token1)?.img} />
            </div>
          </div>
          <p>
            {poolInfo.symbol0}-{poolInfo.symbol1}
          </p>
          <p>LP </p>
        </div>
      </div>
      <div className="grid grid-cols-4 max-md:grid-cols-2 max-sm:grid-cols-1 max-lg:grid-cols-2 w-full gap-4">
        <div className="flex justify-center max-sm:justify-start max-sm:items-start w-full">
          <div className="flex w-fit flex-col justify-around text-left text-sm gap-2">
            <p>Liquidity</p>
            {loading ? <Spinner size={20} /> : <p className="text-gray-500">${abbreviateNumber(tvl)}</p>}
          </div>
        </div>
        <div className=" flex justify-center max-md:justify-start max-md:items-start w-full">
          <div className="flex h-full min-w-[55px] flex-col justify-around text-left text-sm gap-2">
            <div className="flex flex-row gap-2 items-center">
              <div className="h-full flex items-center">
                <p>APR</p>
              </div>
              <Tooltip
                content={<ApyContent apr={apr} pairApy={pairApy} />}
                animate={{
                  mount: { scale: 1, y: 0 },
                  unmount: { scale: 0, y: 25 },
                }}
                className="bg-transparent px-0"
              >
                <button>
                  <AiOutlineInfoCircle />
                </button>
              </Tooltip>
            </div>
            {loading ? (
              <Spinner size={20} />
            ) : (
              <p className="text-gray-500">{displayDecimals(+apr + +pairApy.toFixed(2))}%</p>
            )}
          </div>
          <div className="flex h-full min-w-[55px] flex-col justify-around text-left text-sm gap-2 hidden">
            <div className="flex flex-row gap-2">
              <p>APR</p>
              <AiOutlineInfoCircle />
            </div>
            <p className="text-gray-500">{pairApy.toFixed(2)}%</p>
          </div>
        </div>
        <div className="max-lg:hidden flex justify-center max-md:justify-start max-md:items-start w-full">
          <div className="flex flex-col justify-between text-left text-sm gap-2">
            <p>My Share</p>
            {loadingUserInfo ? (
              <Spinner size={20} />
            ) : (
              <p className="text-gray-500">
                {`$${
                  isNaN((userData.amountStaked * tvl) / poolData.lpBalance)
                    ? 0
                    : abbreviateNumber((userData.amountStaked * tvl) / poolData.lpBalance)
                } `}
              </p>
            )}
          </div>
        </div>
        {/* <div className="max-lg:hidden flex justify-center w-full hidden">
          <div className="flex flex-col justify-between text-left text-sm">
            <p>Earned</p>
            {loadingUserInfo ? (
              <Spinner size={20} />
            ) : (
              <p className="text-gray-500">{`$${abbreviateNumber(rewards)} `}</p>
            )}
          </div>
        </div> */}
      </div>
      <div className=" flex justify-end pr-4 items-center">
        <button
          onClick={setOpen}
          className={`w-full px-4 h-[50px] max-lg:hidden ${
            open
              ? "bg-card-light-blue "
              : "border border-primary-color hover:bg-card-light-blue hover:text-primary-text text-primary-color"
          } max-w-[200px] rounded-custom`}
        >
          Manage
        </button>
        <Icon open={open} />
      </div>
    </div>
  )
}
