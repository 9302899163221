import { Link, useLocation } from "react-router-dom"
import "./sidebar.css"

export const SidebarElement = ({
  name,
  index,
  path,
  icon: Icon,
  size = 20,
  target = "_self",
  setIsHovered,
  isHovered,
  img,
}) => {
  const location = useLocation()
  return (
    <Link
      className={`border-b-0 py-3 px-6 font-semibold hover:bg-card-light-blue hover:text-primary-color rounded-xl ${
        location.pathname === path ? "bg-card-light-blue text-primary-color" : ""
      }`}
      to={path}
      onMouseEnter={() => setIsHovered(index)}
      onMouseLeave={() => setIsHovered(-1)}
      target={target}
    >
      <div className="flex flex-row w-full justify-start items-center gap-4">
        {img ? (
          <img src={img} className={`h-6 w-6 -m-[2px] rounded-xl`} />
        ) : (
          <Icon
            size={size}
            width={size}
            height={size}
            color="white"
            className={`${isHovered === index || location.pathname === path ? "hovered" : ""}`}
          />
        )}
        <p>{name}</p>
      </div>
    </Link>
  )
}
