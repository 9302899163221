import { useWeb3React } from "@web3-react/core"
import { USDC_ADDRESS, WETH_ADDRESS, esFORT_ADDRESS } from "../../../constants"
import { useEffect, useState } from "react"
import { harvestAllDividends, harvestDividends, pendingDividendsAmount } from "../../../API/dividends"
import { ethers } from "ethers"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../toast/receiptToast"
import { removeDecimals } from "../../../utils"
import leaf from "../../images/leaf.png"

import { baseToken } from "../../selectToken/baseToken"
import { LoadingButton } from "../../utils/loadingButton"
import { convertSwordToUsd, getETHPrice } from "../../farming/farmingUtility"

//eslint-disable-next-line
const rewardAddress = [
  { address: WETH_ADDRESS, name: "WETH", decimal: 18 },
  { address: USDC_ADDRESS, name: "USDC", decimal: 6 },
  { address: esFORT_ADDRESS, name: "esFORT", decimal: 18 },
]

export const UserPendingRewards = () => {
  //eslint-disable-next-line
  const { account, library, active } = useWeb3React()
  const [rewardsArray, setRewardsArray] = useState([])
  //eslint-disable-next-line
  const [swordValue, setSwordValue] = useState(0)
  const [ethPrice, setETHPrice] = useState(0)
  const [loading, setLoading] = useState(-2)

  const getRewards = async () => {
    await setTokenPrice()
    const pendingRewards = []
    rewardAddress.forEach(async (reward) => {
      const pending = await pendingDividendsAmount(reward.address, account)
      pendingRewards.push({
        address: reward.address,
        amount: ethers.formatUnits(pending + "", reward.decimal),
      })
    })
    setRewardsArray(pendingRewards)
  }

  const execute = (tokenAddress, index) => {
    setLoading(index)
    harvestDividends(tokenAddress, library)
      .then((tx) => {
        if (!tx) {
          setLoading(-2)
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              getRewards()
            } else toast("Transaction Failed!")
            setLoading(-2)
          })
          .catch((e) => {
            console.log(e)
            setLoading(-2)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => {
        setLoading(-2)
        console.error(e)
      })
  }

  const executeAll = () => {
    setLoading(-1)
    harvestAllDividends(library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          setLoading(-2)
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              getRewards()
            } else toast("Transaction Failed!")

            setLoading(-2)
          })
          .catch((e) => {
            console.log(e)
            setLoading(-2)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => {
        setLoading(-2)
        console.error(e)
      })
  }

  useEffect(() => {
    if (!active) {
      setRewardsArray([])
      return
    }

    getRewards()
  }, [account, active, ethPrice])

  const setTokenPrice = async () => {
    const sword = await convertSwordToUsd(1)

    setSwordValue(sword)

    const eth = await getETHPrice()
    setETHPrice(eth)
  }
  return (
    <>
      <div className="flex flex-row w-full gap-4 justify-center items-center h-fit max-xl:flex-col-reverse  ">
        {rewardAddress.map((reward, index) => {
          const pending =
            rewardsArray.length === 0
              ? { amount: 0 }
              : rewardsArray.find((pending) => pending.address === reward.address)

          const amount = !pending || +pending.amount === 0 ? 0 : +pending.amount
          let usdValue =
            reward.address === WETH_ADDRESS ? ethPrice : reward.address === esFORT_ADDRESS ? swordValue : 1
          let convertedAmount = amount * usdValue

          convertedAmount = convertedAmount === 0 ? "0.0" : removeDecimals(convertedAmount)

          return (
            <div
              key={index}
              className="flex flex-col w-10/12 max-xl:w-full gap-10 items-center text-center bg-card-light-blue rounded-custom py-[15px] px-6 "
            >
              <div className="w-full flex">
                <div className="flex  flex-row items-center w-full justify-between">
                  <div className="min-w-[42px] ">
                    <img
                      className="w-8"
                      src={baseToken.find((item) => item.address === reward.address)?.img || ""}
                    />
                  </div>
                  <div className="flex flex-col ">
                    <p className="text-sm font-semibold">{reward.name}</p>
                    <p>
                      {removeDecimals(amount, reward.address === WETH_ADDRESS ? 6 : 2)}
                      {` (${removeDecimals(convertedAmount)}$)`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full">
                {loading === index ? (
                  <LoadingButton additionaStyle={"max-h-[42px] border border-transparent"} />
                ) : (
                  <button
                    className="interaction-button-rounded max-h-[42px]"
                    onClick={() => execute(reward.address, index)}
                  >
                    Claim
                  </button>
                )}
              </div>
            </div>
          )
        })}
        <div className="flex flex-col w-10/12 max-xl:w-full gap-10 items-center text-center bg-card-light-blue rounded-custom py-[15px] px-6 h-full">
          <div className="w-full flex">
            <div className="flex  flex-row items-center w-full justify-between">
              <div className="min-w-[42px] flex flex-row relative w-[82px] h-full justify-center items-center">
                {rewardAddress.map((item, index) => (
                  <div
                    key={index}
                    className={`absolute self-center`}
                    style={{ left: index * 25, zIndex: index }}
                  >
                    <img
                      className="w-8 min-w-8"
                      src={baseToken.find((token) => token.address === item.address).img || leaf}
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-col ">
                <p className="text-sm font-semibold">All</p>
                <p>0 $</p>
              </div>
            </div>
          </div>
          <div className="w-full">
            <button
              className="interaction-button max-h-[42px] hover:bg-card-color"
              onClick={() => executeAll()}
            >
              Claim All
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
