import { PUBLIC_ICO_ADDRESS } from "../../constants"
import { calculatePercentage, capitalize, displayDecimals, formatNumberWithApostrophes } from "../../utils"

//eslint-disable-next-line
export const MainDetails = ({ icoAddress, info, raised }) => {
  return (
    <div className={`flex flex-row justify-between w-full gap-28 max-sm:flex-col max-sm:gap-2`}>
      <div className="flex flex-col gap-2 w-full">
        {info.infoLeft.map((info, index) => {
          return (
            <div key={index}>
              <div className="flex flex-row justify-between w-full">
                <p className="text-gray-text text-[14px] font-normal">{info.name}</p>
                <p className="text-white text-[16px] font-bold">{info.value}</p>
              </div>
              <hr className="sm:hidden border-card-border" />
            </div>
          )
        })}
        {icoAddress === PUBLIC_ICO_ADDRESS ? (
          <div className="flex flex-row justify-between w-full">
            <p className="text-gray-text text-[14px] font-normal">APY</p>
            <p className="text-white text-[16px] font-bold ">
              {+raised > 0 ? displayDecimals((1500 / +raised) * 100) + "%" : "TBA"}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="flex flex-col gap-2 w-full">
        {info.infoRight.map((item, index) => {
          return (
            <div key={index}>
              <div className="flex flex-row justify-between w-full">
                <p className="text-gray-text text-[14px] font-normal">
                  {item.name === "Distribution" ? capitalize(info.type) + " allocation" : item.name}
                </p>
                <p className="text-white text-[16px] font-bold">
                  {item.name === "Distribution" ? formatNumberWithApostrophes(item.value) : item.value}
                </p>
              </div>
              <hr className="sm:hidden border-card-border" />
            </div>
          )
        })}
        <div className="flex flex-row justify-between w-full">
          <p className="text-gray-text text-[14px] font-normal">Total raised</p>
          <p className="text-white text-[16px] font-bold ">
            <span className="text-[14px] text-gray-text">
              ({displayDecimals(calculatePercentage(info.target, raised))}%){" "}
            </span>
            {displayDecimals(raised)} ETH
          </p>
        </div>
        <hr className="sm:hidden border-card-border" />
      </div>
    </div>
  )
}
