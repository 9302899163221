import { Claimable } from "./claimable"

export const Claim = ({ userReedems }) => {
  return (
    <div className="bg-card-light-blue py-4 rounded-custom w-full text-center flex flex-col gap-6 justify-center items-center">
      <h5 className="text-white font-bold">My Redeems</h5>
      <div className="border-t border-card-border w-10/12 " />
      <div className="flex flex-col gap-2 w-10/12 ">
        <div className="grid grid-cols-3 gap-4  text-white w-full">
          <div className="">Days Left</div>
          <div className="">Amount</div>
          <div className="">Claim</div>
        </div>
        <div className="flex flex-col w-full">
          {userReedems.length == 0 ? (
            <p className="text-white  pt-4">No pending redeems</p>
          ) : (
            userReedems.map((reedem, index) => <Claimable key={index} index={index} reedem={reedem} />)
          )}
        </div>
      </div>
    </div>
  )
}
