/* eslint-disable */
import Countdown from "react-countdown"
import { removeDecimals } from "../../../utils"
import { CountdownRenderer } from "./countDown"
import { IoWalletSharp } from "react-icons/io5"
import { LuCalendarClock } from "react-icons/lu"
import { FaChartLine } from "react-icons/fa"
import { IoHourglassOutline } from "react-icons/io5"
import { Spinner } from "../../spinner/Spinner"

export const DividendsGeneralInfo = ({ dividendsInfo, loading }) => {
  const calculateWeeksPassed = () => {
    const fixedDate = new Date(1700582400000)
    const currentDate = new Date()
    const millisecondsInAWeek = 1000 * 60 * 60 * 24 * 7
    //eslint-disable-next-line
    const weeksPassed = Math.floor((currentDate - fixedDate) / millisecondsInAWeek)
    return weeksPassed + 1
  }

  return (
    <>
      <div className="lg:hidden w-full h-[150px] flex flex-wrap border rounded-custom border-card-border overflow-hidden bg-card-light-blue">
        <div className="h-1/2 w-1/2 border border-card-border bg-card-light-blue flex justify-between items-center px-4">
          <IoWalletSharp size={20} />
          <div className="text-end flex flex-col gap-1">
            <p className="text-secondary text-sm font-semibold">Total esFORT</p>
            <p className="text-gray-text font-bold text-xs flex justify-end">
              {loading ? <Spinner size={11} /> : removeDecimals(dividendsInfo.totalAllocation)}
            </p>
          </div>
        </div>
        <div className="h-1/2 w-1/2 border border-card-border bg-card-light-blue flex justify-between items-center px-4">
          <LuCalendarClock size={20} />
          <div className="text-end flex flex-col gap-1">
            <p className="text-secondary text-sm font-semibold">Current Epoch</p>
            <p className="text-gray-text font-bold text-xs flex justify-end">
              {loading ? <Spinner size={11} /> : calculateWeeksPassed()}
            </p>
          </div>
        </div>
        <div className="h-1/2 w-1/2 border border-card-border bg-card-light-blue flex justify-between items-center px-4">
          <FaChartLine size={20} />
          <div className="text-end flex flex-col gap-1">
            <p className="text-secondary text-sm font-semibold">APR</p>
            <p className="text-gray-text font-bold text-xs flex justify-end">
              {loading ? (
                <Spinner size={11} />
              ) : dividendsInfo.apy == 0 ? (
                0
              ) : (
                removeDecimals(dividendsInfo.apy) + "%"
              )}
            </p>
          </div>
        </div>
        <div className="h-1/2 w-1/2 border border-card-border bg-card-light-blue flex justify-between items-center px-4">
          <IoHourglassOutline size={20} />
          <div className="text-end flex flex-col gap-1">
            <p className="text-secondary text-sm font-semibold">Next Epoch</p>
            <p className="text-gray-text font-bold text-xs flex justify-end">
              {loading ? (
                <Spinner size={11} />
              ) : dividendsInfo.startTime ? (
                <Countdown date={dividendsInfo.startTime * 1000} renderer={CountdownRenderer} />
              ) : (
                "0d 0h 0m 0s"
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="max-lg:hidden h-[56px] w-full flex justify-between gap-4">
        <div className="h-full w-full bg-card-light-blue rounded-custom flex justify-between items-center px-4 max-[950px]:px-2">
          <IoWalletSharp size={20} />
          <div className="text-end flex flex-col gap-1">
            <p className="text-secondary text-sm font-semibold">Total esFORT</p>
            <p className="text-gray-text font-bold text-xs flex justify-end">
              {loading ? <Spinner size={11} /> : removeDecimals(dividendsInfo.totalAllocation)}
            </p>
          </div>
        </div>
        <div className="h-full w-full bg-card-light-blue rounded-custom flex justify-between items-center px-4 max-[950px]:px-2">
          <LuCalendarClock size={20} />
          <div className="text-end flex flex-col gap-1">
            <p className="text-secondary text-sm font-semibold">Current Epoch</p>
            <p className="text-gray-text font-bold text-xs flex justify-end">
              {loading ? <Spinner size={11} /> : calculateWeeksPassed()}
            </p>
          </div>
        </div>
        <div className="h-full w-full bg-card-light-blue rounded-custom flex justify-between items-center px-4 max-[950px]:px-2">
          <FaChartLine size={20} />
          <div className="text-end flex flex-col gap-1">
            <p className="text-secondary text-sm font-semibold">APR</p>
            <p className="text-gray-text font-bold text-xs flex justify-end">
              {loading ? (
                <Spinner size={11} />
              ) : dividendsInfo.apy == 0 ? (
                0
              ) : (
                removeDecimals(dividendsInfo.apy) + "%"
              )}
            </p>
          </div>
        </div>
        <div className="h-full w-full bg-card-light-blue rounded-custom flex justify-between items-center px-4 max-[950px]:px-2">
          <IoHourglassOutline size={20} />
          <div className="text-end flex flex-col gap-1">
            <p className="text-secondary text-sm font-semibold">Next Epoch</p>
            <p className="text-gray-text font-bold text-xs flex justify-end">
              {loading ? (
                <Spinner size={11} />
              ) : dividendsInfo.startTime ? (
                <Countdown date={dividendsInfo.startTime * 1000} renderer={CountdownRenderer} />
              ) : (
                "0d 0h 0m 0s"
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
