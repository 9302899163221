import { useEffect, useState } from "react"
import { getPair } from "../../API/router"
import { getPairToken, getReserve, getTotalSupply, pairBalanceOf } from "../../API/pair"
import { useWeb3React } from "@web3-react/core"
import { ethers } from "ethers"
import { WETH_ADDRESS } from "../../constants"
import { parseWeiToValue } from "../../API/erc20"

import { baseToken } from "../selectToken/baseToken"
import noSymbol from "../images/tokens/noSymbol.png"
import { Spinner } from "../spinner/Spinner"
import { BsPlusLg } from "react-icons/bs"
//import arrowDown from "../images/arrow-down-2.png"

//eslint-disable-next-line
export const PairSelection = ({ setIsSelectingToken, tokenSelection, pair, setPair, setTokenSelection }) => {
  const [internalPair, setInternalPair] = useState()
  const { account, active } = useWeb3React()
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (tokenSelection.tokenA === tokenSelection.tokenB) {
      setLoading(false)
      return
    } else if (tokenSelection.tokenA && tokenSelection.tokenB) {
      setLoading(true)
      let tempTokenA = tokenSelection.tokenA === "ETH" ? WETH_ADDRESS : tokenSelection.tokenA
      let tempTokenB = tokenSelection.tokenB === "ETH" ? WETH_ADDRESS : tokenSelection.tokenB
      getPair(tempTokenA, tempTokenB).then(async (pairAddress) => {
        if (!pairAddress) {
          setInternalPair({
            pairAddress: ethers.ZeroAddress,
            pairBalance: "0",
            token0: tokenSelection.tokenA,
            reserve0: "0",
            reserve1: "0",
          })
          setLoading(false)
          return
        }
        let pairBalance = "0.0"
        const reserves = await getReserve(pairAddress)
        const token0 = await getPairToken(0, pairAddress)
        const token1 = await getPairToken(1, pairAddress)
        const reserve0 = await parseWeiToValue(reserves[0] + "", token0)
        const reserve1 = await parseWeiToValue(reserves[1] + "", token1)
        const totalSupply = await getTotalSupply(pairAddress)

        if (active) {
          pairBalance = await pairBalanceOf(account, pairAddress)
        }
        const ratio = pairBalance / totalSupply
        const userReserve0 = reserve0 * ratio
        const userReserve1 = reserve1 * ratio

        let tempTokenA = tokenSelection.tokenA === "ETH" ? WETH_ADDRESS : tokenSelection.tokenA
        let tempTokenB = tokenSelection.tokenB === "ETH" ? WETH_ADDRESS : tokenSelection.tokenB

        let selectedToken = {
          tokenA: token0,
          symbolA: token0 === tempTokenA ? tokenSelection.symbolA : tokenSelection.symbolB,
          tokenB: token1,
          symbolB: token1 === tempTokenB ? tokenSelection.symbolB : tokenSelection.symbolA,
        }

        selectedToken.tokenA = selectedToken.tokenA === WETH_ADDRESS ? "ETH" : selectedToken.tokenA
        selectedToken.tokenB = selectedToken.tokenB === WETH_ADDRESS ? "ETH" : selectedToken.tokenB

        setInternalPair({
          pairAddress,
          pairBalance,
          reserve0,
          reserve1,
          token0,
          token1,
          symbol0: selectedToken.symbolA,
          symbol1: selectedToken.symbolB,
          totalSupply,
          userReserve0,
          userReserve1,
        })

        setTokenSelection(selectedToken)

        setLoading(false)
      })
    }
  }, [])

  useEffect(() => {
    if (tokenSelection.tokenA && tokenSelection.tokenB && active && internalPair) {
      if (active && internalPair && internalPair.pairAddress !== ethers.ZeroAddress) {
        pairBalanceOf(account, internalPair.pairAddress).then((pairBalance) => {
          setInternalPair({ ...internalPair, pairBalance })
        })
      }
    }
  }, [account, active, internalPair])

  return (
    <div className="text-primary-text card-custom-w flex flex-col gap-6">
      <div className="flex flex-col justify-center items-center w-full py-3 gap-6">
        <button
          onClick={() => setIsSelectingToken({ a: true, b: false })}
          className="w-full h-12 border-card-border hover:border-primary-color bg-card-light-blue rounded-custom border "
        >
          <div className="flex flex-row gap-2 items-center justify-start px-6">
            <img
              className="w-5"
              src={baseToken.find((item) => item.address === tokenSelection.tokenA)?.img || noSymbol}
            />
            <p>{tokenSelection.symbolA}</p>
          </div>
        </button>
        <div className="w-full flex flex-row justify-center items-center relative mr-[7px]">
          <button id="invert-swap" disabled className=" self-cente">
            <BsPlusLg size={20} color="var(--primary-color)" className="" />
          </button>
        </div>
        <button
          onClick={() => setIsSelectingToken({ a: false, b: true })}
          className="w-full h-12 border border-card-border hover:border-primary-color bg-card-light-blue rounded-custom "
        >
          <div className="flex flex-row gap-2 items-center justify-start px-6">
            <img
              className="w-5"
              src={baseToken.find((item) => item.address === tokenSelection.tokenB)?.img || noSymbol}
            />
            <p>{tokenSelection.symbolB}</p>
          </div>
        </button>
      </div>
      {loading ? (
        <button disabled className="loading-button">
          <Spinner size={"30"} />
        </button>
      ) : tokenSelection.tokenA === tokenSelection.tokenB ? (
        <button disabled className="disabled-button">
          <p>Select different token</p>
        </button>
      ) : internalPair ? (
        <button onClick={() => setPair(internalPair)} className="interaction-button">
          Manage Liquidity
        </button>
      ) : (
        <div className="disabled-button">
          <p>No Token selected!</p>
        </div>
      )}
    </div>
  )
}
