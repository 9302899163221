import { atom } from "nanostores"
import { RPC_URL, FORT_ADDRESS } from "../constants"
import { getDefaultProvider } from "@ethersproject/providers"
//import { baseToken } from "../components/selectToken/baseToken"

export const providerReadOnly = getDefaultProvider(RPC_URL)

export const saleUpdate = atom(0)

export const stakeUpdate = atom(0)

export const xStakeUpdate = atom(0)

export const xSwordStaked = atom(0)

export const updateInizializeReedem = atom(0)

export const addLiquidityApproval = atom(0)

export const addLiquidityState = atom(0)

export const removeLiquidityUpdate = atom(0)

export const updateinvertSwap = atom(0)

export const pairSwap = atom("0xc8b6B3A4D2d8428EF3a940EAc1E32A7DdAdCB0f1")

export const swapConfig = atom({
  tokenA: "ETH",
  tokenB: FORT_ADDRESS,
  tokenALiquidity: FORT_ADDRESS,
  tokenBLiquidity: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
  slippage: "0.5",
  swapValue: null,
  amountOut: null,
  deadLine: "90",
  symbolA: "ETH",
  symbolB: "FORT",
  symbolC: "ETH",
  amountLiquidityA: 0,
  amountLiquidityB: 0,
  valueA: 1,
  valueB: 1,
})
