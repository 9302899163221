import { InteractionCard } from "./interactionCard"

export const ConnectWallet = () => {
  return (
    <InteractionCard>
      <div className="flex flex-col w-full h-full justify-center items-center text-primary-text font-semibold min-h-[200px]">
        <p className="text-xl">Connect Wallet</p>
      </div>
    </InteractionCard>
  )
}
