import { useWeb3React } from "@web3-react/core"
import { useEffect } from "react"

export const ConnectionButton = ({ connector, img, name }) => {
  const { activate } = useWeb3React()

  const connection = async () => {
    activate(connector)
    localStorage.setItem("connection", JSON.stringify({ name }))
  }

  useEffect(() => {
    let connectionInfo = localStorage.getItem("connection")

    if (connectionInfo) {
      connectionInfo = JSON.parse(connectionInfo)

      if (connectionInfo.name === name) {
        try {
          activate(connector)
        } catch (error) {
          console.log(error)
        }
      }
    }
  })

  return (
    <button
      onClick={connection}
      className="bg-card-light-blue hover:border-primary-color border-card-border text-white text-start font-bold py-2 mb-4 px-4 rounded-custom border w-full"
    >
      <div className="flex flex-row items-center text-gray-text">
        <img width={"40px"} className="pr-2" src={img} />
        {name}
      </div>
    </button>
  )
}
