import { useWeb3React } from "@web3-react/core"

import { useEffect, useState } from "react"
import { allowance as allowanceX, balanceOf as balanceOfX } from "../../API/xtoken"
import {
  totalAllocation,
  usersAllocation,
  dividendsInfo as getCurrentInfo,
  nextCycleStartTime,
} from "../../API/dividends"
import { ethers } from "ethers"
import { DIVIDENDS_ADDRESS, FORT_STAKE, USDC_ADDRESS, WETH_ADDRESS, esFORT_ADDRESS } from "../../constants"
import { getUserRedeem, getUserRedeemLength } from "../../API/swordStake"
import { useStore } from "@nanostores/react"
import { updateInizializeReedem, xStakeUpdate } from "../../state/state"
import { calculateApr } from "./dividends/dividendsUtils"
import { convertSwordToUsd, getETHPrice } from "../farming/farmingUtility"
import { DividendsGeneralInfo } from "./dividends/dividendsGeneralInfo"
import { UserPendingRewards } from "./dividends/userPendingRewards"
import { DividendsDescription } from "./dividendsDescription"
import { Allocate } from "./dividends/modal/allocate"
import { Deallocate } from "./dividends/modal/deallocate"
import banner from "../images/banner/dividends.png"

export const DividendsPage = () => {
  const { account, active } = useWeb3React()

  const update = useStore(xStakeUpdate)
  const updateBal = useStore(updateInizializeReedem)

  const [esFortBalance, setEsFortBalance] = useState({ parsed: 0 })
  //eslint-disable-next-line
  const [esFortBalanceLoading, setEsFortBalanceLoading] = useState(false)
  const [dividendsInfo, setDividendsInfo] = useState({
    totalAllocation: 0,
    startTime: null,
    xSwordRewards: null,
    apy: 0,
  })
  const [dividendsInfoLoading, setDividendsInfoLoading] = useState(false)
  const [userDividendsInfo, setUserDividendsInfo] = useState({ allowance: 0, allocation: 0 })

  //eslint-disable-next-line
  const [userDividendsLoading, setUserDividendsLoading] = useState(false)

  const updateBalance = async () => {
    setEsFortBalanceLoading(true)
    const valueXBalance = await balanceOfX(account)
    const parsedX = ethers.formatEther(valueXBalance + "")

    let allowanceXValue = await allowanceX(account, FORT_STAKE)
    allowanceXValue = ethers.formatEther(allowanceXValue + "")

    setEsFortBalance({ parsed: parsedX, bigNumber: valueXBalance, allowanceValue: allowanceXValue })
    setEsFortBalanceLoading(false)
  }

  //eslint-disable-next-line
  const getDividendsInfo = async () => {
    setDividendsInfoLoading(true)
    let allocation = await totalAllocation()
    allocation = ethers.formatEther(allocation + "")

    let startTime = await nextCycleStartTime()
    startTime = startTime + ""

    const currentEpochInfoXSword = await getCurrentInfo(esFORT_ADDRESS)
    const xSwordRewards = ethers.formatEther(currentEpochInfoXSword[0] + "")
    const SwordUsdValue = await convertSwordToUsd(1)
    //eslint-disable-next-line
    const currentEpochInfoWETH = await getCurrentInfo(WETH_ADDRESS)
    const WETHRewards = ethers.formatEther(currentEpochInfoWETH[0] + "")
    const ETHPrice = await getETHPrice()
    const WETHUsdValue = WETHRewards * ETHPrice
    const WETHConvertedToSword = WETHUsdValue / SwordUsdValue

    const currentEpochInfoUSDC = await getCurrentInfo(USDC_ADDRESS)
    const USDCRewards = ethers.formatUnits(currentEpochInfoUSDC[0] + "", 6)
    const USDCConvertedToSword = USDCRewards / SwordUsdValue

    const apy = calculateApr(allocation, +xSwordRewards + +WETHConvertedToSword + +USDCConvertedToSword)

    setDividendsInfo({ totalAllocation: allocation, startTime, apy })
    setDividendsInfoLoading(false)
  }

  const getDividendsUserInfo = async () => {
    setUserDividendsLoading(true)
    let dividendsAllowance = await allowanceX(account, DIVIDENDS_ADDRESS)
    dividendsAllowance = ethers.formatEther(dividendsAllowance + "")

    let userAllocation = await usersAllocation(account)
    userAllocation = ethers.formatEther(userAllocation + "")

    setUserDividendsInfo({ allowance: dividendsAllowance, allocation: userAllocation })
    setUserDividendsLoading(false)
  }

  //eslint-disable-next-line
  const getUserRedeemInfo = async () => {
    getUserRedeemLength(account).then(async (value) => {
      const reedems = []

      for (let i = 0; i < value; i++) {
        const reedem = await getUserRedeem(account, i)

        reedems.push(reedem)
      }
    })
  }

  useEffect(() => {
    getDividendsInfo()

    if (!active) {
      setEsFortBalance({ parsed: 0, bigNumber: null })
      setUserDividendsInfo({ allowance: 0, allocation: 0 })
      return
    }

    if (active) {
      updateBalance()
      getDividendsUserInfo()
      getUserRedeemInfo()
    }
  }, [active, account, update])

  useEffect(() => {
    if (active) {
      updateBalance()
      getDividendsUserInfo()
    }
  }, [updateBal])

  return (
    <>
      <div className="w-full flex flex-col gap-6 justify-center items-center text-white py-6">
        <div className="flex flex-col items-center justify-center w-8/12 max-md:w-11/12 max-xx:w-10/12 rounded-custom overflow-hidden bg-card-color self-center p-6 gap-6 z-10 shadow-custom">
          <div className="w-full h-[204px] rounded-custom overflow-hidden bg-card-light-blue relative max-sm:hidden">
            <img src={banner} className="min-w-full min-h-full object-cover" />
          </div>
          <div
            id="img-launch"
            className="w-full h-32 bg-red rounded-custom bg-card-light-blue overflow-hidden relative sm:hidden"
          >
            <img
              src={banner}
              className="min-w-full min-h-full absolute top-50 left-50 transform -translate-x-50% -translate-y-50% object-cover"
            />
          </div>
          <div className="w-full text-left flex flex-col gap-2">
            <p className="text-[14px]">
              {/* eslint-disable-next-line */}
              Allocate esFORT here to earn a share of protocol earnings in the form of real yield.
            </p>
          </div>
          <DividendsGeneralInfo dividendsInfo={dividendsInfo} loading={dividendsInfoLoading} />
          <div className="w-full flex items-center justify-center">
            <div className="w-full border-t border-card-border" />
          </div>
          <div className="flex flex-row gap-[10px] w-full max-lg:flex-col">
            <Allocate
              userDividendsInfo={userDividendsInfo}
              esFortBalanceLoading={esFortBalanceLoading}
              esFortBalance={esFortBalance}
            />
            <Deallocate userDividendsInfo={userDividendsInfo} userDividendsLoading={userDividendsLoading} />{" "}
          </div>
          <UserPendingRewards />
        </div>
        <DividendsDescription />
      </div>
    </>
  )
}
