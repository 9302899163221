import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react"
import { useState } from "react"
import { IoIosArrowDown } from "react-icons/io"

export const LaunchDetail = ({ type }) => {
  const [open, setOpen] = useState(false)

  const Icon = ({ open }) => {
    return (
      <div className={`${open ? "rotate-180" : ""}`}>
        <IoIosArrowDown size={20} />
      </div>
    )
  }

  return (
    <>
      <Accordion className={`bg-transparent`} open={open} icon={<Icon open={open} />}>
        <AccordionHeader
          className="text-primary-color border-none flex justify-center gap-1 text-[12px] font-bold"
          onClick={() => setOpen(!open)}
        >
          {open ? "Hide" : "Details"}
        </AccordionHeader>
        <AccordionBody className="text-gray-text text-[14px] font-normal">
          {type === "private"
            ? "$FORT Private Sale will be an FCFS Sale with fixed hard cap and a fixed token price.  The sale is open only to Guards ( exclusive roles in our Discord server, which represents early supporters ) and Whitelisted addresses. Due to the fixed hard cap and token price, the number of participants is also fixed."
            : "$FORT Public Sale will be an Overflow Farming, which is an advanced sale model that introduces an important improvement. In fact, while in the classic Overflow model, early contributors who assumed greater risks were not rewarded, in the Farming Overflow model, they will. We will in fact allocate bonus $esFORT tokens to be farmed within the sale duration. Investors will be able to farm and generate extra yield while waiting for the sale to ennd, maximizing their profit."}
        </AccordionBody>
      </Accordion>
    </>
  )
}
