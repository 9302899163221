import { useStore } from "@nanostores/react"
import { useWeb3React } from "@web3-react/core"
import { useState } from "react"
import { RouterSwapButton } from "./RouterSwapButton"
import { Spinner } from "../spinner/Spinner"
import { SelectToken } from "../selectToken/SelectToken"
import "react-tooltip/dist/react-tooltip.css"
import { BsArrowDownUp } from "react-icons/bs"
import { CardHeader } from "../utils/cardHeader"
import { SwapSetting } from "./SwapSetting"
import { SettingsPopover } from "../utils/settingPopover"
import "./swapCardStyle.css"
import { SwapInput } from "./swapInput"
import { debounce, removeLeadingZero } from "../../utils"

//eslint-disable-next-line
export const SwapCard = ({ context }) => {
  const { active } = useWeb3React()

  const [openSettingTokenA, setOpenSettingTokenA] = useState(false)
  const [openSettingTokenB, setOpenSettingTokenB] = useState(false)

  const balances = useStore(context.userBalance)
  // const selectedTokens = useStore(context.selectedTokens)
  const inputA = useStore(context.inputA)
  const outputB = useStore(context.outputB)
  const loadingQuote = useStore(context.pairLoading)
  //const pair = useStore(context.pairData)
  const pairError = useStore(context.error)

  const debounceQuote = debounce(context.quoteToken, 500)

  const setMax = (type) => () => {
    if (type === "a") {
      console.log("set max", balances.a)
      context.inputA.set(balances.a)
      context.quoteToken("a")
    }
  }

  const handleSwapValueChange = (type) => (e) => {
    if (type === "a") {
      context.inputA.set(removeLeadingZero(e.target.value))
      debounceQuote("a")
    } else {
      context.inputB.set(e.target.value)
      context.quoteToken("b")
    }
  }

  const setOpenSetting = (type) => (value) => {
    if (type === "a") {
      setOpenSettingTokenA(value)
    } else {
      setOpenSettingTokenB(value)
    }
  }

  return (
    <>
      <div className="max-md:px-2 shadow-custom  rounded-custom overflow-hidden">
        {openSettingTokenA || openSettingTokenB ? (
          <div id="setting-container" className="w-full max-md:px-4 md:basis-2/6">
            {openSettingTokenA ? (
              <SelectToken exit={setOpenSetting("a")} selectToken={context.setToken("a")} />
            ) : (
              <SelectToken exit={setOpenSetting("b")} selectToken={context.setToken("b")} />
            )}
          </div>
        ) : (
          <div className="flex flex-col justify-between bg-card-color py-5 p-[20px] h-fit max-w-[450px] w-full">
            <div className="flex justify-between max-w-full pb-[10px]">
              <CardHeader text={"Swap"} />
              <SettingsPopover>
                <SwapSetting context={context} />
              </SettingsPopover>
            </div>
            <div className="flex flex-col justify-center gap-1 w-full">
              <SwapInput
                context={context}
                type={"a"}
                handleChange={handleSwapValueChange("a")}
                openSetting={setOpenSetting("a")}
                output={inputA}
                setMax={setMax("a")}
              />
              <div className="w-full flex flex-row justify-center items-center relative">
                <button
                  id="invert-swap"
                  onClick={context.invertSwap}
                  className="absolute self-center bg-card-dark border-card-border border hover:scale-110 transition-transform duration-200 rounded-full p-[6px] m-1"
                >
                  <BsArrowDownUp size={18} color={"#ffffff"} />
                </button>
              </div>
              <SwapInput
                context={context}
                type={"b"}
                handleChange={handleSwapValueChange("b")}
                openSetting={setOpenSetting("b")}
                output={outputB}
                setMax={setMax}
                disabled={true}
              />
            </div>
            <div className="pt-[40px]">
              {loadingQuote ? (
                <button disabled className="disabled-button">
                  <Spinner size={"30"} />
                </button>
              ) : pairError ? (
                <button disabled className="disabled-button">
                  {pairError}
                </button>
              ) : !active ? (
                <button disabled className="disabled-button">
                  Connect your wallet first!
                </button>
              ) : inputA > +balances.a ? (
                <button disabled className="disabled-button">
                  Amount exceed balance
                </button>
              ) : (
                <RouterSwapButton context={context} />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
