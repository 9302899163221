import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { baseToken } from "../selectToken/baseToken"
import noSymbol from "../images/tokens/noSymbol.png"
import { providerReadOnly } from "../../state/state"
import { balanceOf, parseWeiToValue } from "../../API/erc20"
import { displayDecimals } from "../../utils"

export const TokenItem = ({ address, connectionSidebarOpen }) => {
  const { active, account } = useWeb3React()
  const [balance, setBalance] = useState(0)
  useEffect(() => {
    if (!connectionSidebarOpen || !active) {
      return
    }

    getBalance()

    return () => {}
  }, [connectionSidebarOpen, account])

  const getBalance = async () => {
    let value
    if (address === "ETH") {
      value = await providerReadOnly.getBalance(account)
    } else {
      value = await balanceOf(account, address)
    }

    value = await parseWeiToValue(value, address)
    value = displayDecimals(value)
    setBalance(value)
  }

  return (
    <div className="flex flex-row w-full justify-between items-center">
      <div className="flex flex-row items-center gap-2">
        <img className="w-10" src={baseToken.find((item) => item.address === address)?.img || noSymbol} />
        <p className="font-bold">{baseToken.find((item) => item.address === address).name}</p>
      </div>
      <div>
        <p className="font-bold">{balance}</p>
      </div>
    </div>
  )
}
