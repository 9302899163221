import { useState } from "react"
import { AddLiquidity } from "./addLiquidity"
import { RemoveLiquidity } from "./removeLiquidity"
import { ethers } from "ethers"
import { SettingLiquidity } from "./settingLiquidity"
import { SettingsPopover } from "../utils/settingPopover"
import { HiOutlineArrowLeft } from "react-icons/hi"

export const PairManaging = ({
  pair,
  setPair,
  tokenSelection,
  origin = "add",
  userData,
  isLoadindBalance,
}) => {
  const [type, setType] = useState(origin)
  const [setting, setSetting] = useState({
    add: { slippage: "0.5", deadLine: 90 },
    remove: { slippage: "0.5", deadLine: 90 },
  })
  const [isSettingOpen, setIseSettingOpen] = useState({ status: false, type: "add" })

  const firstUpper = (data) => {
    const firstChar = data.charAt(0)

    return firstChar.toUpperCase() + data.slice(1)
  }

  const settingsHandler = () => {
    setIseSettingOpen({ status: true, type })
  }

  return (
    <div className="w-6/12 max-xl:w-8/12 max-lg:w-10/12 max-sm:w-full flex flex-col items-center pb-6">
      {pair.pairAddress === ethers.ZeroAddress ? (
        <div className="pt-16 md:pt-20" />
      ) : (
        <div className="mt-[15px] mb-[15px] max-md:my-[7px]  card-custom-w card h-[50px] flex flex-row justify-around  text-lg font-bold w-fill text-primary-text border border-primary-color">
          <button
            onClick={() => {
              setIseSettingOpen({ status: false })
              setType("add")
            }}
            className={`${
              type == "add" ? "bg-primary-color text-white" : "text-primary-text"
            } w-1/2 rounded-l-custom transition-all duration-300`}
          >
            Add
          </button>
          <button
            disabled={pair.pairAddress === ethers.ZeroAddress}
            onClick={() => {
              setIseSettingOpen({ status: false })
              setType("remove")
            }}
            className={`${
              type == "remove" ? "bg-primary-color text-white" : "text-primary-text"
            } w-1/2 rounded-r-custom transition-all duration-300`}
          >
            Remove
          </button>
        </div>
      )}
      <div className="w-full overflow-hidden rounded-custom bg-card-color">
        <div className="card px-4 border py-4 border-card-border  w-full text-primary-text ">
          <div className="text-primary-text flex flex-row justify-between w-full">
            <button
              onClick={() => (isSettingOpen.status ? setIseSettingOpen({ status: false }) : setPair(null))}
              className="flex items-center self-start w-8 justify-center h-8"
            >
              <HiOutlineArrowLeft color={"white"} />
            </button>
            <div className="w-fit flex items-center justify-center">
              <p className="font-bold text-[24px]">
                {!isSettingOpen.status
                  ? firstUpper(type) + " Liquidity"
                  : firstUpper(isSettingOpen.type) + " Setting"}
              </p>
            </div>
            <SettingsPopover moreFunction={settingsHandler}>
              <SettingLiquidity
                setIseSettingOpen={setIseSettingOpen}
                isSettingOpen={isSettingOpen}
                setSetting={setSetting}
                setting={setting}
              />
            </SettingsPopover>
          </div>

          {type === "add" ? (
            <AddLiquidity
              pair={pair}
              tokenSelection={tokenSelection}
              setting={setting}
              isLoadindBalance={isLoadindBalance}
              userData={userData}
            />
          ) : (
            <RemoveLiquidity
              pair={pair}
              tokenSelection={tokenSelection}
              setting={setting}
              userData={userData}
            />
          )}
        </div>
      </div>
    </div>
  )
}
