import { PRIVATE_ICO_MAX_COMMITMENT, PRIVATE_ICO_TARGET } from "../../../constants"

export const launchHeaders = [
  { key: "startDate", name: "Start Date" },
  { key: "type", name: "Type of Sale" },
  { key: "endDate", name: "End Date" },
  { key: "target", name: "Target raise" },
]

export const publicLaunch = {
  type: "public",
  title: "Public Sale - FORT",
  description:
    "$FORT is Citadel’s native token. It is at the core of the protocol.Together with $esFORT, it can be earned as yield rewards on the various yield farms available on the protocol, gives access to Citadel's DAO and guarantees allocation in Citadel's Launchpad future launches.",
  start: 1700222400000,
  claimETH: 1700477150000,
  end: 1700568000000,
  minCommit: 0.00001,
  maxCommit: 1000,
  target: 380,
  infoLeft: [
    {
      name: "Start Date",
      value: "17th Nov 12 PM UTC",
      type: "date",
    },
    {
      name: "Sale Type",
      value: "Farming Overflow",
      type: "date",
    },
    {
      name: "Price",
      value: "$0.0403",
      type: "date",
    },
    {
      name: "Target Raise",
      value: "380 ETH",
      type: "date",
    },
  ],
  infoRight: [
    {
      name: "End Date",
      value: "20th Nov 12 PM UTC",
      type: "number",
    },
    {
      name: "Farming Bonus",
      value: "500,000 $esFORT",
      type: "number",
    },
    {
      name: "Distribution",
      value: 17000000,
      type: "number",
    },
    {
      name: "Initial Marketcap",
      value: "$975,744",
      type: "number",
    },
  ],
}

export const privateLaunch = {
  type: "private",
  title: "Private Sale - FORT",
  description:
    "$FORT is Citadel’s native token. It is at the core of the protocol.Together with $esFORT, it can be earned as yield rewards on the various yield farms available on the protocol, gives access to Citadel's DAO and guarantees allocation in Citadel's Launchpad future launches.",
  start: 1700056800000, //1700046000000,
  claimETH: 1700136000000,
  end: 1700568000000,
  // start: 1700004000000, //1700046000000,
  // claimETH: 1700004300000,
  // end: 1700004600000,
  //target: 60,
  minCommit: 0,
  maxCommit: PRIVATE_ICO_MAX_COMMITMENT,
  target: PRIVATE_ICO_TARGET,
  infoLeft: [
    {
      name: "Start Date",
      value: "15th Nov 12 PM UTC",
      type: "date",
    },
    {
      name: "Type Sale",
      value: "FCFS",
      type: "date",
    },
    {
      name: "Price",
      value: "$0.0356",
      type: "date",
    },
    {
      name: "Target Raise",
      value: "60 ETH",
      type: "date",
    },
  ],
  infoRight: [
    {
      name: "End Date",
      value: "16th Nov 12 PM UTC",
      type: "number",
    },
    {
      name: "Distribution",
      value: 3000000,
      type: "number",
    },
    {
      name: "Initial Marketcap",
      value: "$975,744",
      type: "number",
    },
  ],
}

export const harvestHavenLaunch = {
  type: "public",
  title: "IDO - $HVR",
  description:
    "$FORT is Citadel’s native token. It is at the core of the protocol.Together with $esFORT, it can be earned as yield rewards on the various yield farms available on the protocol, gives access to Citadel's DAO and guarantees allocation in Citadel's Launchpad future launches.",

  claimETH: 1700477150000,
  end: 1700568000000,
  minCommit: 0.00001,
  maxCommit: 1000,
  target: 380,
  infoLeft: [
    {
      name: "Start Date",
      value: "TBA",
      type: "string",
    },
    {
      name: "Sale Type",
      value: "TBA",
      type: "string",
    },
    {
      name: "Price",
      value: "TBA",
      type: "string",
    },
    {
      name: "Target Raise",
      value: "TBA",
      type: "string",
    },
  ],
  infoRight: [
    {
      name: "End Date",
      value: "TBA",
      type: "string",
    },
    {
      name: "Farming Bonus",
      value: "TBA",
      type: "string",
    },
    {
      name: "Distribution",
      value: "TBA",
      type: "string",
    },
    {
      name: "Initial Marketcap",
      value: "TBA",
      type: "string",
    },
  ],
}
