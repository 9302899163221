import { Contract } from "@ethersproject/contracts"
import ERC20_ABI from "../ABI/ERC20.json"
import { ethers } from "ethers"
import { parseWeiToNumber } from "../utils"
import { providerReadOnly } from "../state/state"

export const name = async (contractAddress, provider) => {
  if (contractAddress === "ETH") return "ETH"
  const contract = connectContract(contractAddress, provider)
  return await contract.name()
}

export const symbol = async (contractAddress, provider) => {
  const contract = connectContract(contractAddress, provider)
  return await contract.symbol()
}

export const decimal = async (contractAddress, provider) => {
  const contract = connectContract(contractAddress, provider)
  return await contract.decimals()
}

export const totalSupply = async (contractAddress, provider) => {
  const contract = connectContract(contractAddress, provider)
  return await contract.totalSupply()
}

export const balanceOf = async (account, contractAddress, provider = providerReadOnly) => {
  const contract = connectContract(contractAddress, provider)
  return await contract.balanceOf(account)
}

export const balanceOfParsed = async (account, contractAddress, decimal, provider = providerReadOnly) => {
  const contract = connectContract(contractAddress, provider)
  const balance = await contract.balanceOf(account)

  const parsed = ethers.formatUnits(balance + "", decimal)

  return parsed
}

export const tokenBalance = async (contractAddress, tokenLP, provider) => {
  const contract = connectContract(contractAddress, provider)
  return await contract.balanceOf(tokenLP)
}

export const transfer = async (to, value, contractAddress, provider) => {
  const contract = connectContract(contractAddress, provider.getSigner())
  const parsedValue = await parseValueToWeiString(value, contractAddress, provider)
  return await contract.transfer(to, parsedValue)
}

export const transferFrom = async (from, to, value, contractAddress, provider) => {
  const contract = connectContract(contractAddress, provider.getSigner())
  const parsedValue = await parseValueToWeiString(value, contractAddress, provider)
  return await contract.transferFrom(from, to, parsedValue)
}

export const approve = async (spender, value, contractAddress, provider) => {
  const contract = connectContract(contractAddress, provider.getSigner())
  const parsedValue = await parseValueToWeiString(value, contractAddress, provider)
  return await contract.approve(spender, parsedValue)
}

export const allowance = async (owner, spender, contractAddress, provider) => {
  const contract = connectContract(contractAddress, provider)
  const allowanceValue = await contract.allowance(owner, spender)
  return parseWeiToNumber(allowanceValue.toString())
}

export const allowance2 = async (owner, spender, contractAddress) => {
  const contract = connectContract(contractAddress)
  return await contract.allowance(owner, spender)
}

export const allowance3 = async (owner, spender, contractAddress, provider) => {
  const contract = connectContract(contractAddress, provider)
  const allowanceValue = await contract.allowance(owner, spender)
  return parseWeiToValue(allowanceValue, contractAddress)
}

export let allowanceParsed = async (owner, spender, contractAddress, decimal, provider) => {
  const contract = connectContract(contractAddress, provider)
  const allowanceValue = await contract.allowance(owner, spender)
  return ethers.formatUnits(allowanceValue + "", decimal)
}
export const parseValueToWeiString = async (value, contractAddress) => {
  const tokenDecimal = contractAddress === "ETH" ? 18 : await decimal(contractAddress, providerReadOnly)
  //const roundedValue = Math.floor(Number(value) * 10 ** tokenDecimal) / 10 ** tokenDecimal
  const parsedValue = ethers.parseUnits(removeAfterDot(value + "", tokenDecimal), tokenDecimal)
  return parsedValue.toString()
}

function removeAfterDot(string, numCharsAfterDot) {
  var dotFound = false
  var charCount = 0
  var newString = ""

  for (var i = 0; i < string.length; i++) {
    if (string[i] === ".") {
      dotFound = true
    }
    if (dotFound) {
      charCount++
      if (charCount > numCharsAfterDot + 1) {
        break
      }
    }
    newString += string[i]
  }

  return newString
}

export const parseWeiToValue = async (value, contractAddress, provider = providerReadOnly) => {
  if (value === undefined) return null

  if (contractAddress === "ETH") return +ethers.formatEther(value + "")

  const tokenDecimal = await decimal(contractAddress, provider)
  const parsedValue = ethers.formatUnits(value + "", tokenDecimal)
  return parsedValue
}

const connectContract = (address, provider = providerReadOnly) => {
  return new Contract(address, ERC20_ABI, provider)
}
