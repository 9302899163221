import { useState, useEffect } from "react"
import { toast } from "react-toastify"

//eslint-disable-next-line
export const SettingLiquidity = ({ setIseSettingOpen, isSettingOpen, setSetting, setting }) => {
  const [selectedSetting, setSelectedSetting] = useState({
    slippage: setting[isSettingOpen.type].slippage,
    deadLine: setting[isSettingOpen.type].deadLine,
  })

  const selected = "w-14 h-8 rounded-custom flex justify-center items-center bg-primary-color text-white"
  const notSelected = "w-14 h-8 rounded-custom flex justify-center items-center bg-card-light-blue"

  const handleSet = (e) => {
    setSelectedSetting({ ...selectedSetting, [e.target.name]: e.target.value })
    if (e.target.id !== "input-slippage") {
      document.getElementById("input-slippage").value = ""
    }
  }

  const saveSetting = () => {
    let temp = setting
    temp[isSettingOpen.type] = selectedSetting

    setSetting(temp)
    toast("Setting saved")
  }

  useEffect(() => {
    if (selectedSetting.slippage === "") {
      setSelectedSetting({ ...selectedSetting, slippage: "0.5" })
    }

    if (selectedSetting.deadLine === "") {
      setSelectedSetting({ ...selectedSetting, deadLine: "90" })
    }

    if (
      selectedSetting.slippage !== "0.5" &&
      selectedSetting.slippage !== "1" &&
      selectedSetting.slippage !== "3"
    )
      document.getElementById("input-slippage").value = selectedSetting.slippage
  }, [selectedSetting])

  // const firstUpper = (data) => {
  //   const firstChar = data.charAt(0)

  //   return firstChar.toUpperCase() + data.slice(1)
  // }

  return (
    <>
      <div className="flex flex-col min-w-[320px] rounded-custom max-w-[450px] bg-card-color border border-card-border shadow-2xl p-4 gap-6 text-primary-text">
        <div className="flex flex-col gap-4">
          <h5 className=" font-bold">Slippage Tolerance</h5>
          <div className="flex flex-row gap-3 text-sm justify-between">
            <button
              id="slippage"
              name="slippage"
              value="0.5"
              onClick={(e) => handleSet(e)}
              className={`${
                selectedSetting.slippage === "0.5" ? selected : notSelected
              } w-full rounded-custom flex justify-center items-center px-4 py-2 font-bold`}
            >
              0.5%
            </button>
            <button
              id="slippage"
              name="slippage"
              value="1"
              onClick={(e) => handleSet(e)}
              className={`${
                selectedSetting.slippage === "1" ? selected : notSelected
              } w-full rounded-custom flex justify-center items-center px-4 py-2 font-bold`}
            >
              1%
            </button>
            <button
              id="slippage"
              name="slippage"
              value="3"
              onClick={(e) => handleSet(e)}
              className={`${
                selectedSetting.slippage === "3" ? selected : notSelected
              } w-full rounded-custom flex justify-center items-center px-4 py-2 font-bold`}
            >
              3%
            </button>
          </div>
          <div className="w-full rounded-custom flex justify-between items-center px-4 py-2 font-bold bg-card-light-blue">
            <input
              id="input-slippage"
              name="slippage"
              type="number"
              onChange={handleSet}
              className="bg-transparent"
            />
            <p>%</p>
          </div>
          <p
            className={`text-primary-text font-bold pt-3 ${selectedSetting.slippage === "0" ? "" : "hidden"}`}
          >
            Slippage can be 0
          </p>
        </div>
        <div className="text-primary-text font-bold flex flex-col gap-4">
          <p className=" ">Transaction Deadline</p>
          <div className="w-full rounded-custom flex justify-between items-center px-4 py-2 font-medium bg-card-light-blue">
            <input
              id="deadLine"
              name="deadLine"
              type="number"
              onChange={handleSet}
              className="bg-transparent"
            />
            <p>Minutes</p>
          </div>
        </div>
        <div>
          <button onClick={saveSetting} className="interaction-button">
            Save Settings
          </button>
        </div>
      </div>
    </>
  )
}
