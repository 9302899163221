import { baseToken } from "../selectToken/baseToken"
import noSymbol from "../images/tokens/noSymbol.png"
import { WETH_ADDRESS } from "../../constants"
import { Link } from "react-router-dom"
import { addressSlice, displayDecimals } from "../../utils"
import { useState } from "react"

export const LiquidityItem = ({ lp, setTokenSelection, setPair }) => {
  const [disabled, setDisabled] = useState(false)

  return (
    <>
      <button
        disabled={disabled}
        onClick={async () => {
          setTokenSelection({
            tokenA: lp.pairToken0 === WETH_ADDRESS ? "ETH" : lp.pairToken0,
            symbolA: lp.token0Symbol === "WETH" ? "ETH" : lp.token0Symbol,
            tokenB: lp.pairToken1 === WETH_ADDRESS ? "ETH" : lp.pairToken1,
            symbolB: lp.token1Symbol === "WETH" ? "ETH" : lp.token1Symbol,
          })
          setPair({
            pairAddress: lp.pairAddress,
            pairBalance: lp.pairBalance,
            token0: lp.pairToken0 === WETH_ADDRESS ? "ETH" : lp.pairToken0,
            token1: lp.pairToken1 === WETH_ADDRESS ? "ETH" : lp.pairToken1,
            reserve0: lp.reserve0,
            reserve1: lp.reserve1,
            symbol0: lp.token0Symbol === "WETH" ? "ETH" : lp.token0Symbol,
            symbol1: lp.token1Symbol === "WETH" ? "ETH" : lp.token1Symbol,
            totalSupply: lp.totalSupply,
            ratio: lp.ratio,
            userReserve0: lp.userReserve0,
            userReserve1: lp.userReserve1,
          })
        }}
        className="bg-card-light-blue h-[120px] w-full p-6 flex-col flex justify-between  rounded-custom "
      >
        <div className="flex justify-between w-full h-6">
          <div className="relative flex flex-row h-6 w-12">
            <div className="absolute left-0">
              <img
                className="w-6"
                src={baseToken.find((item) => item.address === lp.pairToken0)?.img || noSymbol}
              />
            </div>
            <div className="absolute left-5">
              <img
                className="w-6"
                src={baseToken.find((item) => item.address === lp.pairToken1)?.img || noSymbol}
              />
            </div>
          </div>
          <Link
            to={`https://basescan.org/address/${lp.pairAddress}`}
            target="_blank"
            className="underline text-[16px] z-10"
            onMouseEnter={() => setDisabled(true)}
            onMouseLeave={() => setDisabled(false)}
          >
            {addressSlice(lp.pairAddress)}
          </Link>
        </div>
        <div className="flex flex-row gap-1 font-bold text-[16px]">
          <p>
            {displayDecimals(lp.userReserve0) + " " + (lp.token0Symbol === "WETH" ? "ETH" : lp.token0Symbol)}
          </p>
          <p>/</p>
          <p>
            {displayDecimals(lp.userReserve1) + " " + (lp.token1Symbol === "WETH" ? "ETH" : lp.token1Symbol)}
          </p>
        </div>
      </button>
    </>
  )
}
