import { Oval } from "react-loader-spinner"

export const Spinner = ({ size }) => {
  return (
    <Oval
      height={size}
      width={size}
      radius="9"
      color="#3294f8"
      secondaryColor="#3294f850"
      ariaLabel="three-dots-loading"
    />
  )
}
