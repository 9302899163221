import { useEffect, useState } from "react"
import "./sidebar.css"
import { SidebarElement } from "./sidebarElement"
import { VscArrowSwap } from "react-icons/vsc"
import { BiCoinStack } from "react-icons/bi"
import { TbMoneybag } from "react-icons/tb"
import { HiOutlineRocketLaunch } from "react-icons/hi2"
import { SidebarCard } from "./sidebarCard"
import { SidebarBridge } from "./sidebarBridge"
import { Link, useLocation } from "react-router-dom"
import { PiCoinLight } from "react-icons/pi"
import { TbTower } from "react-icons/tb"
import { FaChartLine } from "react-icons/fa6"

//eslint-disable-next-line
export const Sidebar = ({ sidebarOpen, setSidebarOpen, isMobile }) => {
  const [isHovered, setIsHovered] = useState(-1)
  const location = useLocation()

  useEffect(() => {
    if (isMobile && sidebarOpen) {
      setSidebarOpen(false)
    }
  }, [location])

  const items = [
    {
      name: "Swap",
      path: "/",
      icon: VscArrowSwap,
    },
    {
      name: "Perpetual",
      path: "https://perps.citadelswap.xyz/",
      icon: FaChartLine,
    },
    {
      name: "Liquidity",
      path: "/liquidity",
      icon: BiCoinStack,
    },
    {
      name: "Farms",
      path: "/farms",
      icon: TbMoneybag,
    },
    {
      name: "esFORT",
      path: "/esfort",
      icon: TbTower,
    },
    {
      name: "Dividends",
      path: "/dividends",
      icon: PiCoinLight,
    },
    {
      name: "Launchpad",
      path: "/launchpad",
      icon: HiOutlineRocketLaunch,
    },
  ]

  //eslint-disable-next-line
  const closeOnMobile = () => {
    if (isMobile) {
      setSidebarOpen(false)
    }
  }

  return (
    <div
      className={`${isMobile ? "absolute z-5-1" : ""} h-full max-h-screen flex transition-all duration-500 ${
        !sidebarOpen ? "w-0" : isMobile ? "w-screen p-4" : "min-w-[248px] max-w-[248px] pb-4"
      } bg-card-color text-white flex flex-col justify-between z-50 overflow-hidden`}
    >
      <div className="flex flex-row justify-between items-center min-w-[200px] w-full px-[30px] min-h-[64px] mb-[40px]">
        <Link to={"https://www.citadelswap.xyz/"}>
          <img
            src="https://uploads-ssl.webflow.com/64f09ac675e6a33325b4e9cd/64f09e0f1a6f67749a483f03_LOGO%20WEB-WHITE-p-500.png"
            className="hover:scale-[0.92] transition-transform duration-500"
          />
        </Link>

        <button onClick={() => setSidebarOpen(false)} className="h-fit self-center lg:hidden pl-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            className="h-6 w-6"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div className="flex flex-col gap-[40px] h-full overflow-auto max-h-full">
        <div className="flex flex-col gap-6 px-6 h-fit">
          {items.map((item, index) => (
            <SidebarElement
              key={index}
              name={item.name}
              path={item.path}
              icon={item.icon}
              index={index}
              isHovered={isHovered}
              setIsHovered={setIsHovered}
            />
          ))}
          <SidebarBridge index={items.length} setIsHovered={setIsHovered} isHovered={isHovered} />
        </div>
      </div>
      <SidebarCard />
    </div>
  )
}
