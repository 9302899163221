import ContentLoader from "react-content-loader"
import { CardHeader } from "../utils/cardHeader"
import { LiquidityItem } from "./liqudityItem"

export const MyLiquidity = ({ loading, allLP, setTokenSelection, setPair, active, setManualSelection }) => {
  return (
    <>
      <div className="bg-card-color card-custom-w rounded-custom p-6 text-primary-text flex flex-col gap-6">
        <div className="w-full text-left">
          <CardHeader text={"Liquidity"} />
        </div>
        <div className="flex flex-col gap-4">
          <p className="font-bold text-[18px]">Your Liquidity</p>

          <div className="rounded-custom text-sm w-full flex flex-col gap-2">
            {loading ? (
              <ContentLoader
                speed={2}
                width={"100%"}
                height={"100%"}
                viewBox="0 0 476 124"
                backgroundColor="var(--card-light)"
                foregroundColor="var(--primary-color)"
              >
                <rect x="262" y="11" rx="3" ry="3" width="180" height="12" />
                <rect x="7" y="95" rx="3" ry="3" width="294" height="17" />
                <circle cx="20" cy="20" r="20" />
                <circle cx="51" cy="21" r="20" />
              </ContentLoader>
            ) : // <div className="h-[120px] w-full rounded-custom bg-gradient-to-r from-card-light-blue via-text-gray to-dark-blue bg-300%  animate-gradient" />
            allLP.length > 0 ? (
              allLP.map((lp, index) => (
                <LiquidityItem key={index} lp={lp} setPair={setPair} setTokenSelection={setTokenSelection} />
              ))
            ) : active ? (
              <div className="h-[120px] w-full rounded-custom bg-card-light-blue text-center flex justify-center items-center">
                <p className=" font-bold">No Liquidity found.</p>
              </div>
            ) : (
              <div className="h-[120px] w-full rounded-custom bg-card-light-blue text-center flex justify-center items-center">
                <p className=" font-bold">Connect your wallet first!</p>
              </div>
            )}
          </div>
          <p className="text-[14px] font-normal">
            If you staked LP tokens in a farm, unstake them in order to view that liquidity
          </p>
        </div>
        <div className="flex flex-col text-center gap-4">
          <p className="text-[14px] text-gray-text">Add liquidity to receive LP tokens</p>
          <button
            className="w-full h-[56px] rounded-custom interaction-button"
            onClick={() => setManualSelection(true)}
          >
            <p className="font-bold text-[16px]">Manage Liquidity</p>
          </button>
        </div>
      </div>
    </>
  )
}
