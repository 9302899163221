import { useStore } from "@nanostores/react"
import { approve } from "../../API/erc20"
import { useWeb3React } from "@web3-react/core"
import { ROUTER_ADDRESS } from "../../constants"
import { toast } from "react-toastify"
import { ReceiptToast } from "../toast/receiptToast"
import { Spinner } from "../spinner/Spinner"
import {
  calculateSlippageAmount,
  swapTokensForTokens,
  swapTokensForETHs,
  swapETHsForTokens,
} from "../../API/router"
import { buildPath, limitDecimalPlaces } from "../../utils"

export const RouterSwapButton = ({ context }) => {
  const { library, account } = useWeb3React()

  // const swapValue = useStore(swapConfig.get().swapValue)
  // const inputA = useStore(swapConfig.get().inputA)
  // const inputB = useStore(swapConfig.get().inputB)
  // const outputB = useStore(swapConfig.get().outputB)
  // const pair = useStore(swapConfig.get().pairData)
  // const pairError = useStore(swapConfig.get().error)
  const outputA = useStore(context.inputA)
  const outputB = useStore(context.outputB)
  const swapSettings = useStore(context.swapSettings)
  const selectedTokens = useStore(context.selectedTokens)
  const allowance = useStore(context.userAllowance)
  const pairLoading = useStore(context.pairLoading)

  const setLoadingButton = (value) => {
    context.setPairLoading(value)
  }

  const executeApprove = async () => {
    setLoadingButton(true)
    let tx
    try {
      tx = await approve(ROUTER_ADDRESS, outputA, selectedTokens.a.address, library)
      toast(<ReceiptToast txHash={tx.hash} />)
    } catch (e) {
      console.log(e)
      setLoadingButton(false)
      return
    }

    if (!tx) {
      toast("Transaction error")
      setLoadingButton(false)
      return
    }

    tx.wait().then((res) => {
      if (res.status === 1) {
        context.updateAllowance("a")
        toast("Transaction Success!")
      } else {
        toast("Transaction Failed!")
      }

      setLoadingButton(false)
    })
  }

  const execute = async () => {
    setLoadingButton(true)
    const slippageAmount = calculateSlippageAmount(outputB, swapSettings.slippage)

    const calculatedDeadLine = (Date.now() / 1000).toFixed(0) + swapSettings.deadLine + ""

    //TODO - refactor this function to read directly from SwapContextClass
    const path = buildPath({ tokenA: selectedTokens.a.address, tokenB: selectedTokens.b.address })

    if (selectedTokens.a.address === "ETH") {
      swapETHsForTokens(outputA, slippageAmount, path, account, account, library, calculatedDeadLine)
        .then((tx) => {
          if (!tx) {
            setLoadingButton(true)
            toast("Transaction error!")
            return
          }

          toast(<ReceiptToast txHash={tx.hash} />)
          tx.wait()
            .then((res) => {
              if (res.status === 1) {
                toast("Transaction Success!")
                context.updateBalances()
              } else toast("Transaction Failed!")

              setLoadingButton(false)
            })
            .catch(() => {
              setLoadingButton(false)
              toast("Transaction Failed!")
            })
        })
        .catch((e) => {
          setLoadingButton(false)
          console.error(e)
        })
    } else if (selectedTokens.b.address === "ETH") {
      swapTokensForETHs(outputA, slippageAmount, path, account, account, library, calculatedDeadLine)
        .then((tx) => {
          if (!tx) {
            toast("Transaction error!")
            setLoadingButton(true)
            return
          }
          toast(<ReceiptToast txHash={tx.hash} />)
          tx.wait()
            .then((res) => {
              if (res.status === 1) {
                toast("Transaction Success!")
                context.updateBalances()
              } else toast("Transaction Failed!")

              setLoadingButton(false)
            })
            .catch((e) => {
              console.log(e)
              setLoadingButton(false)
              toast("Transaction Failed!")
            })
        })
        .catch(() => {
          setLoadingButton(false)
          console.error("Transaction failed")
        })
    } else {
      await swapTokensForTokens(outputA, slippageAmount, path, account, account, library, calculatedDeadLine)
        .then((tx) => {
          if (!tx) {
            toast("Transaction error!")
            setLoadingButton(false)
            return
          }

          toast(<ReceiptToast txHash={tx.hash} />)
          tx.wait()
            .then((res) => {
              if (res.status === 1) {
                toast("Transaction Success!")
                context.updateBalances()
              } else toast("Transaction Failed!")

              setLoadingButton(false)
            })
            .catch((e) => {
              console.log(e)
              setLoadingButton(false)
              toast("Transaction Failed!")
            })
        })
        .catch((e) => {
          setLoadingButton(false)
          console.error(e)
        })
    }
  }

  return (
    <>
      {pairLoading ? (
        <button disabled className="disabled-button">
          <Spinner size={"30"} />
        </button>
      ) : outputB ? (
        allowance.a >= limitDecimalPlaces(outputA, selectedTokens.a.decimal) ? (
          <button onClick={execute} className="interaction-button">
            Swap
          </button>
        ) : (
          <button onClick={executeApprove} className="interaction-button">
            Approve
          </button>
        )
      ) : (
        <button disabled onClick={execute} className="disabled-button">
          Insert amount
        </button>
      )}
    </>
  )
}
