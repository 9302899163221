import { atom } from "nanostores"
import { allowanceParsed, balanceOfParsed, decimal as getDecimal } from "../../API/erc20"
import { providerReadOnly } from "../../state/state"
import { buildPath, parseWeiToNumber, removeLeadingZero } from "../../utils"
import { ROUTER_ADDRESS, WETH_ADDRESS } from "../../constants"
import { getAmountsOutWithDecimal, getPair } from "../../API/router"
import { getPairToken, getReserve, isStable } from "../../API/pair"
import { ethers, formatUnits } from "ethers"

export class SwapContext {
  userProvider = atom(null)
  userAddress = atom(null)

  inputA = atom(0)
  inputB = atom(0)

  outputA = atom(0)
  outputB = atom(0)

  userBalance = atom({
    a: 0,
    b: 0,
  })
  userAllowance = atom({
    a: 0,
    b: 0,
  })

  balanceLoading = atom(false)

  error = atom()

  pairData = atom({
    address: "",
    addressA: "",
    addressB: "",
    reserveA: undefined,
    reserveB: undefined,
    totalSupply: undefined,
    feeTokenA: 0,
    feeTokenB: 0,
    valid: "",
  })
  pairLoading = atom(false)

  swapSettings = atom({
    slippage: 0.5,
    deadLine: 90,
  })

  selectedTokens = atom({
    a: {
      address: "",
      name: "",
      decimal: "",
    },
    b: {
      address: "",
      name: "",
      decimal: "",
    },
  })

  constructor() {
    this.setDefault()
    this.userAddress.listen((current) => {
      if (current) {
        this.updateBalances()
      }
    })

    this.selectedTokens.subscribe(() => {
      this.handlePairUpdate()
    })
  }

  standardLog = (data = "") => {
    console.log("SWAP-CONTEXT:: ", data)
  }

  handlePairUpdate = () => {
    const tokenA = this.selectedTokens.get().a
    const tokenB = this.selectedTokens.get().b

    const currentPath = [this.pairData.get().addressA, this.pairData.get().addressB]

    if (
      currentPath.includes(tokenA.address) &&
      currentPath.includes(tokenB.address) &&
      tokenA.address !== tokenB.address
    ) {
      return
    }

    this.updatePairData().then(() => this.quoteToken("a"))
  }

  adjustETHAddress = (address) => {
    return address === "ETH" ? WETH_ADDRESS : address
  }

  setEmptyPairData = () => {
    this.pairData.set({
      address: null,
      addressA: null,
      addressB: null,
      reserveA: 0,
      reserveB: 0,
      feeTokenA: 0,
      feeTokenB: 0,
      totalSupply: 0,
    })
  }

  updatePairData = async (refresh = false) => {
    this.standardLog("updating pair data")
    this.pairLoading.set(true)
    this.error.set(null)
    const tokenA = this.selectedTokens.get().a
    const tokenB = this.selectedTokens.get().b

    if (tokenA.address === tokenB.address) {
      this.error.set("Select different tokens")
      this.setEmptyPairData()
      this.pairLoading.set(false)
      return
    }

    const address = await getPair(this.adjustETHAddress(tokenA.address), tokenB.address)

    if (!address) {
      let path = buildPath({ tokenA: tokenA.address, tokenB: tokenB.address })
      if (!path) {
        this.error.set("Pair not found")
        this.setEmptyPairData()
      }
      this.pairLoading.set(false)
      return
    }

    if (address === this.pairData.get().address && !refresh) {
      this.pairLoading.set(false)
      return
    }

    const stable = await isStable(address)

    const addressA = await getPairToken(0, address)
    let decimalA = this.adjustETHAddress(tokenA.address) === addressA ? tokenA.decimal : tokenB.decimal

    if (!decimalA) {
      decimalA = await this.setDecimal(this.adjustETHAddress(tokenA.address) === addressA ? "a" : "b")
    }

    const addressB = await getPairToken(1, address)
    let decimalB = this.adjustETHAddress(tokenB.address) === addressB ? tokenB.decimal : tokenA.decimal

    if (!decimalB) {
      decimalB = await this.setDecimal(this.adjustETHAddress(tokenB.address) === addressB ? "b" : "a")
    }

    const reservers = await getReserve(address)
    const reserveA = ethers.formatUnits(reservers[0] + "", decimalA)
    const reserveB = ethers.formatUnits(reservers[1] + "", decimalB)
    const weiReserveA = reservers[0] + ""
    const precisionMultiplierA = 10 ** decimalA
    const weiReserveB = reservers[1] + ""
    const precisionMultiplierB = 10 ** decimalB
    const feeTokenA = reservers[2] / 100000
    const feeTokenB = reservers[3] / 100000

    const newPairData = {
      address,
      addressA,
      addressB,
      reserveA,
      reserveB,
      feeTokenA,
      feeTokenB,
      stable,
      weiReserveA,
      precisionMultiplierA,
      weiReserveB,
      precisionMultiplierB,
    }

    this.pairData.set(newPairData)

    console.log(`new pair data: `, newPairData)

    this.pairLoading.set(false)
  }

  setToken = (type) => async (address, name) => {
    if (address === this.selectedTokens.get()[type].address) {
      return null
    }

    const data = { ...this.selectedTokens.get() }

    let decimal = undefined

    if (address === "ETH") {
      decimal = 18
    }

    data[type] = {
      address,
      name,
      decimal,
    }

    this.selectedTokens.set(data)
    if (this.userAddress.get()) this.updateBalances(type)
  }

  invertSwap = () => {
    if (this.balanceLoading.get()) {
      return
    }

    //TODO - why its work?!
    this.inputA.set(this.inputB.get())
    this.inputB.set(this.inputA.get())
    this.outputA.set(this.outputB.get())
    this.outputB.set(this.outputA.get())

    this.selectedTokens.set({
      a: {
        address: this.selectedTokens.get().b.address,
        name: this.selectedTokens.get().b.name,
        decimal: this.selectedTokens.get().b.decimal,
      },
      b: {
        address: this.selectedTokens.get().a.address,
        name: this.selectedTokens.get().a.name,
        decimal: this.selectedTokens.get().a.decimal,
      },
    })

    this.invertUserData()

    this.standardLog(`userAllowance token a: ${this.userAllowance.get().a}`)
    this.standardLog(`userAllowance token b: ${this.userAllowance.get().b}`)

    this.quoteToken("a")
  }

  invertUserData = () => {
    this.userBalance.set({
      a: this.userBalance.get().b,
      b: this.userBalance.get().a,
    })

    this.userAllowance.set({
      a: this.userAllowance.get().b,
      b: this.userAllowance.get().a,
    })
  }

  setSwapSettings = () => {}

  updateBalances = async (type) => {
    console.log("SWAP-CONTEXT-updatingBalance::", type)
    this.balanceLoading.set(true)
    if (!type) {
      await this.updateBalance("a")
      await this.updateBalance("b")
    } else {
      await this.updateBalance(type)
    }
    this.balanceLoading.set(false)
  }

  updateBalance = async (type) => {
    const tokenAddress = this.selectedTokens.get()[type].address
    let decimal = this.selectedTokens.get()[type].decimal

    if (!decimal) {
      decimal = await this.setDecimal(type)
    }

    let balance = 0

    if (tokenAddress === "ETH") {
      const current = await providerReadOnly.getBalance(this.userAddress.get())
      balance = parseWeiToNumber(current)
    } else {
      balance = await balanceOfParsed(this.userAddress.get(), tokenAddress, decimal)
    }

    const data = { ...this.userBalance.get() }

    data[type] = balance

    await this.updateAllowance(type)
    this.userBalance.set(data)
  }

  updateAllowance = async (type) => {
    const tokenAddress = this.selectedTokens.get()[type].address
    let decimal = this.selectedTokens.get()[type].decimal

    if (!decimal) {
      decimal = await this.setDecimal(type)
    }

    let allowance = 0

    if (tokenAddress === "ETH") {
      const current = await providerReadOnly.getBalance(this.userAddress.get())
      allowance = parseWeiToNumber(current)
    } else {
      allowance = await allowanceParsed(this.userAddress.get(), ROUTER_ADDRESS, tokenAddress, decimal)
    }

    const data = { ...this.userAllowance.get() }

    data[type] = allowance

    this.userAllowance.set(data)

    this.standardLog(`userAllowance token ${type}: ${allowance}`)
  }

  setDecimal = async (type) => {
    const data = { ...this.selectedTokens.get() }

    const decimal = await getDecimal(data[type].address)

    data[type].decimal = decimal

    this.selectedTokens.set(data)

    return decimal
  }

  quoteToken = async (inserted) => {
    if (this.error.get()) {
      return
    }
    const selectedA = this.selectedTokens.get().a
    const selectedB = this.selectedTokens.get().b
    const actualPair = this.pairData.get()

    let tokenIn = inserted === "a" ? selectedA.address : selectedB.address
    tokenIn = this.adjustETHAddress(tokenIn)

    //eslint-disable-next-line no-unused-vars
    const feePercentage = tokenIn === actualPair.addressA ? actualPair.feeTokenA : actualPair.feeTokenB
    let amountIn = inserted === "a" ? this.inputA.get() : this.inputB.get()
    let result = 0

    if (!amountIn || amountIn === 0) {
      this.inputA.set(removeLeadingZero(this.inputA.get()))
      this.outputB.set(0)
      return
    }

    this.pairLoading.set(true)

    const path = buildPath({ tokenA: selectedA.address, tokenB: selectedB.address })

    if (!path) {
      this.inputA.set(removeLeadingZero(this.inputA.get()))
      this.outputB.set(0)
      this.error.set("Pair not found")
      return
    }

    this.inputA.set(removeLeadingZero(this.inputA.get()))
    const amountOut = await getAmountsOutWithDecimal(amountIn, path, selectedA.decimal)

    result = formatUnits(amountOut[amountOut.length - 1] + "", selectedB.decimal)
    this.pairLoading.set(false)

    this.inputA.set(removeLeadingZero(this.inputA.get()))
    this.outputB.set(result.toString())
  }

  // if (actualPair.stable) {
  //   this.outputA.set(removeLeadingZero(this.inputA.get()))
  //   if (!amountIn) {
  //     result = 0
  //   } else {
  //     this.pairLoading.set(true)
  //     const amountOut = await getAmountsOutWithDecimal(
  //       amountIn,
  //       [selectedA.address, selectedB.address],
  //       selectedA.decimal
  //     )

  //     result = formatUnits(amountOut[1] + "", selectedB.decimal)
  //     this.pairLoading.set(false)
  //   }
  // } else {
  //   const { reserveA, reserveB } =
  //     tokenIn === actualPair.addressA
  //       ? { reserveA: actualPair.reserveA, reserveB: actualPair.reserveB }
  //       : { reserveA: actualPair.reserveB, reserveB: actualPair.reserveA }
  //   amountIn = +amountIn - amountIn * feePercentage
  //   result = (+amountIn * +reserveB) / (+reserveA + +amountIn)
  // }

  // if (inserted === "a") {
  //   this.outputA.set(removeLeadingZero(this.inputA.get()))
  //   this.outputB.set(result.toString())
  // } else {
  //   this.outputA.set(result.toString())
  //   this.outputB.set(removeLeadingZero(this.inputB.get()))
  // }
  //}

  setDefault = () => {
    this.selectedTokens.set({
      a: {
        address: "ETH",
        name: "ETH",
        decimal: 18,
      },
      b: {
        address: "0x7233062d88133B5402D39D62BFa23a1b6c8d0898",
        name: "FORT",
        decimal: 18,
      },
    })

    this.pairData.set({
      address: "",
      reserveA: 0,
      reserveB: 0,
      totalSupply: 0,
      feeTokenA: 0,
      feeTokenB: 0,
    })
  }

  setUser = (address, provider) => {
    this.userProvider.set(provider)
    this.userAddress.set(address)

    if (!address) {
      this.userBalance.set({
        a: 0,
        b: 0,
      })
    }
  }

  setSettings = (settings) => {
    this.swapSettings.set(settings)
  }

  setPairLoading = (isLoading) => {
    this.pairLoading.set(isLoading)
  }
}

export const SwapContextAtom = atom(new SwapContext(undefined, undefined))
