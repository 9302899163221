//eslint-disable-next-line
import { useEffect, useState } from "react"
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md"
import { ConnectionSidebarContent } from "./connectioonSidebarContent"
import { useWeb3React } from "@web3-react/core"
export const ConnectionSidebar = ({ connectionSidebarOpen, setConnectionSidebarOpen, isMobile }) => {
  const [hover, setHover] = useState(false)
  const { active } = useWeb3React()

  useEffect(() => {
    if (!active) {
      return
    }

    setTimeout(() => {
      setConnectionSidebarOpen(false)
    }, 300)
  }, [active])
  return (
    <div
      className={`${
        connectionSidebarOpen
          ? isMobile
            ? "h-[calc(100vh-76px)] bottom-0"
            : "w-[400px]"
          : isMobile
          ? "h-0 opacity-0"
          : "w-0 opacity-0"
      } overflow-hidden transition-all duration-500 ${
        isMobile ? "fixed bottom-0 w-full rounded-t-custom flex-col" : "h-screen flex-row p-4"
      } flex absolute right-0 justify-end items-end z-50`}
    >
      <div
        className={`${
          isMobile ? "h-full w-full flex-col rounded-t-custom" : "h-full w-fit flex-row rounded-custom"
        }  ${hover && !isMobile ? "bg-card-color/50" : ""} flex transition-all duration-300 `}
      >
        <button
          onClick={() => setConnectionSidebarOpen(false)}
          className={` ${
            isMobile ? "hidden" : "h-full"
          } w-14 hover:w-12 transition-all duration-300 rounded-l-custom p-4 flex flex-col items-start`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <MdOutlineKeyboardDoubleArrowRight size={20} color="white" />
        </button>
        <div
          className={`bg-card-color h-full ${
            isMobile ? "w-full rounded-t-custom" : "w-[330px] rounded-custom"
          } p-4`}
        >
          <ConnectionSidebarContent
            connectionSidebarOpen={connectionSidebarOpen}
            isMobile={isMobile}
            setConnectionSidebarOpen={setConnectionSidebarOpen}
          />
        </div>
      </div>
    </div>
  )
}
