import {
  DAI_PLUS_ADDRESS,
  FORT_ADDRESS,
  USDBC_ADDRESS,
  USDC_ADDRESS,
  USD_PLUS_ADDRESS,
  WETH_ADDRESS,
  esFORT_ADDRESS,
} from "../../constants"
import leaf from "../images/token.png"
import usdplus from "../images/tokens/USD+.png"

/**
 * @type {Array<{address: string, name: string, img: string}>}
 * @description This array contains the base tokens that are used in the app
 * @property {string} address - The address of the token
 * @property {string} name - The name of the token
 * @property {string} img - The image of the token (URL)
 */
export const baseToken = [
  {
    address: FORT_ADDRESS,
    name: "FORT",
    img: leaf,
    display: true,
  },
  {
    address: esFORT_ADDRESS,
    name: "esFORT",
    img: leaf,
    display: false,
  },
  {
    address: "ETH",
    name: "ETH",
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    display: true,
  },
  {
    address: WETH_ADDRESS,
    name: "WETH",
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    display: false,
  },
  {
    address: USDC_ADDRESS,
    name: "USDC",
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
    display: true,
  },
  {
    address: USDBC_ADDRESS,
    name: "USDbC",
    img: "https://basescan.org/token/images/usdbc_ofc_32.png",
    display: true,
  },
  {
    address: USD_PLUS_ADDRESS,
    name: "USD+",
    img: usdplus,
    display: true,
  },
  {
    address: DAI_PLUS_ADDRESS,
    name: "DAI+",
    img: "https://basescan.org/token/images/overnight_32.png",
    display: true,
  },
]
