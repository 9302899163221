import { Link } from "react-router-dom"
import { FiExternalLink } from "react-icons/fi"

export const LaunchDescription = ({ title = "How to participate", type }) => {
  const publicSteps = [
    {
      title: "Before the Sale",
      content: "Buy ETH and Set up your wallet on the BASE network",
      // link: [
      //   {
      //     url: "",
      //     name: "Buy ETH",
      //   },
      //   {
      //     url: "",
      //     name: "Set your wallet",
      //   },
      // ],
    },
    {
      title: "During the Sale",
      content: [
        "You can participate by investing ETH on the Launchpad.",
        "Any excess ETH will be automatically refunded once the sale has ended. You will be able to farm extra $esFORT during the whole sale period, sharing the farming pool with other participants.",
      ],
      dotted: false,
    },
    {
      title: "After the Sale",
      content: [
        "After the sale has ended, participants will immediately be able to claim back the excess ETH and their respective $FORT and $esFORT allocations.",
        "The claim procedure will be shown in the UI.",
      ],
    },
  ]

  const privateSteps = [
    {
      title: "Before the Sale",
      content: "Buy ETH and set up your wallet on the BASE Network",
    },
    {
      title: "During the Sale",
      content:
        "You can participate by simply investing ETH on the Launchpad. The sale will conclude once the Hard Cap will be reached",
      dotted: false,
    },
    {
      title: "After the Sale",
      content:
        "You will be able to claim your $FORT and $esFORT allocations once the public sale ends. The claim procedure will be shown in the UI.",
    },
  ]
  const Content = ({ data }) => {
    if (Array.isArray(data)) {
      return (
        <div className="flex flex-col gap-1">
          {data.map((item, index) => (
            <p className="text-gray-text font-normal text-[14px]" key={index}>
              {item}
            </p>
          ))}
        </div>
      )
    } else {
      return <p className="text-gray-text font-normal text-[14px]">{data}</p>
    }
  }

  const ItemLink = ({ data }) => {
    return (
      <div className="w-full flex flex-row justify-start gap-4">
        {data.map((item) => (
          <Link key={item.url} to={item.url} target="_blank">
            <div className="flex items-center gap-1 font-semibold">
              <p className="text-primary-color">{item.name}</p>
              <FiExternalLink color="var(--primary-color)" />
            </div>
          </Link>
        ))}
      </div>
    )
  }

  return (
    <div className="text-white w-10/12 max-md:w-11/12 max-xx:w-10/12 self-center rounded-custom py-[34px] px-12 bg-card-color shadow-custom z-10">
      <div className="w-full text-start text-[22px] font-medium h-12">
        <p>{title}</p>
      </div>
      <div className="flex flex-row justify-between w-full max-sm:flex-col max-sm:gap-6">
        {(type === "private" ? privateSteps : publicSteps).map((step, index) => (
          <div key={index} className="flex flex-col justify-start w-[30%] max-sm:w-full">
            <div className="bg-primary-color text-card-color rounded-full h-[30px] w-[30px] flex justify-center items-center font-bold mb-[10px]">
              {index + 1}
            </div>
            <p className="text-white font-medium text-[18px]">{step.title}</p>
            <Content data={step.content} />
            {!step.link ? "" : <ItemLink data={step.link} />}
          </div>
        ))}
      </div>
    </div>
  )
}
