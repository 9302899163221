import ICO_ABI from "../ABI/ICO_ABI.json"
import { Contract } from "@ethersproject/contracts"
import { providerReadOnly } from "../state/state"
import { parseNumberToWeiETH, parseWeiToNumber } from "../utils"
import { ethers } from "ethers"
import { PRIVATE_ICO_ADDRESS } from "../constants"

// eslint-disable-next-line
export const ethersToRaise = async (address) => {
  const contract = connectContract(address)

  const total = await contract.ethersToRaise()

  return parseWeiToNumber(total)
}

export const getLimiterCommit = async (address) => {
  const contract = connectContract(address)

  let minCommit = await contract.minCommit()
  let maxCommit = await contract.maxCommit()
  minCommit = ethers.formatEther(minCommit + "")
  maxCommit = ethers.formatEther(maxCommit + "")

  return { minCommit, maxCommit }
}

export const started = async (address) => {
  const contract = connectContract(address)

  return contract.started()
}

export const endTime = async (address) => {
  const contract = connectContract(address)

  return contract.endTime()
}

export const receiveTime = async (address) => {
  const contract = connectContract(address)

  return contract.receiveTime()
}

export const finished = async (address) => {
  const contract = connectContract(address)

  return contract.finished()
}

export const commitments = async (address, account) => {
  const contract = connectContract(address)

  return contract.commitments(account)
}

export const simulateCommit = async (address, provider) => {
  const contract = connectContract(address, provider.getSigner())
  const data = await contract.simulateClaimNoUpdate()

  return data
}

export const commit = async (address, value, provider) => {
  const contract = connectContract(address, provider.getSigner())

  const parsedValue = parseNumberToWeiETH(value)

  return contract.commit({ value: parsedValue, gasLimit: 350000 })
}

export const finalEmissions = async (address, account) => {
  const contract = connectContract(address)

  return contract.finalEmissions(account)
}

export const claim = async (address, provider) => {
  const contract = connectContract(address, provider.getSigner())

  return contract.claim({ gasLimit: 350000 })
}

export const claim2 = async (address, provider) => {
  const contract = connectContract(address, provider.getSigner())

  return contract.claim2({ gasLimit: 350000 })
}

const connectContract = (address, provider = providerReadOnly) => {
  return new Contract(address, ICO_ABI, provider)
}

export const isOnWhitelist = async (account) => {
  const contract = connectContract(PRIVATE_ICO_ADDRESS)

  return contract.isOnWhitelist(account)
}

export const getTotalCommitments = async (ico_Address) => {
  const contract = connectContract(ico_Address)

  const total = await contract.totalCommitments()
  return parseWeiToNumber(total)
}
