import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Countdown from "react-countdown"
import bannerPublic from "../../images/banner/PublicSale.png"
import bannerPrivate from "../../images/banner/PrivateSale.png"

import { CountdownRenderer } from "../../esFort/dividends/countDown"
import { abbreviateMillions, capitalize } from "../../../utils"

//eslint-disable-next-line
export const PadDetail = ({ info, name, to, banner }) => {
  const [isStarted, setIsStarted] = useState(false)

  useEffect(() => {
    if (info.start <= Date.now()) {
      setIsStarted(true)
    }

    const interval = setInterval(() => {
      if (info.start <= Date.now()) {
        setIsStarted(true)
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [info.start])

  const DisabledButton = ({ value }) => {
    return (
      <button disabled className="w-full h-full rounded-custom bg-card-light-blue font-bold ">
        {value}
      </button>
    )
  }

  return (
    <div
      id="card-launch"
      className="bg-card-color w-[500px] h-fit rounded-custom max-sm:w-11/12 max-sm:h-fit p-6 flex flex-col gap-6 text-white max-sm:mb-6 shadow-custom"
    >
      <div
        id="img-launch"
        className="w-full h-32 bg-red rounded-custom bg-card-light-blue overflow-hidden relative"
      >
        <img
          src={banner ? banner : info.type === "private" ? bannerPrivate : bannerPublic}
          className={`${
            banner
              ? "absolute top-[-60px]"
              : "min-w-full min-h-full absolute top-50 left-50 transform -translate-x-50% -translate-y-50% object-cover"
          }`}
        />
      </div>
      <div className="w-full flex flex-col gap-2">
        <div id="header-launch" className="w-full h-fit flex flex-col gap-2">
          <p className="text-[16px] font-bold">{info.title}</p>
          <p className="text-[14px] font-normal text-gray-text hidden">{info.description}</p>
        </div>
        <div id="info-launch" className="flex flex-row max-sm:flex-col gap-4 max-sm:gap-2 w-full">
          <div className="flex flex-col gap-2 w-full">
            {info.infoLeft.map((info, index) => {
              return (
                <div key={index} className="flex flex-row justify-between w-full">
                  <p className="text-white text-[14px] font-normal">{info.name}</p>
                  <p className="text-gray-text text-[14px] font-bold">{info.value}</p>
                </div>
              )
            })}
          </div>
          <div className="flex flex-col gap-2 w-full">
            {info.infoRight.map((item, index) => {
              return (
                <div key={index} className="flex flex-row justify-between w-full">
                  <p className="text-white text-[14px] font-normal">
                    {item.name === "Distribution" ? capitalize(info.type) + " allocation" : item.name}
                  </p>
                  <p className="text-gray-text text-[14px] font-bold">
                    {item.name === "Distribution"
                      ? item.type === "string"
                        ? item.value
                        : abbreviateMillions(item.value, 0)
                      : item.value}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
        <div id="button-launch" className="w-full h-[48px]">
          {!info.start ? (
            <DisabledButton value={<p className="text-primary-color">TBA</p>} />
          ) : !isStarted ? (
            <Link to={to} className="flex justify-center w-full h-full">
              <button className="w-full h-full rounded-custom bg-card-light-blue text-primary-color font-bold">
                <Countdown date={info.start} renderer={CountdownRenderer} />
              </button>
            </Link>
          ) : (
            <Link to={to} className="flex justify-center w-full h-full">
              <button className={`disabled-button`}>Ended</button>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}
