import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { ethers } from "ethers"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../../toast/receiptToast"
import { updateInizializeReedem, xStakeUpdate } from "../../../../state/state"
import { FORT_STAKE, esFORT_ADDRESS } from "../../../../constants"
import { approve2 } from "../../../../API/xtoken"
import { doInitializeReedem } from "../../../../API/swordStake"
import { DisabledButton } from "../../../utils/disabledButton"
import { displayDecimals, removeLeadingZero } from "../../../../utils"
import { Spinner } from "../../../spinner/Spinner"

//eslint-disable-next-line
export const InizializeReedem = ({ balance, setBalance, balanceLoading }) => {
  const { active, library } = useWeb3React()
  const [data, setData] = useState({ amount: 0, days: 0 })
  const [ratio, setRatio] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [balance])

  const handleInputChange = (e) => {
    const name = e.target.name
    const value = removeLeadingZero(e.target.value)

    if (value.length > 1 && value.sta)
      if (!value) {
        if (name === "days") {
          let change = data
          change[name] = 0
          setData({ ...data, ...change })
          setRatio(ratio)
        }
        return
      }

    if (name === "days") {
      let ratio = 50 + ((value - 15) * 2) / 3
      setRatio(parseInt(ratio))
    }

    let change = data
    change[name] = value
    setData({ ...data, ...change })
  }

  const setMax = () => {
    handleInputChange({ target: { name: "amount", value: balance.parsed } })
  }

  const setDays = (value) => {
    handleInputChange({ target: { name: "days", value } })
  }

  const executeApprove = async () => {
    setLoading(true)
    approve2(FORT_STAKE, ethers.parseEther(data.amount) + "", library, esFORT_ADDRESS)
      .then((tx) => {
        if (!tx) {
          setLoading(false)
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              updateInizializeReedem.set(updateInizializeReedem.get() + 1)
            } else {
              setLoading(false)
              toast("Transaction Failed!")
            }
          })
          .catch((e) => {
            setLoading(false)
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => {
        setLoading(false)
        console.error(e)
      })
  }

  const execute = async () => {
    setLoading(true)
    doInitializeReedem(ethers.parseEther(data.amount) + "", data.days, library)
      .then((tx) => {
        if (!tx) {
          setLoading(false)
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              setLoading(false)
              toast("Transaction Success!")
              updateInizializeReedem.set(updateInizializeReedem.get() + 1)
              xStakeUpdate.set(xStakeUpdate.get() + 1)
            } else {
              setLoading(false)
              toast("Transaction Failed!")
            }
          })
          .catch((e) => {
            setLoading(false)
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => {
        setLoading(false)
        console.error(e)
      })
  }

  return (
    <div className="w-full h-full bg-card-color-solid rounded-custom flex flex-col items-center text-white justify-between gap-6">
      <div className="flex flex-col gap-2 text-left min-h-[140px]">
        <p className="text-[16px] font-bold">Redeem FORT</p>
        <p className="py-2 text-gray-text font-normal text-[14px]">Burn your esFORT to redeem back FORT.</p>
        <p className="text-gray-text font-normal text-[14px]">
          Redeem your esFORT back into FORT over a vesting period of 15 days (0.5:1 ratio) to 90 days (1:1
          {/* eslint-disable-next-line */}
          ratio). Without esFORT you won't get Launchpad access and Dividends earnings.
        </p>
      </div>
      <div className="flex flex-col gap-2 w-full">
        <div className="flex flex-row gap-[10px]">
          <div className="flex flex-col gap-2 bg-card-light-blue w-full p-4 rounded-custom">
            <button onClick={setMax} className="self-end pb-2 text-xs text-gray-text flex items-center">
              Balance: {balanceLoading ? <Spinner size={10} /> : displayDecimals(balance.parsed)}
            </button>
            <input
              onChange={handleInputChange}
              name="amount"
              type="number"
              className="w-10/12 bg-transparent"
              placeholder="0"
              value={data.amount}
            />
          </div>
          <div className="flex flex-col gap-2 bg-card-light-blue w-full p-4 rounded-custom">
            <p className="self-end text-xs pb-2 text-gray-text">
              Min <button onClick={() => setDays("15")}>15</button> - Max{" "}
              <button onClick={() => setDays("90")}>90</button>
            </p>
            <input
              onChange={handleInputChange}
              name="days"
              id="days"
              type="number"
              min="15"
              max="90"
              className="w-10/12 bg-transparent"
              placeholder="0"
              value={data.days}
            />
          </div>
        </div>

        {loading ? (
          <div className="loading-button">
            <Spinner size={30} />
          </div>
        ) : !active ? (
          <DisabledButton type={"rounded"} text={"Connect your wallet"} />
        ) : data.amount == 0 ? (
          <DisabledButton type={"rounded"} text={"Insert amount"} />
        ) : data.days < 15 || data.days > 90 ? (
          <DisabledButton type={"rounded"} text={"Days not valid"} />
        ) : +data.amount > +balance.parsed ? (
          <DisabledButton type={"rounded"} text={"Amount exceed Balance!"} />
        ) : +data.amount > +balance.allowanceValue ? (
          <button onClick={executeApprove} className="interaction-button">
            Approve
          </button>
        ) : (
          <button onClick={execute} className="interaction-button">
            Unlock{" "}
            {data.days == 0 || data.days < 15 || data.days > 90
              ? "0"
              : Math.round((data.amount - (data.amount * (100 - ratio)) / 100) * 100) / 100}{" "}
            FORT in {data.days} days
          </button>
        )}
      </div>
    </div>
  )
}

/* 

{/* <div className="w-full flex gap-6 flex-col px-2">
        <div className="w-full flex justify-between">
          <p>Ratio</p>
          <p>
            {ratio === 0 || data.days == 0 || data.days < 15 || data.days > 90
              ? "0.5:1"
              : (ratio / 100).toFixed(2)}
          </p>
        </div>
        <div className="w-full flex justify-between">
          <p>Penality</p>
          <p>
            {ratio === 0 || data.days == 0 || data.days < 15 || data.days > 90
              ? "100"
              : Math.round((100 - ratio) * 100) / 100}
            %
          </p>
        </div>
        <div className="w-full flex justify-between">
          <p>Redeem</p>
          <p>
            <span className="text-primary-color">
              +
              {data.days == 0 || data.days < 15 || data.days > 90
                ? "0"
                : Math.round((data.amount - (data.amount * (100 - ratio)) / 100) * 100) / 100}
            </span>{" "}
            SWORD
          </p>
        </div>
      </div> 
      
      */
