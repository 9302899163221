import { FORT_ADDRESS, USDBC_ADDRESS, USDC_ADDRESS, WETH_ADDRESS } from "./constants"
import { providerReadOnly } from "./state/state"
import { ethers } from "ethers"

export const hexToInteger = (hexValue) => {
  return parseInt(hexValue, 16)
}

export const addressSlice = (address) => {
  return address.slice(0, 6) + "..." + address.slice(38, 42)
}

export const parseWeiToNumber = (value) => {
  return ethers.formatEther(value + "")
}

export const parseStringToNumberDecimal = (value, decimal = 2) => {
  return Number(+value + "").toFixed(decimal)
}

export const parseNumberBase18 = (value, decimal = 2) => {
  return Number(+value / Math.pow(10, 18) + "").toFixed(decimal)
}

export const parseNumberPerBase18 = (value, decimal = 2) => {
  return parseFloat(+value * Math.pow(10, 18) + "").toFixed(decimal)
}

export const parseNumberToWeiETH = (value) => {
  return ethers.parseEther(value) + ""
}

export const getParsedBalance = async (address) => {
  const value = await providerReadOnly.getBalance(address)
  return parseWeiToNumber(value + "", 5)
}

export const buildPath = (config) => {
  const path = []

  path.push(config.tokenA === "ETH" ? WETH_ADDRESS : config.tokenA)
  path.push(config.tokenB === "ETH" ? WETH_ADDRESS : config.tokenB)

  if (path[0] === path[1]) return undefined

  //route per usdc / sword
  if (path[0] === FORT_ADDRESS && path[1] === USDC_ADDRESS) {
    return [path[0], WETH_ADDRESS, path[1]]
  }

  if (path[0] === USDC_ADDRESS && path[1] === FORT_ADDRESS) {
    return [path[0], WETH_ADDRESS, path[1]]
  }

  if (path[0] === FORT_ADDRESS && path[1] === USDBC_ADDRESS) {
    return [path[0], WETH_ADDRESS, USDC_ADDRESS, path[1]]
  }

  if (path[0] === USDBC_ADDRESS && path[1] === FORT_ADDRESS) {
    return [path[0], USDC_ADDRESS, WETH_ADDRESS, path[1]]
  }

  if (path[0] === USDBC_ADDRESS && path[1] === WETH_ADDRESS) {
    return [path[0], USDC_ADDRESS, path[1]]
  }

  if (path[0] === WETH_ADDRESS && path[1] === USDBC_ADDRESS) {
    return [path[0], USDC_ADDRESS, path[1]]
  }

  return path
}

export const abbreviateNumber = (number) => {
  let abbreviation = ""

  if (number >= 1000000) {
    number = (number / 1000000).toString()
    if (number.includes(".")) {
      const decimalIndex = number.indexOf(".")
      number = number.slice(0, decimalIndex + 3)
    }
    abbreviation = number + "M"
  } else {
    const decimalIndex = number.toString().indexOf(".")
    if (decimalIndex !== -1) {
      const decimals = number.toString().slice(decimalIndex + 1, decimalIndex + 3)
      abbreviation = number.toFixed(decimals.length)
    } else {
      abbreviation = number.toString()
    }
  }

  return abbreviation
}

export const convertToDecimalString = (numberString) => {
  if (numberString.includes("e")) {
    const number = parseFloat(numberString)
    return number.toFixed(0)
  }
  return numberString
}

export const removeDecimals = (number, decimals = 2) => {
  if (number == 0) {
    return 0
  }
  const multiplier = Math.pow(10, decimals)
  const roundedNumber = Math.floor(number * multiplier) / multiplier
  return roundedNumber
}

export const removeLeadingZero = (str) => {
  if (str.length > 1 && str.charAt(0) === "0" && str.charAt(1) !== "," && str.charAt(1) !== ".") {
    return str.substring(1)
  }
  return str
}

export const keepDecimalSeparatorAtPosition1 = (inputString) => {
  //eslint-disable-next-line no-useless-escape
  const modifiedString = inputString.replace(/[\.,]/g, (match, offset) => (offset === 1 ? match : ""))

  return modifiedString
}

export const displayDecimals = (num) => {
  let str = num.toString()
  let decimalIndex = str.indexOf(".")

  if (decimalIndex === -1) {
    decimalIndex = str.indexOf(",")
  }

  if (decimalIndex === -1) {
    return str
  }

  let decimalPart = str.slice(decimalIndex + 1)
  if (decimalPart.length <= 2) {
    return str
  }

  let nonZeroIndex = -1
  for (let i = 0; i < decimalPart.length; i++) {
    if (decimalPart[i] !== "0") {
      nonZeroIndex = i
      break
    }
  }

  if (nonZeroIndex === -1 || nonZeroIndex < 2) {
    return str.slice(0, decimalIndex + 3)
  }

  const data = str.slice(0, decimalIndex + nonZeroIndex + 3)

  return data
}

export const abbreviateMillions = (num, fixed = 2) => {
  if (num >= 1000000) {
    const millions = (num / 1000000).toFixed(fixed)
    return millions.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " M"
  } else {
    return num.toFixed(0)
  }
}

export const capitalize = (text) => {
  return text.slice(0, 1).toUpperCase() + text.slice(1)
}

export const formatNumberWithApostrophes = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
}

export const calculatePercentage = (numberA, numberB) => {
  // Check if numberA is not zero to avoid division by zero
  if (numberA !== 0) {
    // Calculate the percentage
    const percentage = (numberB / numberA) * 100
    return percentage
  } else {
    // Handle the case where numberA is zero
    return 0
  }
}

export const limitDecimalPlaces = (number = 0, decimalPlaces) => {
  if (!number) {
    return "0"
  }

  // Verifica se il numero è un numero e se il numero di cifre decimali è valido
  if (isNaN(decimalPlaces) || decimalPlaces < 0) {
    return "0"
  }

  // Trasforma il numero in una stringa
  const numberString = number.toString()

  // Verifica se il numero ha cifre decimali
  if (numberString.indexOf(".") !== -1) {
    const [integerPart, decimalPart] = numberString.split(".")

    // Limita il numero di cifre decimali
    const limitedDecimalPart = decimalPart.slice(0, decimalPlaces - 1)

    // Costruisci il numero con il numero limitato di cifre decimali
    const result = parseFloat(`${integerPart}.${limitedDecimalPart}`)

    return result
  }

  // Se il numero non ha cifre decimali, restituisci il numero originale
  return number
}

export function removeAfterDot(string, numCharsAfterDot) {
  var dotFound = false
  var charCount = 0
  var newString = ""

  for (var i = 0; i < string.length; i++) {
    if (string[i] === ".") {
      dotFound = true
    }
    if (dotFound) {
      charCount++
      if (charCount > numCharsAfterDot + 1) {
        break
      }
    }
    newString += string[i]
  }

  return newString
}

let timerId
export const debounce = (func, delay) => {
  return function (...args) {
    clearTimeout(timerId)
    timerId = setTimeout(() => func.apply(this, args), delay)
  }
}
