import { useWeb3React } from "@web3-react/core"
import { useEffect, useRef, useState } from "react"
import { AiOutlinePoweroff } from "react-icons/ai"

export const DisconnectButton = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900)
  const [isOpen, setIsOpen] = useState(false)
  const buttonRef = useRef()
  const { deactivate } = useWeb3React()

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 900
      setIsMobile(mobile)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    function handleClickOutside(event) {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const handleClick = () => {
    if (!isOpen) {
      setIsOpen(true)
      return
    }

    deactivate()
  }

  return (
    <button
      ref={buttonRef}
      className={`flex py-1 h-7 items-center bg-card-light-blue ${
        isOpen ? (isMobile ? "px-2 w-[145px]" : "w-[185px]") : "p-1 w-[28px]"
      } transition-all duration-500 rounded-custom relative overflow-hidden h-7`}
      onClick={handleClick}
    >
      <div className={`absolute ${isOpen ? "left-[7px]" : "left-[3px]"}`}>
        <AiOutlinePoweroff size={20} color="white" />
      </div>
      <div className="absolute left-5 top-[2px]">
        <span className="ml-2 text-white font-medium">Disconnect</span>
      </div>
    </button>
  )
}
