import { GoAlert } from "react-icons/go"

export const WrongChain = ({ handleSwitch }) => {
  return (
    <div className="flex flex-col text-center w-full justify-center items-center gap-4">
      <div className="w-full flex flex-col items-center">
        <GoAlert size={50} color="white" />
        <p className="font-bold text-white">Wrong Chain</p>
      </div>
      <button
        onClick={handleSwitch}
        className="p-2 w-full rounded-custom tex-lg font-bold text-white bg-primary-color"
      >
        SWITCH
      </button>
    </div>
  )
}
