import { InjectedConnector } from "@web3-react/injected-connector"
import { WalletLinkConnector } from "@web3-react/walletlink-connector"
//import { WalletConnectConnector } from "@web3-react/walletconnect-connector"
import { ConnectionButton } from "./connectionButton"
import CoinbaseLogo from "../images/wallet/coinbase.svg"
//import { RPC_URL } from "../../constants"
export const ConnectionMenu = () => {
  const connections = [
    {
      connector: new InjectedConnector({}),
      image: `https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/512px-MetaMask_Fox.svg.png?20220831120339`,
      name: "MetaMask",
    },
    {
      connector: new WalletLinkConnector({
        url: `wss://base-goerli.publicnode.com`,
        appName: "Citadel",
      }),
      image: CoinbaseLogo,
      name: "Coinbase Wallet",
    },
  ]
  return (
    <div className="">
      <p className="text-white text-start font-bold">Connect Wallet</p>
      <div className="pt-6">
        {connections.map((connection, index) => (
          <ConnectionButton
            key={index}
            connector={connection.connector}
            img={connection.image}
            name={connection.name}
          />
        ))}
      </div>
    </div>
  )
}
