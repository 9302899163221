/* eslint-disable */
import { Outlet } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./style.css"
import { useEffect, useState } from "react"
import { Nav } from "../navbar/navbar"
import { Sidebar } from "../sidebar/sidebar"
import { ConnectionSidebar } from "../connection/connectionsSidebar"
import { getTotalTvl } from "../../API/dexscreener/dexscreener"
import { useWeb3React } from "@web3-react/core"

export const Layout = () => {
  const { account } = useWeb3React()
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900)
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile)
  const [connectionSidebarOpen, setConnectionSidebarOpen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 900
      setIsMobile(mobile)

      setSidebarOpen(!mobile)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    getTotalTvl()

    let timer = setInterval(() => {
      getTotalTvl()
    }, 20000)

    return () => {
      clearInterval(timer)
    }
  })

  return (
    <>
      <div className="h-screen w-full bg-background-color font-aeonik">
        <div className="flex flex-row h-full">
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} isMobile={isMobile} />
          <div className="flex flex-col h-full w-full">
            <Nav
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              connectionSidebarOpen={connectionSidebarOpen}
              setConnectionSidebarOpen={setConnectionSidebarOpen}
            />
            <div className="h-full w-full max-h-full overflow-auto bg-slate-750">
              <Outlet />
            </div>
          </div>
          <ConnectionSidebar
            connectionSidebarOpen={connectionSidebarOpen}
            setConnectionSidebarOpen={setConnectionSidebarOpen}
            isMobile={isMobile}
          />
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    </>
  )
}
