import {
  DAI_PLUS_ADDRESS,
  FORT_ADDRESS,
  USDBC_ADDRESS,
  USDC_ADDRESS,
  USD_PLUS_ADDRESS,
  esFORT_ADDRESS,
} from "../../constants"
import { TokenItem } from "./tokenItem"

export const CurrentInfo = ({ connectionSidebarOpen }) => {
  const tokens = [
    "ETH",
    FORT_ADDRESS,
    esFORT_ADDRESS,
    USDC_ADDRESS,
    USDBC_ADDRESS,
    USD_PLUS_ADDRESS,
    DAI_PLUS_ADDRESS,
  ]

  return (
    <div className="flex flex-col text-white gap-2 w-full">
      <p className="font-bold">Token</p>
      <div className="flex flex-col gap-4 w-full">
        {tokens.map((token, index) => (
          <TokenItem key={index} address={token} connectionSidebarOpen={connectionSidebarOpen} />
        ))}
      </div>
    </div>
  )
}
