// export const FORT_ADDRESS = "0xB1bD114648ac22d21d7F2BFf63D629C1Bb666D09" //TESTNET
// export const esFORT_ADDRESS = "0x84F1702A894515EA741cF80ECFf129b936867dF6" //TESTNET
// export const FACTORY_ADDRESS = "0x9C645fF5A9Dc885d57c19be6342d6016B1c973Eb" //TESTNET
// export const FARMING_ADDRESS = "0x376A3D6BED357C0C58aEdda3a6ee4d1EF207A84b" //TESTNET
// export const ROUTER_ADDRESS = "0xB0217035E441db951ec99193fc4224f52547dA0c" //TESTNET
// export const WETH_ADDRESS = "0x4200000000000000000000000000000000000006" //TESTNET
// export const RPC_URL = "https://base-goerli.publicnode.com" //TESTNET
// export const PUBLIC_ICO_ADDRESS = "0x151F37158345d928A99CdaA8f06bE4EF2CB77E7d" //TESTNET
// export const PRIVATE_ICO_ADDRESS = "0xD0bd8d22ee88261417762084d45a847d24580C69" //TESTNET
// export const PRIVATE_ICO_FORT = 22500000
// export const PRIVATE_ICO_ES_FORT = 7500000
// export const PRIVATE_ICO_TARGET = 0.1
// export const PRIVATE_ICO_MAX_COMMITMENT = 0.05
// export const FORT_STAKE = "0xe7d7dFceb5b75B6FA5898c2594C8Bd27660bf29d"
// export const DIVIDENDS_ADDRESS = "0xC7cF3f2129571e6483250fa9AFFBC829F2205A11"
// export const CHAIN = 84531
// export const CHAIN_OBJ = {
//   chainId: "0x14a33",
//   rpcUrls: ["https://base-goerli.publicnode.com"],
//   chainName: "Base Goerli",
//   nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
//   blockExplorerUrls: ["https://goerli.basescan.org/"],
// }
// export const EPOCH_DURATION = 1800000

export const CHAIN = 8453
export const CHAIN_OBJ = {
  chainId: "0x2105",
  rpcUrls: ["	https://mainnet.base.org"],
  chainName: "Base Mainnet",
  nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
  blockExplorerUrls: ["https://basescan.org/"],
}

export const ETH_FORT_ADDRESS = "0x7ae7434Fe356Ca9e60E973Bd59974078fe8949bc" //MAINNET
export const ETH_USDC_ADDRESS = "0x3fe49194d3cf9a7005203468073a32d764fb86b6" //MAINNET
export const USDBC_ADDRESS = "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA"
export const USD_PLUS_ADDRESS = "0xB79DD08EA68A908A97220C76d19A6aA9cBDE4376"
export const DAI_PLUS_ADDRESS = "0x65a2508C429a6078a7BC2f7dF81aB575BD9D9275"
export const USDC_ADDRESS = "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913" //MAINNET - AGGIORNATO
export const WETH_ADDRESS = "0x4200000000000000000000000000000000000006" //MAINNET

export const FORT_ADDRESS = "0x7233062d88133B5402D39D62BFa23a1b6c8d0898" //MAINNET - AGGIORNATO
export const esFORT_ADDRESS = "0xB1bD114648ac22d21d7F2BFf63D629C1Bb666D09" //MAINNET - AGGIORNATO
export const ROUTER_ADDRESS = "0x112f3f5CE89a46B9d7e18131e8E7CDdE313e4234" //MAINNET - AGGIORNATO
export const FACTORY_ADDRESS = "0xbe720274c24b5Ec773559b8C7e28c2503DaC7645" //MAINNET - AGGIORNATO
export const FARMING_ADDRESS = "0x75776C547d312Eba17C9461Fd5C10bA6c2F85237" //MAINNET - AGGIORNATO
export const PUBLIC_ICO_ADDRESS = "0x724CBCB2d8a67071EB73bF54702704cB059bda43" //MAINNET - AGGIORNATO
export const PRIVATE_ICO_ADDRESS = "0xBc72C4C73EC28eE97Bc516247B08a7B1A37352d0" //MAINNET - AGGIORNATO
export const PRIVATE_ICO_FORT = 2250000
export const PRIVATE_ICO_ES_FORT = 750000
export const PRIVATE_ICO_TARGET = 60
export const PRIVATE_ICO_MAX_COMMITMENT = 3
export const FORT_STAKE = "0x84F1702A894515EA741cF80ECFf129b936867dF6" //MAINNET - AGGIORNATO
export const DIVIDENDS_ADDRESS = "0xadd58e519ECD3E9Fa6ffBdFfbD0A3d3252861e53" //MAINNET - AGGIORNATO
export const RPC_URL = "https://base-mainnet.g.alchemy.com/v2/7MTLCjxCdSnVrtIs3YMKUR1nBKlqmwxd" //MAINNET
export const EPOCH_DURATION = 604800000
