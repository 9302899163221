import { Contract } from "@ethersproject/contracts"
import PRIVATE_SALE_ABI from "../ABI/PRIVATE_SALE_ABI.json"
import { providerReadOnly } from "../state/state"
import { PRIVATE_ICO_ADDRESS } from "../constants"
import { parseNumberToWeiETH, parseWeiToNumber } from "../utils"

export const invest = async (address, amount, provider) => {
  if (address !== PRIVATE_ICO_ADDRESS) return

  const contract = connectContract(provider.getSigner())

  const parsedValue = parseNumberToWeiETH(amount + "")

  return contract.invest({ value: parsedValue })
}

export const claim = async (address, provider) => {
  if (address !== PRIVATE_ICO_ADDRESS) return

  const contract = connectContract(provider.getSigner())

  return contract.claimTokens()
}

export const invested = async (owner) => {
  const contract = connectContract()

  let invested = await contract.investments(owner)

  invested = parseWeiToNumber(invested)

  return invested
}

export const whiteListed = async (owner) => {
  const contract = connectContract()

  return await contract.whitelisted(owner)
}

export const ethersToRaise = async (address) => {
  if (address !== PRIVATE_ICO_ADDRESS) return 0
  const contract = connectContract()

  const total = await contract.totalCommitments()

  return parseWeiToNumber(total)
}

export const commitments = async (address) => {
  const contract = connectContract()

  const total = await contract.investments(address)

  return parseWeiToNumber(total)
}

const connectContract = (provider = providerReadOnly) => {
  return new Contract(PRIVATE_ICO_ADDRESS, PRIVATE_SALE_ABI, provider)
}
