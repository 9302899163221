import { Navbar } from "@material-tailwind/react"

import { SocialIcon } from "../launchpad/socialIcon"
import { WalletConnection } from "./walletConnection"

export const Nav = ({ sidebarOpen, setSidebarOpen, connectionSidebarOpen, setConnectionSidebarOpen }) => {
  return (
    <Navbar className="p-0 rounded-none bg-dark-blue border-none max-sm:pt-3">
      <div className=" px-4 max-lg:mx-2 mx-auto h-16 flex flex-row items-center justify-between  text-white">
        <SocialIcon size={25} />
        <button
          onClick={() => setSidebarOpen(true)}
          className={`${sidebarOpen ? "opacity-0" : "opacity-100"} transition-opacity duration-500`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
        <WalletConnection
          connectionSidebarOpen={connectionSidebarOpen}
          setConnectionSidebarOpen={setConnectionSidebarOpen}
        />
      </div>
    </Navbar>
  )
}
