import { Link } from "react-router-dom"

export const ReceiptToast = ({ txHash }) => {
  return (
    <Link target="_blank" to={`https://basescan.org/tx/${txHash}`}>
      <div>
        <span>Transaction sent, click on this popup to open the explorer. </span>
        link
      </div>
    </Link>
  )
}
