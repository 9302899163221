import { FORT_ADDRESS } from "../../constants"

export const farmingInfo = [
  {
    index: 0,
    pair: "0x3fe49194d3cf9a7005203468073a32d764fb86b6",
    token0: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    symbol0: "USDC",
    decimals0: 6,
    token1: "0x4200000000000000000000000000000000000006",
    symbol1: "ETH",
    decimals1: 18,
    isStable: false,
    percentageRewards: 0.3256,
    active: true,
  },
  {
    index: 1,
    pair: "0x9dCFF9C872BBF746633ce6d1c7a511a92bf75d39",
    token0: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    symbol0: "USDC",
    decimals0: 6,
    token1: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
    symbol1: "USDbC",
    decimals1: 6,
    isStable: true,
    percentageRewards: 0.1395,
    active: true,
  },
  {
    index: 2,
    pair: "0x7ae7434Fe356Ca9e60E973Bd59974078fe8949bc",
    token0: "0x4200000000000000000000000000000000000006",
    symbol0: "ETH",
    decimals0: 18,
    token1: FORT_ADDRESS,
    symbol1: "FORT",
    decimals1: 18,
    isStable: false,
    percentageRewards: 0.4651,
    active: true,
  },
  {
    index: 3,
    pair: "0x3F50De34Cf2E72d173a018A18eDF935bC03D43c7",
    token1: "0xB79DD08EA68A908A97220C76d19A6aA9cBDE4376",
    symbol1: "USD+",
    decimals1: 6,
    token0: "0x65a2508C429a6078a7BC2f7dF81aB575BD9D9275",
    symbol0: "DAI+",
    decimals0: 18,
    isStable: true,
    percentageRewards: 0.0698,
    active: true,
  },
]
