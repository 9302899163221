import { useEffect, useState } from "react"
import { Sale } from "./interactionCard/sale"
import { Claim } from "./interactionCard/claim"

//eslint-disable-next-line
export const LaunchInteraction = ({ icoAddress, info, data, getData, isOnWhite, raised, investments }) => {
  const [isAllClaimable, setIsAllClaimable] = useState(false)
  const [isETHClaimable, setIsETHClaimable] = useState(false)

  const { end, claimETH } = info

  useEffect(() => {
    if (end <= Date.now()) {
      setIsAllClaimable(true)
    }

    if (claimETH <= Date.now()) {
      setIsETHClaimable(true)
    }

    const interval = setInterval(() => {
      if (end <= Date.now()) {
        setIsAllClaimable(true)
      }

      if (claimETH <= Date.now()) {
        setIsETHClaimable(true)
      }

      if (isAllClaimable && isETHClaimable) {
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [info.start])

  return (
    <>
      {isAllClaimable || isETHClaimable ? (
        <Claim
          icoAddress={icoAddress}
          info={info}
          isOnWhite={isOnWhite}
          data={data}
          getData={getData}
          isAllClaimable={isAllClaimable}
          isETHClaimable={isETHClaimable}
          disabled={isOnWhite}
        />
      ) : (
        <Sale
          icoAddress={icoAddress}
          info={info}
          getData={getData}
          isOnWhite={isOnWhite}
          raised={raised}
          investments={investments}
        />
      )}
    </>
  )
}
