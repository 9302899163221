import { ethers } from "ethers"
import { deposit } from "../../../API/farming"
import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { DisabledButton } from "../../utils/disabledButton"
import { pairApprove } from "../../../API/pair"
import { FARMING_ADDRESS } from "../../../constants"
import { stakeUpdate } from "../../../state/state"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../toast/receiptToast"
import { displayDecimals } from "../../../utils"
import { LoadingButton } from "../../utils/loadingButton"

export const StakeComponent = ({ index, poolData, userData }) => {
  const { account, library, active } = useWeb3React()
  const [haveToken, setHaveToken] = useState(true)
  const [inputValue, setInputValue] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [userData])

  const checkBalance = (value) => {
    setHaveToken(+userData.lpBalance >= +value)
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setInputValue(value)
    checkBalance(value)
  }

  const setMax = () => {
    if (!active) return
    setInputValue(userData.lpBalance)
  }

  const executeApprove = async () => {
    setLoading(true)
    pairApprove(FARMING_ADDRESS, inputValue, poolData.pair, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          setLoading(false)
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              stakeUpdate.set({ index, date: Date.now() })
            } else {
              setLoading(false)
              toast("Transaction Failed!")
            }
          })
          .catch((e) => {
            console.log(e)
            setLoading(false)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => {
        setLoading(false)
        console.error(e)
      })
  }

  const executeDeposit = async () => {
    setLoading(true)
    deposit(index, ethers.parseEther(inputValue) + "", account, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          setLoading(false)
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              stakeUpdate.set({ index, date: Date.now() })
              handleInputChange({ target: { value: "" } })
            } else toast("Transaction Failed!")
            setLoading(false)
          })
          .catch((e) => {
            console.log(e)
            setLoading(false)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => {
        setLoading(false)
        console.error(e)
      })
  }

  return (
    <div className="container text-gray-500 font-semibold">
      <div className="content">
        <div className="flex flex-col w-full text-center px-4 pt-4 pb-12">
          <p className="text-primary-text">
            First, you neet to deposit your tokens into the liquidity pools.
          </p>
          <p>Then, use the returned LP Tokens and stake them into the respective farm on this page</p>
        </div>
        <div className="w-full flex justify-center">
          <button onClick={setMax} className="">
            Available: {displayDecimals(userData.lpBalance)}
          </button>
        </div>
        <div className="px-4 pt-1">
          <div className="w-full h-[50px] border-gray-500 rounded-custom border px-4 flex gap-4 justify-between items-center text-primary-text">
            <p>
              {poolData.symbol0}-{poolData.symbol1} LP
            </p>
            <input
              onChange={handleInputChange}
              className="w-[70%] bg-transparent text-end"
              placeholder="0"
              value={inputValue}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center px-4 footer pt-10 text-primary-text">
        {loading ? (
          <LoadingButton />
        ) : !active ? (
          <DisabledButton text={"Connect Wallet First!"} />
        ) : !inputValue || inputValue == 0 ? (
          <DisabledButton text={"Insert amount First!"} />
        ) : !haveToken ? (
          <DisabledButton text={"Insufficient amount!"} />
        ) : +inputValue > +userData.allowance ? (
          <button onClick={executeApprove} className="interaction-button">
            Approve
          </button>
        ) : (
          <button onClick={executeDeposit} className="interaction-button">
            Stake
          </button>
        )}
      </div>
    </div>
  )
}
