import { Link } from "react-router-dom"
import { harvestHavenLaunch, privateLaunch, publicLaunch } from "./launchInfo"
import { PadDetail } from "./padDetails"
import { FaExternalLinkAlt } from "react-icons/fa"
import bannerHarvest from "../../images/collab/harverstHavenBanner.jpg"

export const Launchpad = () => {
  return (
    <>
      <div className="flex flex-col items-center gap-6 pt-10 w-full h-full">
        <div className="text-center text-white ">
          <h1 className="text-[46px] font-semibold">Launchpad</h1>
        </div>
        <div className="flex w-full justify-center items-center xl:flex-row flex-col md:gap-4 max-md:items-center max-md:pb-3">
          {/* <PadDetail to={"/private-launch"} name={"PRIVATE"} info={_privateLaunch} /> */}
          <PadDetail to={"/private-launch"} name={"PUBLIC"} info={privateLaunch} />
          <PadDetail to={"/public-launch"} name={"PUBLIC"} info={publicLaunch} />
          <PadDetail to={"/public-launch"} name={"TEST"} info={harvestHavenLaunch} banner={bannerHarvest} />
        </div>
        <div className="w-full text-center mt-8">
          <Link
            className="text-2xl transition-all duration-300 hover:text-primary-color text-primary-text flex justify-center"
            target="_blank"
            to="https://www.citadelswap.xyz/form"
          >
            <div className="flex items-center gap-2">
              <FaExternalLinkAlt />
              Apply to Launch
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}
