import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react"
import "./sidebar.css"
import { TbBuildingBridge2 } from "react-icons/tb"
import { useState } from "react"
import { SidebarElement } from "./sidebarElement"
import { ReactComponent as rhino } from "../images/rhino.svg"
import { ReactComponent as squid } from "../images/squid.svg"
import interport from "../images/interport.png"

export const SidebarBridge = ({ index, setIsHovered, isHovered, size = 20 }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpenening = () => {
    setIsOpen(!isOpen)
  }

  const items = [
    {
      name: "Rhino",
      path: "https://app.rhino.fi/bridge",
      icon: rhino,
    },
    {
      name: "Squid",
      path: "https://app.squidrouter.com/",
      icon: squid,
    },
    {
      name: "Interport",
      path: "https://app.interport.fi/bridge/",
      img: interport,
    },
  ]

  const Icon = ({ open }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className={`${open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg>
    )
  }

  return (
    <Accordion
      className="border-b-0 p-0 font-semibold"
      open={isOpen}
      icon={<Icon open={isOpen} />}
      onMouseEnter={() => setIsHovered(index)}
      onMouseLeave={() => setIsHovered(-1)}
    >
      <AccordionHeader
        className="flex flex-row w-full justify-start items-center gap-4 rounded-xl border-none text-base font-semibold py-3 px-6 hover:bg-card-light-blue hover:text-primary-color"
        onClick={handleOpenening}
      >
        <TbBuildingBridge2 size={size} color="white" className={`${isHovered === index ? "hovered" : ""}`} />
        <p>Bridge</p>
      </AccordionHeader>
      <AccordionBody className="flex flex-col gap-6">
        {items.map((item, index) => (
          <SidebarElement
            key={index}
            name={item.name}
            path={item.path}
            icon={item.icon}
            img={item.img}
            index={index + 10}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            target="_blank"
          />
        ))}
      </AccordionBody>
    </Accordion>
  )
}
