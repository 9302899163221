import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { ethers } from "ethers"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../toast/receiptToast"
import { xStakeUpdate } from "../../../state/state"
import { finalizeReedem } from "../../../API/swordStake"
//eslint-disable-next-line
export const Claimable = ({ index, reedem }) => {
  const { library } = useWeb3React()
  const [userReedems, setUserReedem] = useState({ endTime: 0, daysLeft: 0, rewards: 0 })

  const execute = async () => {
    finalizeReedem(index, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              xStakeUpdate.set(xStakeUpdate.get() + 1)
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  useEffect(() => {
    if (!library) return

    const endTime = new Date(reedem[2] * 1000)
    const timeDiff = endTime - Date.now()
    let daysLeft = +Number(timeDiff / (1000 * 60 * 60 * 24)).toFixed(2)
    daysLeft = daysLeft < 0 ? "0.00" : daysLeft
    const rewards = ethers.formatEther(reedem[1] + "")
    setUserReedem({ endTime, daysLeft, rewards })
  }, [])

  return (
    <div className="grid grid-cols-3 gap-4 text-white  h-[50px] pt-2">
      <div className="flex justify-center items-center">
        <p className="">{userReedems.daysLeft} Days</p>
      </div>
      <div className="flex justify-center items-center">
        <p className="">{Math.floor(userReedems.rewards * 100) / 100} FORT</p>
      </div>
      <div className="flex justify-center ">
        {userReedems.daysLeft == 0 ? (
          <button
            onClick={execute}
            className="border-primary-color rounded-custom border w-10/12 h-full self-center"
          >
            Claim
          </button>
        ) : (
          <button disabled className="bg-gray-600 text-gray-400 rounded-custom w-10/12 h-full self-center">
            Claim
          </button>
        )}
      </div>
    </div>
  )
}
