import { useWeb3React } from "@web3-react/core"
import { NoPositionFound } from "./farmingInteraction/noPositionFound"
import { ManageFarm } from "./farmingInteraction/manageFarm"
import { ConnectWallet } from "./farmingInteraction/connectWallet"
import { abbreviateNumber } from "../../utils"
import { Spinner } from "../spinner/Spinner"
import { Link } from "react-router-dom"
import { FARMING_ADDRESS } from "../../constants"
import { InteractionCard } from "./farmingInteraction/interactionCard"

export const FarminItemBody = ({ poolInfo, userData, poolData, rewards, tvl, loadingUserInfo }) => {
  const { active } = useWeb3React()

  return (
    <div className="flex md:flex-row flex-col-reverse w-full h-full lg:gap-10">
      <div className="max-lg:hidden md:min-h-[200px] text-primary-text text-left min-w-fit md:pr-4 pl-6 flex flex-col justify-center font-semibold">
        <Link to={"/liquidity"}>
          Add {poolInfo.symbol0}-{poolInfo.symbol1} LP
        </Link>
      </div>
      <div className="lg:hidden flex md:flex-col flex-row-reverse min-w-[30%] min-h-full max-md:min-w-full">
        <div className="min-h-[50%] max-md:w-[50%] flex md:flex-col flex-row items-center justify-around text-primary-text font-semibold md:pl-6 py-6">
          <div className="flex justify-start max-md:justify-start max-md:items-start md:w-full">
            <div className="flex flex-col justify-between text-left text-sm gap-2">
              <p>My Share</p>
              {loadingUserInfo ? (
                <Spinner size={20} />
              ) : (
                <p className="text-gray-500">{`$${
                  isNaN((userData.amountStaked * tvl) / poolData.lpBalance)
                    ? 0
                    : abbreviateNumber((userData.amountStaked * tvl) / poolData.lpBalance)
                } `}</p>
              )}
            </div>
          </div>
          {/* <div className="flex justify-start md:w-full">
            <div className="flex flex-col justify-between text-left text-sm gap-2">
              <p>Earned</p>
              {loadingUserInfo ? (
                <Spinner size={20} />
              ) : (
                <p className="text-gray-500">{`$${abbreviateNumber(rewards)} `}</p>
              )}
            </div>
          </div> */}
        </div>
        <div className=" md:h-[50%] max-md:min-h-full max-md:w-[50%] md:border-t max-md:border-r border-transparent   text-primary-text text-left min-w-fit md:pr-4 pl-6 flex flex-col justify-center font-semibold">
          <div className="py-6">
            <Link to={"/liquidity"}>
              Add {poolInfo.symbol0}-{poolInfo.symbol1} LP
            </Link>
          </div>
        </div>
      </div>
      {FARMING_ADDRESS === "" ? (
        <InteractionCard>
          <div className="flex flex-col w-full h-full justify-center items-center text-primary-text font-semibold min-h-[200px]">
            <p className="text-2xl">Liquidity mining not active yet</p>
          </div>
        </InteractionCard>
      ) : !active ? (
        <ConnectWallet />
      ) : +userData.lpBalance === 0 && +userData.amountStaked === 0 ? (
        <NoPositionFound />
      ) : (
        <ManageFarm userData={userData} poolData={poolData} index={poolInfo.index} rewards={rewards} />
      )}
    </div>
  )
}
