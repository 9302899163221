import { useEffect, useState } from "react"
import { listAllPairs2 } from "../../API/factory"
import {
  addLiquidityApproval,
  addLiquidityState,
  providerReadOnly,
  removeLiquidityUpdate,
} from "../../state/state"
import { useWeb3React } from "@web3-react/core"
import { LiquidityCard } from "./LiquidityCard"
import { PairManaging } from "./pairManaging"
import { useStore } from "@nanostores/react"
import { getReserve, getTotalSupply, pairBalanceOf } from "../../API/pair"
import { MyLiquidity } from "./myLiquidity"
import { allowance2, balanceOf, parseWeiToValue } from "../../API/erc20"
import { ROUTER_ADDRESS, WETH_ADDRESS } from "../../constants"

export const Liquidity = () => {
  const { active, library, account } = useWeb3React()
  const liquidityUpdate = useStore(addLiquidityState)
  const pairUpdateListner = useStore(removeLiquidityUpdate)
  const [allLP, setAllLP] = useState([])
  const [loading, setLoading] = useState(false)
  const [pair, setPair] = useState()
  const [tokenSelection, setTokenSelection] = useState({
    tokenA: null,
    symbolA: null,
    tokenB: null,
    symbolB: null,
  })
  const [manualSelection, setManualSelection] = useState(false)

  const getMyLiquidity = async () => {
    setLoading(true)
    listAllPairs2(account, providerReadOnly)
      .then(async (meLPs) => {
        setAllLP(meLPs.filter((lp) => +lp.pairBalance > 0))
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (!library) return
    getMyLiquidity()
  }, [library, account, liquidityUpdate, pairUpdateListner])

  const pairUpdate = async () => {
    if (!pair) return
    let pairBalance = "0.0"
    const reserves = await getReserve(pair.pairAddress)
    const reserve0 = await parseWeiToValue(reserves[0] + "", tokenSelection.tokenA)
    const reserve1 = await parseWeiToValue(reserves[1] + "", tokenSelection.tokenB)
    const totalSupply = await getTotalSupply(pair.pairAddress)

    if (active) {
      pairBalance = await pairBalanceOf(account, pair.pairAddress)
    }

    setPair({ ...pair, reserve0, reserve1, pairBalance, totalSupply })
  }

  useEffect(() => {
    if (pairUpdateListner === 0) return
    pairUpdate()
  }, [liquidityUpdate, pairUpdateListner])

  const approveUpdate = useStore(addLiquidityApproval)
  const [isLoadindBalance, setIsLoadingBalance] = useState(false)
  const [userData, setUserData] = useState({
    parsedBalanceTokenA: 0,
    parsedBalanceTokenB: 0,
    balanceTokenA: null,
    balanceTokenB: null,
  })

  const getUserData = async () => {
    setIsLoadingBalance(true)
    const [balanceTokenAResponse, balanceTokenBResponse, allowanceTokenAResponse, allowanceTokenBResponse] =
      await Promise.all([
        tokenSelection.tokenA === "ETH" || tokenSelection.tokenA === WETH_ADDRESS
          ? library.getBalance(account)
          : balanceOf(account, tokenSelection.tokenA),
        tokenSelection.tokenB === "ETH" || tokenSelection.tokenB === WETH_ADDRESS
          ? library.getBalance(account)
          : balanceOf(account, tokenSelection.tokenB),
        tokenSelection.tokenA === "ETH" || tokenSelection.tokenA === WETH_ADDRESS
          ? library.getBalance(account)
          : allowance2(account, ROUTER_ADDRESS, tokenSelection.tokenA),
        tokenSelection.tokenB === "ETH" || tokenSelection.tokenB === WETH_ADDRESS
          ? library.getBalance(account)
          : allowance2(account, ROUTER_ADDRESS, tokenSelection.tokenB),
      ])

    const parsedBalanceTokenA = await parseWeiToValue(balanceTokenAResponse, tokenSelection.tokenA)
    const parsedBalanceTokenB = await parseWeiToValue(balanceTokenBResponse, tokenSelection.tokenB)
    const parsedAllowanceTokenA = await parseWeiToValue(allowanceTokenAResponse, tokenSelection.tokenA)
    const parsedAllowanceTokenB = await parseWeiToValue(allowanceTokenBResponse, tokenSelection.tokenB)

    setUserData({
      parsedBalanceTokenA,
      parsedBalanceTokenB,
      balanceTokenA: balanceTokenAResponse,
      balanceTokenB: balanceTokenBResponse,
      parsedAllowanceTokenA,
      parsedAllowanceTokenB,
    })
    setIsLoadingBalance(false)
  }

  useEffect(() => {
    if (!active || (!tokenSelection.tokenA && !tokenSelection.tokenB)) {
      setUserData({
        parsedBalanceTokenA: 0,
        parsedBalanceTokenB: 0,
        balanceTokenA: null,
        balanceTokenB: null,
      })
      return
    }

    getUserData()
  }, [active, account, tokenSelection, approveUpdate, liquidityUpdate, pairUpdateListner])

  return (
    <div className="h-full max-h-full min-h-full overflow-auto flex justify-center w-full">
      <div className="w-full min-h-fit h-fit max-h-fit flex justify-center items-center max-md:px-4 pt-16 pb-10">
        {!pair ? (
          manualSelection ? (
            <div className="card-custom-w w-full flex justify-center items-center border border-card-border rounded-custom overflow-hidden">
              <LiquidityCard
                pair={pair}
                setPair={setPair}
                tokenSelection={tokenSelection}
                setTokenSelection={setTokenSelection}
                setManualSelection={setManualSelection}
              />
            </div>
          ) : (
            <div className="card-custom-w w-full flex justify-center items-center border border-card-border rounded-custom overflow-hidden ">
              <MyLiquidity
                active={active}
                allLP={allLP}
                loading={loading}
                setPair={setPair}
                setTokenSelection={setTokenSelection}
                setManualSelection={setManualSelection}
                isLoadindBalance={isLoadindBalance}
                userData={userData}
              />
            </div>
          )
        ) : (
          <div className="w-full flex justify-center">
            <PairManaging
              setPair={setPair}
              pair={pair}
              setTokenSelection={setTokenSelection}
              tokenSelection={tokenSelection}
              isLoadindBalance={isLoadindBalance}
              userData={userData}
            />
          </div>
        )}
      </div>
    </div>
  )
}
