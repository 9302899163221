import { useWeb3React } from "@web3-react/core"
import { claim, claim2 as publicClaim } from "../../../API/ico"
import { claim as privateClaim } from "../../../API/privateSale"
import { DisabledButton } from "./disabledButton"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../toast/receiptToast"

//eslint-disable-next-line
export const Claim = ({ icoAddress, info, isOnWhite, data, getData, isAllClaimable, isETHClaimable }) => {
  const { active, library } = useWeb3React()

  const execute = () => {
    claim(icoAddress, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              getData()
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch(() => console.error("Transaction failed"))
  }

  const execute2 = () => {
    const selectedMethod = info.type === "private" ? privateClaim : publicClaim
    selectedMethod(icoAddress, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              getData()
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch(() => console.error("Transaction failed"))
  }

  return (
    <>
      {!active ? (
        <DisabledButton text={"Connect your wallet first!"} />
      ) : !isOnWhite ? (
        <DisabledButton text={"Not whitelisted"} />
      ) : !isETHClaimable ? (
        <DisabledButton text={"IDO not ended"} />
      ) : data.fort == 0 ? (
        <DisabledButton text={"Nothing to claim"} />
      ) : data.finals == 0 ? (
        <button onClick={() => execute()} className="w-full font-bold h-16 bg-primary-color rounded-custom">
          CLAIM
        </button>
      ) : !isAllClaimable ? (
        <DisabledButton text={"You can not claim yet"} />
      ) : (
        <button onClick={() => execute2()} className="w-full font-bold h-16 bg-primary-color rounded-custom">
          CLAIM
        </button>
      )}
    </>
  )
}
