import { useWeb3React } from "@web3-react/core"
import { addressSlice } from "../../utils"
import { useEffect, useRef } from "react"
import jazzicon from "@metamask/jazzicon"
import { ReactComponent as Logo } from "../images/base.svg"

export const WalletConnection = ({ setConnectionSidebarOpen, connectionSidebarOpen }) => {
  const { active, account } = useWeb3React()
  const avatarRef = useRef()

  useEffect(() => {
    const element = avatarRef.current
    if (element && account) {
      const addr = account.slice(2, 10)
      const seed = parseInt(addr, 16)
      const icon = jazzicon(40, seed) // genera un'icona di dimensione 20
      if (element.firstChild) {
        element.removeChild(element.firstChild)
      }
      element.appendChild(icon)
    }
  }, [account, avatarRef])
  return (
    <>
      {active ? (
        <button
          onClick={() => {
            setConnectionSidebarOpen(!connectionSidebarOpen)
          }}
          className="relative w-11 flex flex-row h-11"
        >
          <div className="absolute left-0" ref={avatarRef}></div>
          <div className="absolute left-7 bottom-0 border-2 rounded-full border-card-color p-[1px] bg-card-color">
            <Logo width={16} height={16} />
          </div>
        </button>
      ) : (
        <button
          className="card-light p-2 flex flex-row items-center text-primary-color font-bold"
          onClick={() => {
            setConnectionSidebarOpen(!connectionSidebarOpen)
          }}
        >
          {active ? addressSlice(account) : "Connect"}
        </button>
      )}
    </>
  )
}
