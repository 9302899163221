import { SiGitbook } from "react-icons/si"
import { RxDiscordLogo } from "react-icons/rx"
import { FiTwitter, FiGithub } from "react-icons/fi"
//import { GiSlashedShield } from "react-icons/gi"
import { Link } from "react-router-dom"
//import SvgOrange from "../icons/Orange"
//eslint-disable-next-line
export const SocialIcon = ({ size, gap = "5", mobileGap = "5" }) => {
  return (
    <div className={`flex flex-row gap-${gap} max-md:gap-2`}>
      <Link to={"https://github.com/citadelswap"} target="_blank">
        <FiGithub className="App-logo" size={size} color="white" />
      </Link>
      <Link to={"https://twitter.com/citadelswap"} target="_blank">
        <FiTwitter size={size} color="white" />
      </Link>
      <Link to={"https://discord.gg/citadelswap"} target="_blank">
        <RxDiscordLogo size={size} color="white" />
      </Link>
      <Link to={"https://docs.citadelswap.xyz/"} target="_blank">
        <SiGitbook size={size} color="white" />
      </Link>
      {/* <Link
        to={"https://github.com/solidproof/projects/tree/main/2023/citadel"}
        className={`w-[${size}px] hidden`}
        target="_blank"
      >
        <GiSlashedShield size={size} color="white" />
      </Link> */}
    </div>
  )
}
