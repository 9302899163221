/* eslint-disable */
import { useWeb3React } from "@web3-react/core"
import { EsFortInfo } from "./esFortInfo"
import { useEffect, useState } from "react"
import { allowance as allowanceX, balanceOf as balanceOfX } from "../../API/xtoken"
import { allowance2, balanceOf } from "../../API/erc20"
import { usersAllocation } from "../../API/dividends"
import { ethers } from "ethers"
import { DIVIDENDS_ADDRESS, FORT_ADDRESS, FORT_STAKE } from "../../constants"
import { getUserRedeem, getUserRedeemLength } from "../../API/swordStake"
import { useStore } from "@nanostores/react"
import { updateInizializeReedem, xStakeUpdate } from "../../state/state"
import { Claim } from "./redeem/claim"
import { ConvertSword } from "./redeem/modal/convertSword"
import { InizializeReedem } from "./redeem/modal/inizializeRedeem"
import banner from "../images/banner/esfort.png"

export const TestEsFort = () => {
  const { account, active } = useWeb3React()

  const update = useStore(xStakeUpdate)
  const updateBal = useStore(updateInizializeReedem)

  const [userReedems, setUserReedems] = useState([])
  const [xSwordBalance, setXSwordBalance] = useState({ parsed: 0 })
  const [loadingXToken, setLoadingXToken] = useState(false)
  const [swordBalance, setSwordBalance] = useState({ parsed: 0 })
  const [loadingToken, setLoadingToken] = useState(false)
  const [redeemingXsword, setRedeemingXsword] = useState(0)

  const [userDividendsInfo, setUserDividendsInfo] = useState({ allowance: 0, allocation: 0 })

  const loadTokenBalance = async () => {
    setLoadingToken(true)

    const valueBalance = await balanceOf(account, FORT_ADDRESS)
    const parsed = ethers.formatEther(valueBalance + "")

    let allowanceValue = await allowance2(account, FORT_STAKE, FORT_ADDRESS)
    allowanceValue = ethers.formatEther(allowanceValue + "")

    setSwordBalance({ parsed: parsed, bigNumber: valueBalance, allowanceValue: allowanceValue })
    setLoadingToken(false)
  }

  const loadXTokenBalance = async () => {
    setLoadingXToken(true)
    const valueXBalance = await balanceOfX(account)
    const parsedX = ethers.formatEther(valueXBalance + "")

    let allowanceXValue = await allowanceX(account, FORT_STAKE)
    allowanceXValue = ethers.formatEther(allowanceXValue + "")

    setXSwordBalance({ parsed: parsedX, bigNumber: valueXBalance, allowanceValue: allowanceXValue })
    setLoadingXToken(false)
  }

  const updateBalance = async () => {
    loadTokenBalance()
    loadXTokenBalance()
  }

  const getDividendsUserInfo = async () => {
    let dividendsAllowance = await allowanceX(account, DIVIDENDS_ADDRESS)
    dividendsAllowance = ethers.formatEther(dividendsAllowance + "")

    let userAllocation = await usersAllocation(account)
    userAllocation = ethers.formatEther(userAllocation + "")

    setUserDividendsInfo({ allowance: dividendsAllowance, allocation: userAllocation })
  }

  const getUserRedeemInfo = async () => {
    getUserRedeemLength(account).then(async (value) => {
      const reedems = []
      let allLocked = 0
      for (let i = 0; i < value; i++) {
        const reedem = await getUserRedeem(account, i)
        const valueLocked = ethers.formatEther(reedem[0] + "")
        allLocked += +valueLocked
        reedems.push(reedem)
      }
      setRedeemingXsword(allLocked)
      setUserReedems(reedems)
    })
  }

  useEffect(() => {
    if (!active) {
      setUserReedems([])
      setXSwordBalance({ parsed: 0, bigNumber: null })
      setSwordBalance({ parsed: 0, bigNumber: null })
      setRedeemingXsword(0)
      setUserDividendsInfo({ allowance: 0, allocation: 0 })
      return
    }

    if (active) {
      updateBalance()
      getDividendsUserInfo()
      getUserRedeemInfo()
    }
  }, [active, account, update])

  useEffect(() => {
    if (active) {
      updateBalance()
      getDividendsUserInfo()
    }
  }, [updateBal])

  return (
    <>
      <div className="w-full h-fit justify-center flex text-white py-6">
        <div className="flex flex-col items-center justify-center w-8/12 max-md:w-11/12 max-xx:w-10/12 bg-card-color rounded-custom p-6 gap-6 shadow-custom">
          <div className="w-full h-[204px] rounded-custom overflow-hidden bg-card-light-blue relative max-sm:hidden">
            <img src={banner} className="min-w-full min-h-full object-cover" />
          </div>
          <div
            id="img-launch"
            className="w-full h-32 bg-red rounded-custom bg-card-light-blue overflow-hidden relative sm:hidden"
          >
            <img
              src={banner}
              className="min-w-full min-h-full absolute top-50 left-50 transform -translate-x-50% -translate-y-50% object-cover"
            />
          </div>
          <div className="flex flex-row max-lg:flex-col gap-[10px] w-full">
            <ConvertSword swordBalance={swordBalance} balanceLoading={loadingToken} />
            <InizializeReedem
              balance={xSwordBalance}
              setBalance={setXSwordBalance}
              balanceLoading={loadingXToken}
            />
          </div>

          <Claim userReedems={userReedems} />
        </div>
      </div>
    </>
  )
}
