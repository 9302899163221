import { ConnectionMenu } from "./connectionMenu"
import { useWeb3React } from "@web3-react/core"
import { ConnectionInfo } from "./newConnectionInfo"
import { SlArrowDown } from "react-icons/sl"

export const ConnectionSidebarContent = ({ setConnectionSidebarOpen, isMobile, connectionSidebarOpen }) => {
  const { active } = useWeb3React()
  return (
    <div className="flex flex-col">
      <button
        className={`${isMobile ? "w-full flex justify-center pb-2" : "hidden"}`}
        onClick={() => setConnectionSidebarOpen(false)}
      >
        <SlArrowDown size={30} color="white" />
      </button>
      <div>
        {!active ? (
          <ConnectionMenu />
        ) : (
          <ConnectionInfo
            connectionSidebarOpen={connectionSidebarOpen}
            setConnectionSidebarOpen={setConnectionSidebarOpen}
          />
        )}
      </div>
    </div>
  )
}
