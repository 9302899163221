import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { pairAllowance, pairApprove } from "../../API/pair"
import { useWeb3React } from "@web3-react/core"
import { ROUTER_ADDRESS, WETH_ADDRESS } from "../../constants"
import { ethers } from "ethers"
import { DisabledButton } from "../utils/disabledButton"
import { addLiquidityApproval, removeLiquidityUpdate } from "../../state/state"
import { ReceiptToast } from "../toast/receiptToast"
import { useStore } from "@nanostores/react"
import { removeLiquidity, removeLiquidityETH } from "../../API/router"
import { LpTokensInfo } from "./lpTokensInfo"
import { BsArrowDownShort } from "react-icons/bs"
import { baseToken } from "../selectToken/baseToken"
import noSymbol from "../images/tokens/noSymbol.png"
import { Spinner } from "../spinner/Spinner"
import { displayDecimals, removeLeadingZero } from "../../utils"

//eslint-disable-next-line
export const RemoveLiquidity = ({ pair, tokenSelection, setting, userData }) => {
  const { active, account, library } = useWeb3React()
  const approveUpdate = useStore(addLiquidityApproval)
  const [allowance, setAllowance] = useState("0")
  //eslint-disable-next-line
  const [inputValue, setInputValue] = useState("0")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!library) return

    pairAllowance(account, ROUTER_ADDRESS, pair.pairAddress).then((value) => {
      setAllowance(ethers.formatEther(value + ""))
    })
  }, [active, library, approveUpdate, pair])

  const handleInputChange = (e) => {
    setInputValue(removeLeadingZero(e.target.value))
  }

  const executeApprove = () => {
    setLoading(true)
    pairApprove(ROUTER_ADDRESS, inputValue, pair.pairAddress, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          setLoading(false)
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              addLiquidityApproval.set(addLiquidityApproval.get() + 1)
            } else toast("Transaction Failed!")
            setLoading(false)
          })
          .catch((e) => {
            console.log(e)
            setLoading(false)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => {
        setLoading(false)
        console.error(e)
      })
  }

  const executeRemove = () => {
    setLoading(true)
    const convertedInputValue = inputValue.toString()

    const maxToken0 = (pair.userReserve0 * convertedInputValue) / pair.pairBalance
    const maxToken1 = (pair.userReserve1 * convertedInputValue) / pair.pairBalance
    const minToken0 = (maxToken0 - (maxToken0 * setting.remove.slippage) / 100).toString()
    const minToken1 = (maxToken1 - (maxToken1 * setting.remove.slippage) / 100).toString()

    if (pair.token0 === "ETH" || pair.token0 === WETH_ADDRESS) {
      removeLiquidityETH(pair.token1, convertedInputValue, minToken1, minToken0, account, library)
        .then((tx) => {
          if (!tx) {
            setLoading(false)
            toast("Transaction error!")
            return
          }
          toast(<ReceiptToast txHash={tx.hash} />)
          tx.wait()
            .then((res) => {
              if (res.status === 1) {
                toast("Transaction Success!")
                removeLiquidityUpdate.set(removeLiquidityUpdate.get() + 1)
              } else toast("Transaction Failed!")
              setLoading(false)
            })
            .catch((e) => {
              console.log(e)
              toast("Transaction Failed!")
              setLoading(false)
            })
        })
        .catch((e) => {
          console.error(e)
          setLoading(false)
        })
    } else if (pair.token1 === "ETH" || pair.token1 === WETH_ADDRESS) {
      removeLiquidityETH(pair.token0, convertedInputValue, minToken0, minToken1, account, library)
        .then((tx) => {
          if (!tx) {
            toast("Transaction error!")
            setLoading(false)
            return
          }
          toast(<ReceiptToast txHash={tx.hash} />)
          tx.wait()
            .then((res) => {
              if (res.status === 1) {
                toast("Transaction Success!")
                removeLiquidityUpdate.set(removeLiquidityUpdate.get() + 1)
              } else toast("Transaction Failed!")
              setLoading(false)
            })
            .catch((e) => {
              console.log(e)
              toast("Transaction Failed!")
              setLoading(false)
            })
        })
        .catch((e) => {
          console.error(e)
          setLoading(false)
        })
    } else {
      removeLiquidity(pair.token0, pair.token1, convertedInputValue, minToken0, minToken1, account, library)
        .then((tx) => {
          if (!tx) {
            toast("Transaction error!")
            setLoading(false)
            return
          }
          toast(<ReceiptToast txHash={tx.hash} />)
          tx.wait()
            .then((res) => {
              if (res.status === 1) {
                toast("Transaction Success!")
                removeLiquidityUpdate.set(removeLiquidityUpdate.get() + 1)
              } else toast("Transaction Failed!")
              setLoading(false)
            })
            .catch((e) => {
              console.log(e)
              toast("Transaction Failed!")
              setLoading(false)
            })
        })
        .catch((e) => {
          console.error(e)
          setLoading(false)
        })
    }
  }

  const setMax = () => {
    setInputValue(pair.pairBalance)
  }

  const buttons = ["25%", "50%", "75%", "max"]
  const [selectedButton, setSelectedButton] = useState()

  function toFixed(x) {
    let e
    if (Math.abs(x) < 1.0) {
      e = parseInt(x.toString().split("e-")[1])
      if (e) {
        x *= Math.pow(10, e - 1)
        x = "0." + new Array(e).join("0") + x.toString().substring(2)
      }
    } else {
      e = parseInt(x.toString().split("+")[1])
      if (e > 20) {
        e -= 20
        x /= Math.pow(10, e)
        x += new Array(e + 1).join("0")
      }
    }
    return x
  }

  const handleButton = (value) => {
    setSelectedButton(value)

    if (!value) {
      return
    }

    let setter

    if (value.endsWith("max")) {
      setMax()
      return
    } else {
      setter = +value.slice(0, -1)
    }

    const newValue = (+pair.pairBalance * setter) / 100

    setInputValue(toFixed(newValue))
  }

  const convertLp = (amount, token) => {
    return (+amount / pair.totalSupply) * (token === pair.token0 ? pair.reserve0 : pair.reserve1)
  }

  return (
    <div className="flex flex-col w-full pt-3 gap-10">
      <div className="w-full flex flex-col gap-2 items-center">
        <div className="flex flex-col card-light p-4 gap-6 w-full">
          <button
            disabled
            onClick={setMax}
            className="self-end flex flex-row items-center text-primary-text text-[14px] font-normal"
          >
            <p>Balance: {displayDecimals(pair.pairBalance)}</p>
          </button>
          <div className="w-full rounded flex flex-row max-sm:flex-col justify-between items-center gap-2">
            <input
              name="valueTokenA"
              onChange={handleInputChange}
              className="w-full bg-transparent font-bold text-[16px]"
              type="number"
              placeholder="0"
              value={inputValue}
              onClick={() => handleButton(null)}
            />
            <div className="flex flex-row gap-3 max-sm:w-full max-sm:justify-between">
              {buttons.map((item, index) => (
                <button
                  key={index}
                  className={`py-2 px-3 ${
                    item === selectedButton
                      ? "bg-primary-color text-white"
                      : "bg-card-light-blue border  border-card-border"
                  } rounded-full text-[14px]`}
                  onClick={() => handleButton(item)}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
        </div>
        <BsArrowDownShort size={24} color="black" />
        <div className="card-light w-full flex flex-col p-4 gap-6">
          <div className="w-full flex flex-row justify-between">
            <p className="font-bold text-[18px] text-primary-text">{pair.symbol0}</p>
            <div className="flex flex-row gap-2 items-center">
              <p className="font-bold text-[16px]">{displayDecimals(convertLp(inputValue, pair.token0))}</p>
              <img
                className="w-5 h-5"
                src={baseToken.find((item) => item.address === pair.token0)?.img || noSymbol}
              />
            </div>
          </div>
          <div className="w-full flex flex-row justify-between">
            <p className="font-bold text-[18px] text-primary-text">{pair.symbol1}</p>
            <div className="flex flex-row gap-2 items-center">
              <p className="font-bold text-[16px]">{displayDecimals(convertLp(inputValue, pair.token1))}</p>
              <img
                className="w-5 h-5"
                src={baseToken.find((item) => item.address === pair.token1)?.img || noSymbol}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-full py-3 rounded-custom border border-card-border flex flex-col items-start mt-2 px-3 gap-2">
        <p className="text-card-light-blue">Pool</p>
        <div className="flex flex-row gap-2">
          <p>{addressSlice(pair.pairAddress)}</p>
          <button onClick={openAddressOnScanner} className="">
            <MdOpenInNew size={"23"} />
          </button>
          <button onClick={copyAddressToClipBoard} className="">
            <MdContentCopy size={"23"} />
          </button>
        </div>
        <p className="text-card-light-blue">Total Liquidity</p>
        <p>
          {Number(pair.reserve0).toFixed(2)}{" "}
          {pair.token0 === tokenSelection.tokenA ? tokenSelection.symbolA : tokenSelection.symbolB}
          {" / "}
          {Number(pair.reserve1).toFixed(2)}{" "}
          {pair.token0 !== tokenSelection.tokenA ? tokenSelection.symbolA : tokenSelection.symbolB}
        </p>
      </div> */}
      {!active ? (
        <DisabledButton text={"Connect your wallet first!"} />
      ) : +inputValue > +pair.pairBalance ? (
        <DisabledButton text={"Amount exceed balance"} />
      ) : +pair.pairBalance === 0 ? (
        <DisabledButton text={"No LP"} />
      ) : loading ? (
        <button disabled className="loading-button">
          <Spinner size={"30"} />
        </button>
      ) : +inputValue > +allowance ? (
        <button onClick={executeApprove} className="interaction-button">
          Approve
        </button>
      ) : (
        <button onClick={executeRemove} className="interaction-button">
          Remove Liquidity
        </button>
      )}
      <LpTokensInfo pair={pair} userData={userData} />
    </div>
  )
}
