import { Popover, PopoverContent, PopoverHandler } from "@material-tailwind/react"
import { useEffect, useState } from "react"
import { BsGearFill } from "react-icons/bs"

export const SettingsPopover = ({ children, moreFunction }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900)

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 900
      setIsMobile(mobile)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Popover placement={isMobile ? "bottom-start" : "left-end"}>
      <PopoverHandler
        onClick={() => {
          console.log("test clicker")
          if (!moreFunction) {
            return
          }

          moreFunction()
        }}
      >
        <button>
          <BsGearFill
            size={20}
            color="white"
            onMouseOver={({ target }) => (target.style.color = "gray")}
            onMouseOut={({ target }) => (target.style.color = "white")}
            className="hover:color-slate-600"
          />
        </button>
      </PopoverHandler>
      <PopoverContent className="w-fit h-fit border-none p-0 bg-transparent">{children}</PopoverContent>
    </Popover>
  )
}
