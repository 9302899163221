import axios from "axios"
import { atom } from "nanostores"
import { getAPY } from "../../components/newFarming/farmingUtility"

export const pairsInfo = atom([])
export const totalTvl = atom(0)

export const getPairsInfo = async () => {
  const pairsAddress = [
    "0x3fe49194d3cf9a7005203468073a32d764fb86b6",
    "0x7ae7434Fe356Ca9e60E973Bd59974078fe8949bc",
    "0x9dCFF9C872BBF746633ce6d1c7a511a92bf75d39",
  ]

  let pairsData = []

  await Promise.all(
    pairsAddress.map(async (address) => {
      let response = await axios.get(`https://api.dexscreener.com/latest/dex/pairs/base/${address}`)

      let pair = response.data.pairs

      if (pair) {
        pairsData.push(response.data.pairs[0])
      }
    })
  )

  pairsInfo.set(pairsData)

  return pairsData
}

export const getTotalTvl = async () => {
  const pairs = await getPairsInfo()

  if (!pairs) {
    return 0
  }

  let current = 0

  pairs.forEach((pair) => {
    current += pair.liquidity.usd
  })

  if (current >= 1000000) {
    const millions = (current / 1000000).toFixed(2)
    current = millions.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "M"
  } else {
    current = Number(current.toFixed(0)).toLocaleString()
  }

  totalTvl.set(current)

  return current
}

export const getTokenPriceFromPair = async (pairAddress, baseToken = true) => {
  if (pairsInfo.get().length === 0) {
    await getPairsInfo()
  }

  let pair = pairsInfo.get().find((item) => item.pairAddress.toLowerCase() === pairAddress.toLowerCase())

  if (!pair) {
    pair = dataMock
  }

  if (baseToken) {
    return pair.priceUsd
  } else {
    return 1 / pair.priceNative
  }
}

export const getPairApy = (pairAddress) => {
  let pair = pairsInfo.get().find((item) => item.pairAddress === pairAddress)

  if (!pair) {
    pair = dataMock
  }

  const pairTvl = pair.liquidity.usd

  const h24Volume = pair.volume.h24

  const feeValue = h24Volume * 0.0025

  const apr = feeValue / pairTvl

  const apy = getAPY(apr)

  return apy
}

export const getPairTvl = (pairAddress) => {
  let pair = pairsInfo.get().find((item) => item.pairAddress.toLowerCase() === pairAddress.toLowerCase())

  if (!pair) {
    pair = dataMock
  }

  const pairTvl = pair.liquidity.usd

  return pairTvl
}

const dataMock = {
  liquidity: {
    usd: 0,
  },
  volume: {
    h24: 0,
  },
}
