import { useStore } from "@nanostores/react"
import { getTokenPriceFromPair, totalTvl } from "../../API/dexscreener/dexscreener"
import { useEffect, useState } from "react"
import { ETH_FORT_ADDRESS } from "../../constants"
import { Link } from "react-router-dom"

export const SidebarCard = () => {
  const tvlTotal = useStore(totalTvl)
  const [tokenPrice, setTokenPrice] = useState(0)

  useEffect(() => {
    getTokenPriceFromPair(ETH_FORT_ADDRESS).then((value) => setTokenPrice(value ? value : 0))
  })

  return (
    <div className="bg-card-light-blue self-center w-10/12 sm:min-h-[176px] rounded-[12px] p-4 flex flex-col justify-around max-sm:px-8 max-sm:gap-4 font-extrabold">
      <div className="flex flex-col text-start gap-2 max-sm:flex-row max-sm:justify-between">
        <p className="font-semibold max-sm:hidden">Total Value Locked</p>
        <p className="font-semibold sm:hidden">TVL</p>
        <p className="text-gray-text">$ {tvlTotal}</p>
      </div>
      <div className="flex flex-col text-start gap-2 max-sm:flex-row max-sm:justify-between">
        <p className="font-semibold">FORT Price</p>
        <Link to={"https://dexscreener.com/base/0x7ae7434fe356ca9e60e973bd59974078fe8949bc"} target="_blank">
          <p className="text-gray-text hover:text-primary-color transition-all duration-300">
            $ {tokenPrice}
          </p>
        </Link>
      </div>
    </div>
  )
}
