import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Layout } from "./components/layout/layout"
import { Liquidity } from "./components/liquidity/Liquidity"
import { Swap } from "./components/swap/Swap"
import { Launchpad } from "./components/launchpad/pad/launchpad"
import { Launch } from "./components/launchpad/launch"
import { PRIVATE_ICO_ADDRESS, PUBLIC_ICO_ADDRESS } from "./constants"
import { privateLaunch, publicLaunch } from "./components/launchpad/pad/launchInfo"
import { Farming } from "./components/newFarming/farming"
import { DividendsPage } from "./components/dividends/dividends"
//import { Coming } from "./components/utils/coming"
import { TestEsFort } from "./components/esFort/esFort"
import { Redirector } from "./components/redirector/redirector"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Swap />} />
          <Route path="/liquidity" element={<Liquidity />} />
          <Route path="/farms" element={<Farming />} />
          <Route path="/esfort" element={<TestEsFort />} />
          <Route path="/launchpad" element={<Launchpad />} />
          <Route path="/dividends" element={<DividendsPage />} />
          <Route path="/public-launch" element={<Redirector />} />
          <Route
            path="/publiclaunch"
            element={<Launch icoAddress={PUBLIC_ICO_ADDRESS} info={publicLaunch} />}
          />
          <Route
            path="/private-launch"
            element={<Launch icoAddress={PRIVATE_ICO_ADDRESS} info={privateLaunch} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
