import { useStore } from "@nanostores/react"
import { displayDecimals, limitDecimalPlaces } from "../../utils"
import noSymbol from "../images/tokens/noSymbol.png"
import { baseToken } from "../selectToken/baseToken"
import { BsChevronDown } from "react-icons/bs"

export const SwapInput = ({ context, type, handleChange, openSetting, output, setMax, disabled = false }) => {
  const balances = useStore(context.userBalance)
  const selectedTokens = useStore(context.selectedTokens)

  return (
    <div
      id="input-value"
      className={`border border-transparent bg-card-light-blue rounded-custom flex flex-row justify-between py-4 px-6 overflow-hidden ${
        !disabled ? "hover:border-primary-color" : ""
      }`}
    >
      <div className="flex items-center font-bold text-primary-text text-xl w-full">
        <input
          type="number"
          className="bg-transparent w-full text-left"
          id="swapValue"
          placeholder="0"
          disabled={disabled}
          onChange={(e) => {
            handleChange(e)
          }}
          value={limitDecimalPlaces(output, selectedTokens[type].decimal)}
        />
      </div>
      <div className="flex flex-col items-end min-w-[140px] max-w-[140px] gap-2">
        <button onClick={() => openSetting(true)} className="flex items-center w-fit">
          <img
            className="w-5"
            src={baseToken.find((item) => item.address === selectedTokens[type].address)?.img || noSymbol}
          />
          <h5 className="font-bold text-sm text-primary-text px-2">{selectedTokens[type].name}</h5>
          <BsChevronDown size={15} color="var(--primary-color)" />
        </button>
        <div className="flex justify-end text-gray-text text-xs w-full ">
          <button disabled={disabled} onClick={setMax} className="flex flex-row">
            <p className="mr-3">Balance: </p>
            <p>{displayDecimals(balances[type])}</p>
          </button>
        </div>
      </div>
    </div>
  )
}
