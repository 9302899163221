//eslint-disable-next-line
export const DisabledButton = ({ text, type = "" }) => {
  return (
    <button
      disabled
      className={`${
        !type ? "disabled-button" : type === "primary" ? "disabled-button-primary" : "disabled-button-rounded"
      }`}
    >
      {text}
    </button>
  )
}
