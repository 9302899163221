import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { approve } from "../../API/erc20"
import { ROUTER_ADDRESS, WETH_ADDRESS } from "../../constants"
import { Spinner } from "../spinner/Spinner"
import { DisabledButton } from "../utils/disabledButton"
import { toast } from "react-toastify"
import { ReceiptToast } from "../toast/receiptToast"
import { addLiquidityApproval, addLiquidityState, removeLiquidityUpdate } from "../../state/state"
import { addLiquidity } from "../../API/router"
import { baseToken } from "../selectToken/baseToken"
import { ethers } from "ethers"
import noSymbol from "../images/tokens/noSymbol.png"
import { BsPlusLg } from "react-icons/bs"
import { displayDecimals, removeLeadingZero } from "../../utils"

export const AddLiquidity = ({ pair, tokenSelection, setting, isLoadindBalance, userData }) => {
  const { account, active, library } = useWeb3React()

  const [liquidityData, setLiquidityData] = useState({ valueTokenA: "0", valueTokenB: "0" })

  const [loading, setLoading] = useState(false)
  const [loadingApprove, setLoadingApprove] = useState({
    tokenA: null,
    tokenB: null,
  })

  const setMax = (selector, value) => {
    let temp = { ...liquidityData }
    if (pair.pairAddress === ethers.ZeroAddress || (pair.reserve1 == 0 && pair.reserve0 == 0)) {
      temp[`valueToken${selector}`] = value
      setLiquidityData({ ...temp })
      return
    }
    temp = respectTheQuote(`valueToken${selector}`, value)
    setLiquidityData({ ...temp })
  }

  const respectTheQuote = (name, value) => {
    let temp = { ...liquidityData }
    let selectedTokenA = tokenSelection.tokenA === "ETH" ? WETH_ADDRESS : tokenSelection.tokenA
    let selectedTokenB = tokenSelection.tokenB === "ETH" ? WETH_ADDRESS : tokenSelection.tokenB

    if (name === "valueTokenA") {
      temp[name] = value
      let valueB
      if (pair.token0 === selectedTokenA) {
        valueB = (pair.reserve1 / pair.reserve0) * value
      } else {
        valueB = (pair.reserve0 / pair.reserve1) * value
      }

      valueB = valueB.toString().replace(",", ".")
      temp.valueTokenB = valueB
    } else {
      temp[name] = value
      let valueA
      if (pair.token0 === selectedTokenB) {
        valueA = (pair.reserve1 / pair.reserve0) * value
      } else {
        valueA = (pair.reserve0 / pair.reserve1) * value
      }
      valueA = valueA.toString().replace(",", ".")
      temp.valueTokenA = valueA
    }

    return temp
  }

  const handleInputChange = (e) => {
    const name = e.target.name
    const value = removeLeadingZero(e.target.value)
    let temp = { ...liquidityData }

    if (pair.pairAddress === ethers.ZeroAddress || (pair.reserve1 == 0 && pair.reserve0 == 0)) {
      temp[name] = value
      setLiquidityData(temp)
      return
    }

    temp = respectTheQuote(name, value)

    setLiquidityData(temp)
  }

  const executeApprove = (value, contractAddress, token) => {
    selectApprove(contractAddress, token)
    approve(ROUTER_ADDRESS, value, contractAddress, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          selectApprove(null, token)
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              addLiquidityApproval.set(addLiquidityApproval.get() + 1)
            } else toast("Transaction Failed!")
            selectApprove(null, token)
          })
          .catch((e) => {
            console.log(e)
            selectApprove(null, token)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => {
        selectApprove(null, token)
        console.error(e)
      })
  }

  const executeAddLiquidity = () => {
    setLoading(true)
    addLiquidity(
      tokenSelection.tokenA,
      tokenSelection.tokenB,
      liquidityData.valueTokenA,
      liquidityData.valueTokenB,
      account,
      setting.add.slippage,
      setting.add.deadLine,
      library
    )
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          setLoading(false)
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              addLiquidityApproval.set(addLiquidityApproval.get() + 1)
              addLiquidityState.set(addLiquidityState.get() + 1)
              removeLiquidityUpdate.set(removeLiquidityUpdate.get() + 1)
            } else toast("Transaction Failed!")
            setLoading(false)
          })
          .catch((e) => {
            console.log(e)
            setLoading(false)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }

  useState(() => {
    console.log(pair)
  }, [pair])

  const calculatePercentage = (pair) => {
    return (+liquidityData.valueTokenA / (+pair.reserve0 + +liquidityData.valueTokenA)) * 100
  }

  const selectApprove = (address, token) => {
    const current = { ...loadingApprove }

    if (token === "a") {
      current.tokenA = address
    } else {
      current.tokenB = address
    }

    setLoadingApprove(current)
  }

  useEffect(() => {
    console.log("Loading Approve", loadingApprove)
  }, [loadingApprove])

  const checkAllowance = (num1, num2) => {
    console.log("allowance check")
    // Trova il numero massimo di decimali tra i due numeri
    const num1Decimals = num1.toString().includes(".") ? num1.toString().split(".")[1].length : 0
    const num2Decimals = num2.toString().includes(".") ? num2.toString().split(".")[1].length : 0

    // Rimuovi i decimali dal primo numero se ne ha meno del secondo
    if (num1Decimals > num2Decimals) {
      const rounder = 10 ** num2Decimals
      num1 = Math.floor(num1 * rounder) / rounder
    }

    return num1 > num2
  }

  return (
    <div className="flex flex-col w-full pt-3 gap-10">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 bg-card-light-blue rounded-custom w-full p-4">
          <div className="w-full flex flex-row justify-between text-gray-text text-[14px] font-normal">
            <p>Amount</p>
            <p className="self-end flex flex-row items-center">
              Balance:{" "}
              {isLoadindBalance ? (
                <div className="pl-2">
                  {" "}
                  <Spinner size={15} />{" "}
                </div>
              ) : (
                <button disabled onClick={() => setMax("A", userData.parsedBalanceTokenA)} className="pl-2">
                  {displayDecimals(userData.parsedBalanceTokenA)}
                </button>
              )}
            </p>
          </div>
          <div className="w-full max-w-full flex flex-row justify-between items-center font-bold text-[16px] gap-2">
            <input
              name="valueTokenA"
              onChange={handleInputChange}
              className="w-8/12 bg-transparent"
              type="number"
              placeholder="0"
              value={liquidityData.valueTokenA}
            />
            <button
              className="px-2 text-primary-color border border-primary-color rounded-custom"
              onClick={() => setMax("A", userData.parsedBalanceTokenA)}
            >
              Max
            </button>
            <div className="flex flex-row gap-2 items-center w-fit">
              <img
                className="w-5"
                src={baseToken.find((item) => item.address === tokenSelection.tokenA)?.img || noSymbol}
              />
              <p className="">{tokenSelection.symbolA}</p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row justify-center items-center relative mr-[7px]">
          <button id="invert-swap" disabled className=" self-cente">
            <BsPlusLg size={20} color="var(--primary-color)" className="" />
          </button>
        </div>
        <div className="flex flex-col gap-2 bg-card-light-blue rounded-custom w-full p-4">
          <div className="w-full flex flex-row justify-between text-gray-text text-[14px] font-normal">
            <p>Amount</p>
            <p className="self-end flex flex-row items-center">
              Balance:{" "}
              {isLoadindBalance ? (
                <div className="pl-2">
                  {" "}
                  <Spinner size={15} />{" "}
                </div>
              ) : (
                <button disabled onClick={() => setMax("B", userData.parsedBalanceTokenA)} className="pl-2">
                  {displayDecimals(userData.parsedBalanceTokenB)}
                </button>
              )}
            </p>
          </div>
          <div className="w-full max-w-full flex flex-row justify-between items-center font-bold text-[16px] gap-2">
            <input
              name="valueTokenB"
              onChange={handleInputChange}
              className="w-8/12 bg-transparent"
              type="number"
              placeholder="0"
              value={liquidityData.valueTokenB}
            />
            <button
              className="px-2 text-primary-color border border-primary-color rounded-custom"
              onClick={() => setMax("B", userData.parsedBalanceTokenB)}
            >
              Max
            </button>
            <div className="flex flex-row gap-2 items-center w-fit">
              <img
                className="w-5"
                src={baseToken.find((item) => item.address === tokenSelection.tokenB)?.img || noSymbol}
              />
              <p className="">{tokenSelection.symbolB}</p>
            </div>
          </div>
        </div>
      </div>
      {pair.pairAddress === ethers.ZeroAddress ? (
        ""
      ) : (
        <div className="flex flex-col gap-4">
          <p className="font-bold text-[16px]">Prices and Pool Share</p>
          <div className="bg-card-light-blue w-full rounded-custom grid grid-cols-3 py-8">
            <div className="w-full flex flex-col items-center justify-center">
              <p className="font-bold text-[16px]">{displayDecimals(+pair.reserve1 / +pair.reserve0)}</p>
              <p className="text-[12px] font-normal text-gray-text">
                {pair.symbol0} per {pair.symbol1}
              </p>
            </div>
            <div className="w-full flex flex-col items-center justify-center">
              <p className="font-bold text-[16px]">{displayDecimals(pair.reserve0 / pair.reserve1)}</p>
              <p className="text-[12px] font-normal text-gray-text">
                {pair.symbol1} per {pair.symbol0}
              </p>
            </div>
            <div className="w-full flex flex-col items-center justify-center">
              <p className="font-bold text-[16px]">{displayDecimals(calculatePercentage(pair))} %</p>
              <p className="text-[12px] font-normal text-gray-text">Share of Pool</p>
            </div>
          </div>
        </div>
      )}
      {/* <p className="self-end flex flex-row items-center">
        Balance:{" "}
        {isLoadindBalance ? (
          <div className="pl-2">
            {" "}
            <Spinner size={20} />{" "}
          </div>
        ) : (
          <button onClick={() => setMax("B", userData.parsedBalanceTokenB)} className="pl-2">
            {Number(userData.parsedBalanceTokenB).toFixed(2)}
          </button>
        )}
      </p>
      <div className="w-full h-[50px] rounded-custom border border-card-border hover:border-primary-color flex flex-row justify-between items-center mt-2 px-3">
        <div className="flex flex-row gap-2 items-center">
          <img
            className="w-5"
            src={baseToken.find((item) => item.address === tokenSelection.tokenB)?.img || noSymbol}
          />
          <p>{tokenSelection.symbolB}</p>
        </div>
        <input
          name="valueTokenB"
          onChange={handleInputChange}
          className="w-full bg-transparent text-end"
          type="number"
          placeholder="0"
          value={liquidityData.valueTokenB}
        />
      </div> */}
      {!active ? (
        <DisabledButton text={"Connect your wallet first!"} marginY="0" paddingX="0" />
      ) : +liquidityData.valueTokenA > +userData.parsedBalanceTokenA ||
        +liquidityData.valueTokenB > +userData.parsedBalanceTokenB ? (
        <DisabledButton
          marginY="0"
          paddingX="0"
          text={`Amount ${
            +liquidityData.valueTokenA > +userData.parsedBalanceTokenA
              ? tokenSelection.symbolA
              : tokenSelection.symbolB
          } exceed balance`}
        />
      ) : checkAllowance(+liquidityData.valueTokenA, +userData.parsedAllowanceTokenA) ||
        checkAllowance(+liquidityData.valueTokenB, +userData.parsedAllowanceTokenB) ? (
        <div className="flex flex-row gap-4">
          {checkAllowance(+liquidityData.valueTokenA, +userData.parsedAllowanceTokenA) ? (
            loadingApprove.tokenA === tokenSelection.tokenA ? (
              <button disabled className="loading-button">
                <Spinner size={"30"} />
              </button>
            ) : (
              <button
                onClick={() => executeApprove(liquidityData.valueTokenA, tokenSelection.tokenA, "a")}
                className="interaction-button"
              >
                Approve {tokenSelection.symbolA}
              </button>
            )
          ) : (
            ""
          )}
          {checkAllowance(+liquidityData.valueTokenB, +userData.parsedAllowanceTokenB) ? (
            loadingApprove.tokenB === tokenSelection.tokenB ? (
              <button disabled className="loading-button">
                <Spinner size={"30"} />
              </button>
            ) : (
              <button
                onClick={() => executeApprove(liquidityData.valueTokenB, tokenSelection.tokenB, "b")}
                className="interaction-button"
              >
                Approve {tokenSelection.symbolB}
              </button>
            )
          ) : (
            ""
          )}
        </div>
      ) : loading ? (
        <button disabled className="loading-button">
          <Spinner size={"30"} />
        </button>
      ) : (
        <button onClick={executeAddLiquidity} className="interaction-button">
          Supply
        </button>
      )}
    </div>
  )
}
