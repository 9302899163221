import { ethers } from "ethers"
import { getReserve, getTotalSupply } from "../../API/pair"
import { ETH_FORT_ADDRESS, ETH_USDC_ADDRESS, FORT_ADDRESS, WETH_ADDRESS } from "../../constants"
import { getTokenPriceFromPair } from "../../API/dexscreener/dexscreener"

const stableArray = [
  "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",
  "0x8E86e46278518EFc1C5CEd245cBA2C7e3ef11557",
  "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
]

export const calculateTVLStable = async (pair, lpLocked) => {
  const reserves = await getReserve(pair)
  const totalSupply = await getTotalSupply(pair)

  const parsedReserves = [ethers.formatUnits(reserves[0] + "", 6), ethers.formatUnits(reserves[1] + "", 6)]

  const reservesSum = +parsedReserves[0] + +parsedReserves[1]

  const tvl = (reservesSum * lpLocked) / totalSupply

  return tvl
}

//eslint-disable-next-line
export const calculateTVL = async (lpLocked, poolInfo) => {
  const reserves = await getReserve(poolInfo.pair)
  //eslint-disable-next-line
  const totalSupply = await getTotalSupply(poolInfo.pair)

  const parsedReserves = [
    ethers.formatUnits(reserves[0] + "", poolInfo.decimals0),
    ethers.formatUnits(reserves[1] + "", poolInfo.decimals1),
  ]

  let pairTvl = 0

  if (stableArray.includes(poolInfo.token0)) {
    const token1Value = parsedReserves[0] / parsedReserves[1]
    const totalValueToken1 = token1Value * parsedReserves[1]

    pairTvl = +totalValueToken1 + +parsedReserves[0]
  } else if (stableArray.includes(poolInfo.token1)) {
    const token0Value = parsedReserves[1] / parsedReserves[0]
    const totalValueToken0 = token0Value * parsedReserves[0]

    pairTvl = +totalValueToken0 + +parsedReserves[1]
  } else {
    if (poolInfo.token0.toLowerCase() === WETH_ADDRESS.toLowerCase()) {
      const ethPrice = await getETHPrice()
      pairTvl = parsedReserves[0] * ethPrice * 2
    } else if (poolInfo.token1.toLowerCase() === WETH_ADDRESS.toLowerCase()) {
      const ethPrice = await getETHPrice()
      pairTvl = parsedReserves[1] * ethPrice * 2
    } else if (poolInfo.token0.toLowerCase() === FORT_ADDRESS.toLowerCase()) {
      pairTvl = (await convertSwordToUsd(parsedReserves[0])) * 2
    } else if (poolInfo.token1.toLowerCase() === FORT_ADDRESS.toLowerCase()) {
      pairTvl = (await convertSwordToUsd(parsedReserves[1])) * 2
    } else {
      return 0
    }
  }

  const tvl = (pairTvl * lpLocked) / totalSupply

  return isNaN(tvl) ? 0 : tvl
}

export const convertSwordToUsd = async (value) => {
  const oneToken = await getTokenPriceFromPair(ETH_FORT_ADDRESS)

  return oneToken ? oneToken : 0.05 * +value
}

export const getETHPrice = async () => {
  const oneToken = await getTokenPriceFromPair(ETH_USDC_ADDRESS)

  return oneToken
}

export const getAPR = async (valueLocked, percentageRewards) => {
  const swordPrice = await convertSwordToUsd(1)
  const apr = (1 / valueLocked) * swordPrice * percentageRewards * 1680000

  return apr
}

export const getAPY = (apr) => {
  const apy = Math.floor(((1 + apr / 365) ** 365 - 1) * 10000) / 100

  return isFinite(apy) ? apy : 0
}
