import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { getParsedBalance, keepDecimalSeparatorAtPosition1, removeLeadingZero } from "../../../utils"

import { Spinner } from "../../spinner/Spinner"
import { DisabledButton } from "./disabledButton"
import { commit as publicCommit } from "../../../API/ico"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../toast/receiptToast"
import { saleUpdate } from "../../../state/state"

import { invest as privateCommit } from "../../../API/privateSale"
import { PRIVATE_ICO_ADDRESS, PRIVATE_ICO_MAX_COMMITMENT, PRIVATE_ICO_TARGET } from "../../../constants"
import { ethers } from "ethers"

//eslint-disable-next-line
export const Sale = ({
  icoAddress,
  info,
  started = true,
  isOnWhite = true,
  getData,
  raised,
  investments,
}) => {
  const { active, library, account } = useWeb3React()
  const [balance, setBalance] = useState("0.00000")
  const [inputValue, setInputValue] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleInputChange = (e) => {
    let value = removeLeadingZero(e.target.value)
    value = keepDecimalSeparatorAtPosition1(value)
    setInputValue(value)
  }

  const updateBalance = async () => {
    setIsLoading(true)
    const value = await getParsedBalance(account)
    setBalance(value)
    setIsLoading(false)
  }

  const setMax = () => {
    if (active) updateBalance().then(() => setInputValue(balance))
  }

  const executeCommit = () => {
    const selectedMethod = info.type !== "private" ? publicCommit : privateCommit
    selectedMethod(icoAddress, inputValue, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              updateBalance()
              getData()
              saleUpdate.set(saleUpdate.get() + 1)
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  useEffect(() => {
    if (!library || !active) {
      setBalance("0.00000")
      return
    }

    updateBalance()
  }, [active, library, account])

  const overCommit = (inputValue) => {
    if (icoAddress === PRIVATE_ICO_ADDRESS) {
      const convertedInput = ethers.parseEther(inputValue)
      const convertedInvestments = ethers.parseEther(investments)
      const convertedMax = ethers.parseEther(PRIVATE_ICO_MAX_COMMITMENT + "")
      const totalCommitments = +(convertedInput + "") + +(convertedInvestments + "")

      return totalCommitments > +convertedMax.toString()
    }
    return +inputValue > info.maxCommit || +inputValue < info.minCommit
  }

  return (
    <>
      <div className="w-full flex flex-col bg-card-light-blue rounded-custom p-4 gap-2">
        <div className="self-end text-gray-text text-[14px] flex flex-row items-center">
          <p className="self-start pr-2">Balance:</p>
          {isLoading ? <Spinner size={"20"} /> : <p> {balance} ETH</p>}
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          <input
            onChange={handleInputChange}
            value={inputValue}
            className="bg-transparent w-full ml-2 font-bold"
            placeholder="0"
          />
          <button
            onClick={setMax}
            className="px-4 h-10 text-primary-color hover:bg-primary-color hover:text-white rounded-custom border border-primary-color"
          >
            MAX
          </button>
        </div>
      </div>
      <div className="h-1" />

      {+raised >= PRIVATE_ICO_TARGET && info.type === "private" ? (
        <DisabledButton text={"Hardcap Reached"} />
      ) : !active ? (
        <DisabledButton text={"Connect wallet"} />
      ) : !isOnWhite ? (
        <DisabledButton text={"Not whitelisted"} />
      ) : !started ? (
        <DisabledButton text={"Not Started"} />
      ) : inputValue == 0 ? (
        <DisabledButton text={"Insert amount first"} />
      ) : overCommit(inputValue) ? (
        <DisabledButton
          text={`${+inputValue < info.minCommit ? "Import exceed Min Commit" : "Import exceed Max Commit"}`}
        />
      ) : +inputValue > +balance ? (
        <DisabledButton text={"Input exceed Balance"} />
      ) : (
        <button onClick={executeCommit} className="interaction-button">
          {"Buy" + (icoAddress === PRIVATE_ICO_ADDRESS ? "" : " & Farm")}
        </button>
      )}
    </>
  )
}
