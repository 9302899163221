/* eslint-disable */
import { ethers } from "ethers"
import { getReserve, getTotalSupply } from "../../API/pair"
import { SWORD_ADDRESS, WETH_ADDRESS } from "../../constants"
import { getPairTvl } from "../../API/dexscreener/dexscreener"
import { convertSwordToUsd } from "../farming/farmingUtility"

const stableArray = ["0x9dCFF9C872BBF746633ce6d1c7a511a92bf75d39"]

const swordPair = "0xc8b6B3A4D2d8428EF3a940EAc1E32A7DdAdCB0f1"
const usdcETHPair = "0xF6aF5785d59fc39A371C650cb6D3A6144C4dC85b"

export const calculateTVLStable = async (poolInfo, lpLocked) => {
  const reserves = await getReserve(poolInfo.pair)
  const totalSupply = await getTotalSupply(poolInfo.pair)

  const parsedReserves = [
    ethers.formatUnits(reserves[0] + "", poolInfo.decimals0),
    ethers.formatUnits(reserves[1] + "", poolInfo.decimals1),
  ]

  const reservesSum = +parsedReserves[0] + +parsedReserves[1]

  const tvl = (reservesSum * lpLocked) / totalSupply

  return tvl
}

export const calculateTVL = async (lpLocked, poolInfo) => {
  const totalSupply = await getTotalSupply(poolInfo.pair)

  let pairTvl = getPairTvl(poolInfo.pair)

  const tvl = (pairTvl * lpLocked) / totalSupply

  return isNaN(tvl) ? 0 : tvl
}

export const getETHPrice = async () => {}

export const getAPR = async (valueLocked, percentageRewards) => {
  const swordPrice = await convertSwordToUsd(1)
  const apr = (1 / valueLocked) * swordPrice * percentageRewards * 18400000

  return apr
}

export const getAPY = (apr) => {
  const apy = Math.floor(((1 + apr / 365) ** 365 - 1) * 10000) / 100

  return isFinite(apy) ? apy : 0
}
