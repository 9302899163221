import { removeDecimals, removeLeadingZero } from "../../../../utils"
import { DisabledButton } from "./disabledButton"
import { useWeb3React } from "@web3-react/core"
import { approve2 } from "../../../../API/xtoken"
import { DIVIDENDS_ADDRESS } from "../../../../constants"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../../toast/receiptToast"
import { updateInizializeReedem, xStakeUpdate } from "../../../../state/state"
import { useState } from "react"
import { ethers } from "ethers"
import { allocate } from "../../../../API/dividends"
import { Spinner } from "../../../spinner/Spinner"
import { LoadingButton } from "../../../utils/loadingButton"

export const Allocate = ({ userDividendsInfo, esFortBalance, esFortBalanceLoading }) => {
  const { account, active, library } = useWeb3React()
  const [amount, setAmount] = useState(0)
  const [loading, setLoading] = useState(false)

  const handleInputChange = (e) => {
    const value = removeLeadingZero(e.target.value)
    setAmount(value)
  }

  const setMax = () => {
    setAmount(esFortBalance.parsed)
  }

  const executeApprove = async () => {
    setLoading(true)
    await approve2(DIVIDENDS_ADDRESS, ethers.parseEther(amount), library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          setLoading(false)
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              updateInizializeReedem.set(updateInizializeReedem.get() + 1)
            } else toast("Transaction Failed!")

            setLoading(false)
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
            setLoading(false)
          })
      })
      .catch((e) => {
        setLoading(false)
        console.error(e)
      })
  }

  const execute = async () => {
    setLoading(true)
    await allocate(account, amount, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          setLoading(false)
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              updateInizializeReedem.set(updateInizializeReedem.get() + 1)
              xStakeUpdate.set(xStakeUpdate.get() + 1)
            } else toast("Transaction Failed!")
            setLoading(false)
          })
          .catch((e) => {
            console.log(e)
            setLoading(false)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => {
        setLoading(false)
        console.error(e)
      })
  }

  return (
    <div className="flex flex-col items-center w-full">
      <div className="bg-card-light-blue rounded-custom flex flex-col p-4 w-full">
        <button onClick={setMax} className="self-end text-gray-text pb-2 text-xs">
          <div className="flex items-center">
            Balance:{" "}
            {esFortBalanceLoading ? <Spinner size={10} /> : removeDecimals(esFortBalance.parsed) + " esFORT"}
          </div>
        </button>
        <div className="w-full h-[50px] flex items-center text-white justify-between text-center gap-[10px]">
          <input
            onChange={handleInputChange}
            name="amount"
            type="number"
            className="w-10/12 bg-transparent"
            placeholder="0"
            value={amount}
          />
          <div className="max-w-[112px] flex w-full max-h-[46px] ">
            {loading ? (
              <LoadingButton additionaStyle={"max-h-[46px]"} />
            ) : !active ? (
              <DisabledButton text={"Connect"} />
            ) : +amount > +esFortBalance.parsed ? (
              <DisabledButton text={"Insufficient Amount!"} />
            ) : +amount > +userDividendsInfo.allowance ? (
              <button
                onClick={executeApprove}
                className="interaction-button max-h-[46px] hover:bg-card-color"
              >
                Approve
              </button>
            ) : (
              <button onClick={execute} className="interaction-button max-h-[46px] hover:bg-card-color">
                Allocate
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
