import { Contract } from "@ethersproject/contracts"
import ROUTER_ABI from "../ABI/ROUTER_ABI.json"
import { ROUTER_ADDRESS, WETH_ADDRESS } from "../constants"
import { parseValueToWeiString } from "./erc20"
import { getPairToken, getReserve } from "./pair"
import { parseNumberToWeiETH, removeAfterDot } from "../utils"
import { providerReadOnly } from "../state/state"
import { ethers } from "ethers"

export const getWETHAddress = async (provider) => {
  const contract = connectContract(provider)

  return await contract.WETH()
}

export const getFactoryAddress = async (provider) => {
  const contract = connectContract(provider)

  return await contract.factory()
}

export const getAmountsOutOriginal = async (amountIn, path, provider) => {
  if (amountIn === "") return "0"

  const contract = connectContract(provider)
  if (path[0] === "ETH") path[0] = WETH_ADDRESS
  if (path[1] === "ETH") path[1] = WETH_ADDRESS

  return await contract.getAmountsOut(amountIn, path)
}

export const getAmountsOut = async (amountIn, path, provider) => {
  if (amountIn === "") return "0"

  const contract = connectContract(provider)
  const parsedValue = await parseValueToWeiString(amountIn, path[0], provider)

  if (path[0] === "ETH") path[0] = WETH_ADDRESS

  if (path[1] === "ETH") path[1] = WETH_ADDRESS

  const amountsResult = await contract.getAmountsOut(parsedValue, path)

  return amountsResult
}

export const getAmountsOutWithDecimal = async (amountIn, path, decimal, provider = providerReadOnly) => {
  if (amountIn === "") return "0"

  const contract = connectContract(provider)
  const parsedValue = ethers.parseUnits(removeAfterDot(amountIn + "", decimal), decimal)

  if (path[0] === "ETH") path[0] = WETH_ADDRESS

  if (path[1] === "ETH") path[1] = WETH_ADDRESS

  const amountsResult = await contract.getAmountsOut(parsedValue, path)

  return amountsResult
}

export const getPair = async (address1, address2) => {
  const contract = connectContract()
  const pair = await contract.getPair(convertToETHAddress(address1), convertToETHAddress(address2))

  return ethers.ZeroAddress === pair ? undefined : pair
}

const convertToETHAddress = (address) => {
  return address === "ETH" ? WETH_ADDRESS : address
}

export const quote = async (amount, tokenA, tokenB, provider) => {
  const contract = connectContract(provider)

  const pair = await getPair(tokenA, tokenB, provider)

  const parsedValue = parseValueToWeiString(amount, tokenA, provider)

  const reserve = await getReserve(pair, provider)

  const token0 = await getPairToken(0, pair, provider)

  if (tokenA.toLowerCase() === token0.toLowerCase())
    return await contract.quote(parsedValue, reserve[0] + "", reserve[1] + "")

  return await contract.quote(parsedValue, reserve[1] + "", reserve[0] + "")
}

//write method

export const addLiquidity = async (
  tokenA,
  tokenB,
  amountA,
  amountB,
  to,
  slippage = 5,
  deadLineSeconds = 90,
  provider
) => {
  const contract = connectContract(provider.getSigner())

  const parsedAmountA = await parseValueToWeiString(amountA, tokenA, provider)

  const parsedAmountB = await parseValueToWeiString(amountB, tokenB, provider)

  const deadLine = getDeadLine(+deadLineSeconds)

  let amountAMin = calculateSlippageAmount(parsedAmountA + "", slippage)

  amountAMin = Number(amountAMin)
    .toLocaleString()
    .replace(/[.,\s]/g, "")

  let amountBMin = calculateSlippageAmount(parsedAmountB + "", slippage)

  amountBMin = Number(amountBMin)
    .toLocaleString()
    .replace(/[.,\s]/g, "")

  if (tokenA === "ETH") {
    return await contract.addLiquidityETH(tokenB, parsedAmountB, amountBMin, amountAMin, to, deadLine, {
      value: parsedAmountA,
    })
  } else if (tokenB === "ETH") {
    return await contract.addLiquidityETH(tokenA, parsedAmountA, amountAMin, amountBMin, to, deadLine, {
      value: parsedAmountB,
    })
  } else {
    return await contract.addLiquidity(
      tokenA,
      tokenB,
      parsedAmountA,
      parsedAmountB,
      amountAMin,
      amountBMin,
      to,
      deadLine
    )
  }
}

export const removeLiquidity = async (
  tokenA,
  tokenB,
  liquidity,
  amountAMin,
  amountBMin,
  to,
  provider,
  deadLine = getDeadLine()
) => {
  const contract = connectContract(provider.getSigner())

  const parsedLiquidity = parseNumberToWeiETH(liquidity)
  const parsedAmountA = await parseValueToWeiString(amountAMin, tokenA, provider)
  const parsedAmountB = await parseValueToWeiString(amountBMin, tokenB, provider)

  return await contract.removeLiquidity(
    tokenA,
    tokenB,
    parsedLiquidity,
    parsedAmountA,
    parsedAmountB,
    to,
    deadLine
  )
}

export const removeLiquidityETH = async (
  token,
  liquidity,
  amountTokenMin,
  amountETHMin,
  to,
  provider,
  deadLine = getDeadLine()
) => {
  const contract = connectContract(provider.getSigner())

  const parsedLiquidity = parseNumberToWeiETH(liquidity)
  const parsedAmountTokenMin = await parseValueToWeiString(amountTokenMin, token, provider)
  const parsedAmountETHMin = ethers.parseEther(Number(amountETHMin).toFixed(18))

  return await contract.removeLiquidityETHSupportingFeeOnTransferTokens(
    token,
    parsedLiquidity,
    parsedAmountTokenMin,
    parsedAmountETHMin,
    to,
    deadLine
  )
}

export const swapTokensForTokens = async (
  amountIn,
  amountOutMin,
  path,
  to,
  referrer,
  provider,
  deadLine = getDeadLine()
) => {
  const contract = connectContract(provider.getSigner())

  const parsedAmountIn = await parseValueToWeiString(amountIn, path[0], provider)
  const parsedAmountOutMin = await parseValueToWeiString(amountOutMin, path[path.length - 1], provider)

  return await contract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
    parsedAmountIn,
    parsedAmountOutMin,
    path,
    to,
    referrer,
    deadLine
  )
}

export const swapTokensForETHs = async (
  amountIn,
  amountOutMin,
  path,
  to,
  referrer,
  provider,
  deadLine = getDeadLine()
) => {
  path[path.length - 1] = WETH_ADDRESS
  const contract = connectContract(provider.getSigner())

  const parsedAmountIn = await parseValueToWeiString(amountIn, path[0], provider)
  const parsedAmountOutMin = await parseValueToWeiString(amountOutMin, path[path.length - 1], provider)

  return await contract.swapExactTokensForETHSupportingFeeOnTransferTokens(
    parsedAmountIn,
    parsedAmountOutMin,
    path,
    to,
    referrer,
    deadLine
  )
}

export const swapETHsForTokens = async (
  amountIn,
  amountOutMin,
  path,
  to,
  referrer,
  provider,
  deadLine = getDeadLine()
) => {
  const contract = connectContract(provider.getSigner())
  path[0] = WETH_ADDRESS

  const parsedAmountIn = await parseValueToWeiString(amountIn, path[0], provider)
  const parsedAmountOutMin = await parseValueToWeiString(amountOutMin, path[path.length - 1], provider)

  return await contract.swapExactETHForTokensSupportingFeeOnTransferTokens(
    parsedAmountOutMin,
    path,
    to,
    referrer,
    deadLine,
    {
      value: parsedAmountIn,
    }
  )
}

export const calculateSlippageAmount = (amount, slippage) => {
  const avg = +amount - (+amount * slippage) / 100
  return Math.trunc(avg)
}

const getDeadLine = (seconds = 90) => {
  return Math.trunc(Date.now() / 1000 + seconds).toString()
}

const connectContract = (provider = providerReadOnly) => {
  return new Contract(ROUTER_ADDRESS, ROUTER_ABI, provider)
}
