import { useWeb3React } from "@web3-react/core"
import { useEffect, useRef, useState } from "react"
import jazzicon from "@metamask/jazzicon"
import { ReactComponent as MetamaskLogo } from "../images/wallet/metamask.svg"
import { ReactComponent as CoinbaseLogo } from "../images/wallet/coinbase.svg"
import { addressSlice } from "../../utils"
import { DisconnectButton } from "./disconnectButton"
import { CHAIN, CHAIN_OBJ } from "../../constants"
import { WrongChain } from "./wrongChain"
import { CurrentInfo } from "./currentInfo"

export const ConnectionInfo = ({ setConnectionSidebarOpen, connectionSidebarOpen }) => {
  const { account, library, active, chainId } = useWeb3React()
  const avatarRef = useRef()
  //eslint-disable-next-line
  const [walletType, setWalletType] = useState()

  useEffect(() => {
    if (!library || !active) {
      return
    }

    const provider = library.provider

    if (provider.isMetaMask) {
      setWalletType("metamask")
    } else if (provider.isCoinbaseWallet) {
      setWalletType("coinbase")
    }
  }, [library])

  useEffect(() => {
    const element = avatarRef.current
    if (element && account) {
      const addr = account.slice(2, 10)
      const seed = parseInt(addr, 16)
      const icon = jazzicon(40, seed) // genera un'icona di dimensione 20
      if (element.firstChild) {
        element.removeChild(element.firstChild)
      }
      element.appendChild(icon)
    }
  }, [account, avatarRef])

  useEffect(() => {
    if (active && chainId !== CHAIN) {
      setConnectionSidebarOpen(true)
    }
  }, [active, chainId, connectionSidebarOpen])

  const switchNetwork = async () => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: CHAIN_OBJ.chainId }],
      })
    } catch (switchError) {
      // 4902 error code indicates the chain is missing on the wallet
      if (switchError.code === 4902) {
        try {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [{ ...CHAIN_OBJ }],
          })
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  const handleSwitch = async () => {
    await switchNetwork()

    if (chainId === CHAIN) {
      setTimeout(() => {
        setConnectionSidebarOpen(false)
      }, 300)
    }
  }

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-row w-full justify-between">
        <div className="w-full h-fit flex flex-row text-white font-medium text-[16px]]">
          <div className="relative w-11 flex flex-row h-11">
            <div className="absolute left-0" ref={avatarRef}></div>
            <div className="absolute left-7 bottom-0 border-2 rounded-full border-card-color p-[1px] bg-card-color">
              {walletType === "metamask" ? (
                <MetamaskLogo width={16} />
              ) : (
                <CoinbaseLogo width={15} height={15} />
              )}
            </div>
          </div>
          <div className="pt-1">
            <p>{addressSlice(account)}</p>
          </div>
        </div>
        <DisconnectButton />
      </div>
      <div className="flex w-full">
        {chainId !== CHAIN ? (
          <WrongChain handleSwitch={handleSwitch} />
        ) : (
          <CurrentInfo connectionSidebarOpen={connectionSidebarOpen} />
        )}
      </div>
    </div>
  )
}
