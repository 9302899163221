import { PRIVATE_ICO_ADDRESS } from "../../constants"
import { displayDecimals } from "../../utils"

//eslint-disable-next-line
export const SellInfo = ({ icoAddress, data }) => {
  return (
    <>
      <div className="flex flex-row max-lg:grid max-lg:grid-cols-2 py-2 gap-[10px] justify-between w-full">
        <div className="flex flex-col rounded-custom bg-card-light-blue w-full p-6 items-center justify-center text-center">
          <p className="text-white font-bold ">Total Purchased</p>
          <p className="text-gray-text">{displayDecimals(data.commit || 0)} ETH</p>
        </div>
        {icoAddress === PRIVATE_ICO_ADDRESS ? (
          ""
        ) : (
          <div className="flex flex-col rounded-custom bg-card-light-blue w-full p-6 items-center justify-center text-center">
            <p className="text-white font-bold">ETH to Refund</p>
            <p className="text-gray-text">{displayDecimals(data.refound || 0)} ETH</p>
          </div>
        )}

        <div className="flex flex-col rounded-custom bg-card-light-blue w-full p-6 items-center justify-center text-center">
          <p className="text-white font-bold">$FORT</p>
          <p className="text-gray-text">{displayDecimals(data.fort || 0)}</p>
        </div>
        <div className="flex flex-col rounded-custom bg-card-light-blue w-full p-6 items-center justify-center text-center">
          <p className="text-white font-bold">$esFORT</p>
          <p className="text-gray-text">{displayDecimals(data.esFort || 0)}</p>
        </div>

        {icoAddress === PRIVATE_ICO_ADDRESS ? (
          ""
        ) : (
          <div className="flex flex-col rounded-custom bg-card-light-blue w-full p-6 items-center justify-center text-center">
            <p className="text-white font-bold">Farmed esFORT</p>
            <p className="text-gray-text">{displayDecimals(data.earnedETH)}</p>
          </div>
        )}
      </div>
    </>
  )
}
