import { useEffect } from "react"
import { SwapCard } from "./SwapCard"
import { SwapContextAtom } from "./swapUtility"
import { useWeb3React } from "@web3-react/core"
import { useStore } from "@nanostores/react"

export const Swap = () => {
  const { account, library } = useWeb3React()
  const swapContext = useStore(SwapContextAtom)

  useEffect(() => {
    swapContext.setUser(account, library)
  }, [account, library])

  return (
    <>
      <div className="">
        <div className="flex flex-row justify-center gap-6 max-w-7xl mx-auto pt-16 md:pt-32 pb-10">
          <SwapCard context={swapContext} />
        </div>
      </div>
    </>
  )
}
