import { MainDetails } from "./mainDetails"
import bannerPublic from "../images/banner/PublicSale.png"
import bannerPrivate from "../images/banner/PrivateSale.png"
import { useEffect, useState } from "react"
import Countdown from "react-countdown"
import { CountdownRenderer } from "../esFort/dividends/countDown"
import { LaunchInteraction } from "./launchInteraction"
import { LaunchDetail } from "./launchDetail"
import { SellInfo } from "./sellInfo"
import { useWeb3React } from "@web3-react/core"
import {
  commitments,
  finalEmissions,
  simulateCommit,
  getTotalCommitments as publicRaised,
} from "../../API/ico"
import { ethers } from "ethers"
import { LaunchDescription } from "./launchDescription"
import { invested, ethersToRaise as privateRaised } from "../../API/privateSale"
import {
  PRIVATE_ICO_ES_FORT,
  PRIVATE_ICO_FORT,
  PRIVATE_ICO_TARGET,
  PUBLIC_ICO_ADDRESS,
} from "../../constants"
import { getUserData } from "./launchUtils"
import { DisabledButton } from "./interactionCard/disabledButton"

//eslint-disable-next-line
export const Launch = ({ icoAddress, info }) => {
  const [isStarted, setIsStarted] = useState(false)
  const { active, account, library } = useWeb3React()
  const [raised, setRaised] = useState("0.00")
  const [userData, setUserData] = useState({
    whiteListed: icoAddress === PUBLIC_ICO_ADDRESS,
    commitments: 0,
  })

  const getRaised = async () => {
    const selectedMethod = info.type !== "private" ? publicRaised : privateRaised

    const result = await selectedMethod(icoAddress)

    setRaised(result)
  }

  useEffect(() => {
    console.log("userData", userData)
  }, [userData])

  useEffect(() => {
    getRaised()
    const interval = setInterval(async () => {
      console.log("updating total raised")
      await getRaised()
    }, 20000)

    return () => clearInterval(interval)
  }, [])

  const [data, setData] = useState(
    info.type === "private"
      ? { commit: "0.00", fort: "0.00", esFort: "0.00" }
      : {
          commit: "0.00",
          fort: "0.00",
          esFort: "0.00",
          earnedETH: "0.00",
          refound: "0.00",
          finales: "0.00",
        }
  )

  const publicGetData = async () => {
    let commit = await commitments(icoAddress, account)
    commit = ethers.formatEther(commit + "")
    commit = Number(commit).toFixed(2)

    let simulate = await simulateCommit(icoAddress, library)

    let finals = await finalEmissions(icoAddress, account)
    finals = ethers.formatEther(finals + "")

    let refound = simulate[0]
    refound = ethers.formatEther(refound + "")
    refound = Number(refound).toFixed(2)
    let fort = simulate[1]
    fort = ethers.formatEther(fort + "")
    fort = Number(fort).toFixed(2)
    let esFort = simulate[2]
    esFort = ethers.formatEther(esFort + "")
    esFort = Number(esFort).toFixed(2)
    let earnedETH = simulate[3]
    earnedETH = ethers.formatEther(earnedETH + "")
    earnedETH = Number(earnedETH).toFixed(2)

    setData({ commit, fort, esFort, earnedETH, refound, finals })
  }

  const privateGetData = async () => {
    let commit = await invested(account)

    const fort = (PRIVATE_ICO_FORT / PRIVATE_ICO_TARGET) * commit
    const esFort = (PRIVATE_ICO_ES_FORT / PRIVATE_ICO_TARGET) * commit

    const data = { commit, fort, esFort }

    getUserData(icoAddress, account, setUserData)

    setData(data)
  }

  const getData = async () => {
    info.type === "public" ? publicGetData() : privateGetData()
    getRaised()
  }

  useEffect(() => {
    if (!library) return

    getData()
  }, [active, account])

  useEffect(() => {
    if (info.start <= Date.now()) {
      setIsStarted(true)
    }
    const interval = setInterval(() => {
      if (info.start <= Date.now()) {
        setIsStarted(true)
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [account])

  return (
    <div className="w-full h-fit justify-center flex flex-col text-white py-6 gap-6">
      <div className="flex flex-col items-center justify-center w-8/12 max-md:w-11/12 max-xx:w-10/12 bg-card-color rounded-custom p-6 gap-6 shadow-custom self-center">
        <div className="w-full h-[204px] rounded-custom overflow-hidden flex items-center bg-card-light-blue relative max-sm:hidden">
          <img
            src={info.type === "private" ? bannerPrivate : bannerPublic}
            className="min-w-full min-h-full object-cover"
          />
        </div>
        <div
          id="img-launch"
          className="w-full h-28 bg-red rounded-custom bg-card-light-blue overflow-hidden relative sm:hidden"
        >
          <img
            src={info.type === "private" ? bannerPrivate : bannerPublic}
            className="min-w-full min-h-full absolute top-50 left-50 transform -translate-x-50% -translate-y-50% object-cover"
          />
        </div>
        <MainDetails icoAddress={icoAddress} info={info} raised={raised} />

        {icoAddress === PUBLIC_ICO_ADDRESS ? (
          ""
        ) : account ? (
          !userData.whiteListed ? (
            <DisabledButton text={"Not Whitelisted"} />
          ) : (
            <DisabledButton text={"Whitelisted"} />
          )
        ) : (
          ""
        )}
        {isStarted ? (
          <div className="w-full flex flex-col items-center gap-1">
            <SellInfo icoAddress={icoAddress} data={data} />
            <LaunchInteraction
              icoAddress={icoAddress}
              info={info}
              data={data}
              getData={getData}
              isOnWhite={userData.whiteListed}
              investments={userData.commitments}
              raised={raised}
            />
          </div>
        ) : (
          <div className="text-primary-color w-full h-12 rounded-custom bg-card-light-blue flex items-center justify-center">
            <Countdown date={info.start} renderer={CountdownRenderer} />
          </div>
        )}
        <LaunchDetail type={info.type} />
      </div>
      <LaunchDescription type={info.type} />
    </div>
  )
}
